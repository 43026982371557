import { ACTION_AUTH, ROUTE, STORE_MODULE } from '@/constants'
import { router } from '@/router/instance'
import { store } from '@/store/instance'

export async function signOut(): Promise<void> {
    if (router.currentRoute.name === ROUTE.SIGN_IN) return

    await store.dispatch(`${STORE_MODULE.AUTH}/${ACTION_AUTH.SIGN_OUT}`)

    router.push({ name: ROUTE.SIGN_IN }).catch(() => {})
}
