import { ROUTE } from '@/constants'
import { IRouteConfig } from '@/models'

export const routesHotelGroups: IRouteConfig[] = [
    {
        name: ROUTE.HOTEL_GROUPS,
        path: 'hotel-groups',
        component: () => import('@/components/BaseRouterView/BaseRouterView.vue'),
        redirect: { name: ROUTE.HOTEL_GROUPS_LIST },
        meta: {
            title: (intl) => intl.t().hotelGroups.hotelGroups.many,
            label: (intl) => intl.t().hotelGroups.hotelGroups.many,
        },
        children: [
            {
                name: ROUTE.HOTEL_GROUPS_LIST,
                path: '',
                component: () => import('@hotelGroups/views/HotelGroups/HotelGroups.vue'),
                meta: {
                    title: (intl) => intl.t().hotelGroups.hotelGroups.many,
                },
            },
            {
                name: ROUTE.HOTEL_GROUP_CREATE,
                path: 'create',
                component: () =>
                    import('@hotelGroups/views/HotelGroupCreate/HotelGroupCreate.vue'),
                meta: {
                    title: (intl) => intl.t().hotelGroups.create,
                    label: (intl) => intl.t().hotelGroups.create,
                },
            },
            {
                name: ROUTE.HOTEL_GROUP,
                path: ':hotelGroupId',
                component: () =>
                    import('@hotelGroups/views/HotelGroupDetails/HotelGroupDetails.vue'),
                redirect: {
                    name: ROUTE.HOTEL_GROUP_DETAILS,
                },
                props: true,
                meta: {
                    title: (intl) => intl.t().hotelGroups.hotelGroups.one,
                    label: (intl) => intl.t().hotelGroups.hotelGroups.one,
                },
                children: [
                    {
                        name: ROUTE.HOTEL_GROUP_DETAILS,
                        path: 'details',
                        component: () =>
                            import(
                                '@hotelGroups/views/HotelGroupDetails/HotelGroupDetails.vue'
                            ),
                        props: true,
                        meta: {
                            title: (intl) => intl.t().hotelGroups.hotelGroups.one,
                        },
                    },
                    // {
                    //     name: ROUTE.HOTELS_GROUPS_EDIT,
                    //     path: 'edit',
                    //     component: () =>
                    //         import('@hotelGroups/views/HotelGroupCreate/HotelGroupCreate.vue'),
                    //     meta: {
                    //         title: (intl) => intl.t().hotelGroups.edit,
                    //     },
                    // },
                ],
            },
        ],
    },
]
