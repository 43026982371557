import { IResource, IContentStoryType } from '@/models'
import { CONTENT_STORY_TYPE_VALUE } from '@/constants'

export class ConverterStoryType {
    constructor() {
        throw new Error('Cannot instantiate using a static class')
    }

    static fromRaw({ id, name }: IResource): IContentStoryType {
        return { id, name, value: this.getTypeValue(id) }
    }

    private static getTypeValue(typeId: string): CONTENT_STORY_TYPE_VALUE {
        return (
            Object.values(CONTENT_STORY_TYPE_VALUE).find((type) => type === typeId) ??
            CONTENT_STORY_TYPE_VALUE.UNKNOWN
        )
    }
}
