import { ROUTE } from '@/constants'
import { IRouteConfig } from '@/models'
import { routesSmartCards } from './routes'

export const routesContent: IRouteConfig[] = [
    {
        path: 'content',
        component: () => import('@/components/BaseRouterView/BaseRouterView.vue'),
        redirect: { name: ROUTE.CONTENT_SMART_CARDS_LIST },
        children: [
            ...routesSmartCards,
            {
                name: ROUTE.HOTEL_CONTENT,
                path: 'hotel-content',
                component: () => import('@content/views/HotelContent/HotelContent.vue'),
                meta: {
                    title: (intl) => intl.t().contents.hotelContent,
                    guard: (p) => p.hotels.read,
                    permission: (p) => p.hotels,
                    label: (intl) => intl.t().contents.hotelContent,
                },
            },
        ],
    },
]
