export enum MUTATION_AUTH {
    SET_USER_ACCOUNT = 'SetUserAccount',
    SET_REFRESH_ACCESS_TOKEN_PROMISE = 'SetRefreshAccessTokenPromise',
}

export enum MUTATION_TOASTS {
    ADD_TOAST = 'AddToast',
    REMOVE_TOAST = 'RemoveToast',
}

export enum MUTATION_LIST {
    SET_ITEMS = 'SetItems',
    ADD_ITEMS = 'AddItems',
    UPDATE_ITEM = 'UpdateItem',
    REMOVE_ITEM = 'RemoveItem',
    SET_IS_FETCHING_ITEMS = 'SetIsFetchingItems',
    SET_HAS_FETCHED_ALL_ITEMS = 'SetHasFetchedAllItems',
    SET_FILTERS = 'SetFilters',
    RESET_FILTERS = 'ResetFilters',
}

export enum MUTATION_IMAGE_VIEWER {
    SET_CURRENT_IMAGE_ID = 'SetCurrentImageId',
    SET_IMAGES_IDS = 'SetImagesIds',
    SET_IMAGES_TYPE = 'SetImagesType',
}
