import { IStateImageViewer } from '@/models'
import { MutationTree } from 'vuex'
import { MUTATION_IMAGE_VIEWER, STORE_MODULE } from '@/constants'
import { IStoreModule, IMAGE_TYPE } from '@/models'

const state = (): IStateImageViewer => ({
    currentImageId: undefined,
    imagesIds: [],
    imageType: undefined,
})

const mutations: MutationTree<IStateImageViewer> = {
    [MUTATION_IMAGE_VIEWER.SET_CURRENT_IMAGE_ID](state, id?: string): void {
        state.currentImageId = id
    },

    [MUTATION_IMAGE_VIEWER.SET_IMAGES_IDS](state, ids: string[]): void {
        state.imagesIds = ids
    },

    [MUTATION_IMAGE_VIEWER.SET_IMAGES_TYPE](state, type: IMAGE_TYPE): void {
        state.imageType = type
    },
}

export const imageViewer: IStoreModule<IStateImageViewer> = {
    name: STORE_MODULE.IMAGE_VIEWER,
    module: {
        namespaced: true,
        state,
        mutations,
    },
}
