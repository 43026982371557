import { pluralize } from '@/utils'
import { ILocales } from '../models'
import { LANGUAGE } from '@/constants'

const locale = LANGUAGE.EN_US

export const localesEnUs: ILocales = {
    common: {
        descriptions: {
            zero: 'No descriptions',
            one: 'Description',
            many: 'Descriptions',
        },
        none: 'None',
        chooseOrAdd: 'Choose from the list or add a new one by pressing Enter',
        close: 'Close',
        title: {
            zero: 'No title',
            one: 'Title',
            many: 'Titles',
        },
        add: 'Add',
        addNew: 'Add new',
        create: 'Create',
        edit: 'Edit',
        save: 'Save',
        accept: 'Accept',
        cancel: 'Cancel',
        more: 'More',
        delete: 'Delete',
        send: 'Send',
        notSaved: 'Not saved',
        details: {
            zero: 'No details',
            one: 'Detail',
            many: 'Details',
        },
        restoreDefault: 'Restore default',
        areYouSure: 'Are you sure?',
        name: {
            zero: 'No name',
            one: 'Name',
            many: 'Names',
        },
        code: {
            zero: 'No code',
            one: 'Code',
            many: 'Codes',
        },
        results: {
            zero: 'No results',
            one: 'Result',
            many: 'Results',
        },
        kinds: {
            zero: 'No kinds',
            one: 'Kind',
            many: 'Kinds',
        },
        types: {
            zero: 'No types',
            one: 'Type',
            many: 'Types',
        },
        tags: {
            zero: 'No tags',
            one: 'Tag',
            many: 'Tags',
        },
        restore: 'Restore',
        min: 'Minimum',
        max: 'Maximum',
        leadDescriptions: {
            zero: 'No lead descriptions',
            one: 'Lead description',
            many: 'Lead descriptions',
        },
        fieldIsRequired: 'This field is required',
        fieldIsTranslated: 'This field is translated',
        fieldTranslatedFallbackInfo:
            'There is no translation for selected language, default value was used',
        unsupportedLanguageCodeInfo: 'There is no language in the system with this ISO code',
        timezone: 'Timezone',
        website: 'Website',
        hotelAmenities: {
            zero: 'No hotel amenities',
            one: 'Hotel amenity',
            many: 'Hotel Amenities',
        },
        roomAmenities: {
            zero: 'No room amenities',
            one: 'Room amenity',
            many: 'Room Amenities',
        },
        starRating: 'Hotel star rating',
        number: 'Number',
        valueInvalid: 'Value is invalid',
        checkIn: 'Check in',
        checkOut: 'Check out',
        type: {
            zero: 'No types',
            one: 'Type',
            many: 'Types',
        },
        image: 'Image',
        mimeType: 'Mime type',
        statuses: {
            zero: 'No status',
            one: 'Status',
            many: 'Statuses',
        },
        startDate: 'Start date',
        endDate: 'End date',
        period: 'Period',
        dateOfBirth: 'Date of birth',
        nationalityCode: 'Nationality code',
        nationalityCodeInvalid: 'Nationality code does not match ISO Alpha 3 standard',
        firstName: 'First name',
        lastName: 'Last name',
        genders: {
            zero: 'No gender',
            one: 'Gender',
            many: 'Genders',
        },
        default: 'Default',
        defaultValue: 'Default value',
        fullName: 'Full name',
        port: 'Port',
        languages: {
            zero: 'No language',
            one: 'Language',
            many: 'Languages',
        },
        systemLanguages: {
            zero: 'No system language',
            one: 'System language',
            many: 'System languages',
        },
        defaultLanguage: {
            zero: 'No default language',
            one: 'Default language',
            many: 'Default languages',
        },
        makeDefault: 'Make default',
        priceLevel: {
            zero: 'No price level',
            one: 'Price level',
            many: 'Prices level',
        },
        prices: {
            zero: 'No price',
            one: 'Price',
            many: 'Prices',
        },
        yes: 'Yes',
        no: 'No',
        dateResourceActivated: 'Resource activation date',
        durations: {
            zero: 'No durations',
            one: 'Duration',
            many: 'Durations',
        },
        required: '*',
        orders: {
            zero: 'No orders',
            one: 'Order',
            many: 'Orders',
        },
        dimensions: {
            zero: 'No Dimensions',
            one: 'Dimension',
            many: 'Dimensions',
        },
        url: {
            zero: 'No URLs',
            one: 'URL',
            many: 'URLs',
        },
        relatedContents: {
            zero: 'No related content',
            one: 'Related content',
            many: 'Related content',
        },
        contents: {
            zero: 'No content',
            one: 'Content',
            many: 'Content',
        },
        range: 'Range',
        ago: 'Ago',
        active: 'Active',
        inactive: 'Inactive',
        inactiveDescription: 'This item is deactivated',
        times: {
            zero: 'No time',
            one: 'Time',
            many: 'Times',
        },
        data: {
            zero: 'No data',
            one: 'Data',
            many: 'Data',
        },
        startTimes: {
            zero: 'No start time',
            one: 'Start time',
            many: 'Start times',
        },
        pluralizedMinutes: (minutes) =>
            pluralize(locale, minutes, (_, variation) => variation, {
                lessThanOne: 'Below minute',
                one: '1 minute',
                other: `${minutes} minutes`,
            }),
        pluralizedHours: (hours) =>
            pluralize(locale, hours, (_, variation) => variation, {
                lessThanOne: 'Below hour',
                one: '1 hour',
                other: `${hours} hours`,
            }),
        pluralizedDays: (days) =>
            pluralize(locale, days, (_, variation) => variation, {
                lessThanOne: 'Below day',
                one: 'Day',
                other: `${days} days`,
            }),
        days: {
            zero: 'No days',
            one: 'Day',
            many: 'days',
        },
        weeks: {
            zero: 'No weeks',
            one: 'Week',
            many: 'Weeks',
        },
        months: {
            zero: 'No months',
            one: 'Month',
            many: 'Months',
        },
        years: {
            zero: 'No years',
            one: 'Year',
            many: 'Years',
        },
        daily: 'Daily',
        weekly: 'Weekly',
        monthly: 'Monthly',
        yearly: 'Yearly',
        search: 'Search',
        showMore: 'Show more...',
        showLess: 'Show less...',
        copyToClipboard: 'Copy to clipboard',
        copiedToClipboard: 'Copied to clipboard',
        example: 'Example',
        showActive: 'Show active',
        showInactive: 'Show inactive',
        deactivate: 'Deactivate',
        activate: 'Activate',
        open: 'Open',
        valueExists: 'Value already exists',
        import: 'Import',
        importLink: 'Link to the form',
        dropFile: 'Drop file anywhere to upload',
        or: 'or',
        selectFile: 'Select File',
        selectCSVFormat: 'Select file in CSV format',
        selectImageFormat: 'Select image from list',
        invalidFormat: 'Invalid format',
        addToFolder: 'should be added to the folder',
        andSendToServer: 'and upload to the server',
        currencies: {
            zero: 'No currencies',
            one: 'Currency',
            many: 'Currencies',
        },
        hasParent: 'Has parent',
        hasChildren: 'Has Children',
        availableRequestTypes: {
            zero: 'No available request types',
            one: 'Available request type',
            many: 'Available request types',
        },
        showFilters: 'Show filters',
        hideFilters: 'Hide filters',
        deleteDescription:
            'If an item is deleted, it will no longer be visible to the system, and its links to other items will no longer exist.',
        deactivateDescription:
            'If the item is activated, it will again be visible in the system along with links to other resources.',
        activateDescription:
            'If an item is deactivated, it will no longer be visible in the system, and its links to other items will no longer be visible. You can always activate the element again.',
        next: 'Next',
        back: 'Back',
        lists: {
            zero: 'No lists',
            one: 'List',
            many: 'Lists',
        },
        tiles: {
            zero: 'No tiles',
            one: 'Tile',
            many: 'Tiles',
        },
        searchInAllLanguages: 'Search in all languages',
        nextDay: 'Next day',
        confirmationMethods: {
            zero: 'No transaction confirmation methods',
            one: 'Transaction confirmation method',
            many: 'Transaction confirmation methods',
        },
        media: {
            zero: 'No media',
            one: 'Medium',
            many: 'Media',
        },
        contentTarget: {
            zero: 'No content target',
            one: 'Content target',
            many: 'Content targets',
        },
        somethingWentWrong: 'Something went wrong',
        tryAgain: 'Try again',
        manuals: {
            zero: 'No manual',
            one: 'Manual',
            many: 'Manuals',
        },
        trash: 'Trash',
        visibleInSystem: 'Visible in system',
        tvCableType: 'TV Cable Type',
        isVisibleInTouristApp: 'Is the hotel available in the tourist app',
        tvAvailableInputs: 'Available TV input types',
        tvPredefinedApplications: 'Predefined applications',
        epgProvider: 'EPG provider',
        main: 'Main',
        displayMain: 'Display only main',
        featured: 'Featured',
        setFeatured: 'Set as featured',
        unsetFeatured: 'Unset featured',
        setAsList: 'Set as list',
        setAsTile: 'Set as tile',
        assignedAsList: 'Assigned as list',
        assignedAsTile: 'Assigned as tile',
        featuredCardCannotBeEdited: 'Featured card can not be edited',
        translationRequired: 'Translation required',
        fillInvalidRequiredTranslatableFields: (count) =>
            `Fill in the required translation fields (${count})`,
    },
    auth: {
        ownerContextChanged: 'Owner context has been switched',
    },
    accommodations: {
        response: {
            created: 'Accommodation has been added',
            updated: 'Accommodation has been updated',
            deleted: 'Accommodation has been deleted',
            deactivate: 'Accommodation has been deactivated',
            activate: 'Accommodation has been activated',
        },
        accommodations: {
            zero: 'No accommodations',
            one: 'Accommodation',
            many: 'Accommodations',
        },
        onboardingFinishedAt: 'Onboarding finish date',
        edit: 'Edit accommodation',
        create: 'Create accommodation',
        activate: 'Activate accommodation',
        deactivate: 'Deactivate accommodation',
    },
    configurations: {
        configurations: {
            zero: 'No configurations',
            one: 'Configuration',
            many: 'Configurations',
        },
        wifi: {
            zero: 'No Wi-Fi',
            one: 'Wi-Fi',
            many: 'Wi-Fi',
        },
        authenticationType: 'Authentication type',
        wifiIsOpen: 'Open Wi-Fi',
        passwordAuthentication: 'Password authentication',
        authenticationRequired: 'External authentication required',
        editWifi: 'Edit Wi-Fi',
        createWifi: 'Create Wi-Fi',
        pickWifi: 'Choose Wi-Fi',
        ssid: 'SSID',
        hotelDefault: 'Default for hotel',
        securityTypes: {
            zero: 'No security type',
            one: 'Security type',
            many: 'Security Types',
        },
        cantSaveDefaultHotel: "The hotel's default Wi-Fi cannot be changed",
        wifiResponse: {
            created: 'Wi-Fi has been added',
            updated: 'Wi-Fi has been updated',
            deleted: 'Wi-Fi has been deleted',
        },
        informationCards: {
            zero: 'No information cards',
            one: 'Information card',
            many: 'Information cards',
        },
        informationCardResponse: {
            created: 'Information card has been added',
            updated: 'Information card has been updated',
            deleted: 'Information card has been deleted',
            deactivate: 'Information card has been deactivated',
            activate: 'Information card has been activated',
        },
        editInformationCard: 'Edit information card',
        createInformationCard: 'Create information card',
        deactivateInformationCard: 'Activate information card',
        activateInformationCard: 'Deactivate information card',
        navigateToStore: 'Navigate to Store',
        navigateToMarketplace: "Navigate to 'Marketplace'",
        informationCardsDescription:
            'Information cards will be displayed in the system views, at the very top of the screen',
        roomServiceCards: {
            zero: 'No room service cards',
            one: 'Room service card',
            many: 'Room service cards',
        },
        roomServiceCardDescription: 'The room service card will be displayed in all views',
        storeCards: {
            zero: 'No store cards',
            one: 'Store card',
            many: 'Store cards',
        },
        storeCardDescription:
            'Set the store card to the selected view so that it appears in the system',
        storyDescription: 'Story description',
        peripheralTemplates: {
            peripheralTemplates: {
                zero: 'No installed external accessories',
                one: 'Installed external accessory',
                many: 'Installed external accessories',
            },
            createPeripheralTemplate: 'Create installed external accessory',
            editPeripheralTemplate: 'Edit installed external accessory',
            response: {
                created: 'Installed external accessory has been added',
                updated: 'Installed external accessory has been updated',
                deleted: 'Installed external accessory has been deleted',
            },
        },
        peripheralTypes: {
            peripheralTypes: {
                zero: 'No external accessories',
                one: 'External accessory',
                many: 'External accessories',
            },
            createPeripheralType: 'Create external accessory',
            editPeripheralType: 'Edit external accessory',
            supportedTechnologies: 'Supported operating systems',
            response: {
                created: 'Accessory has been added',
                updated: 'Accessory has been updated',
                deleted: 'Accessory has been deleted',
            },
        },
        isMarketplaceAvailable: 'Is the Marketplace module enabled',
    },
    events: {
        response: {
            created: 'Event has been added',
            updated: 'Event has been updated',
            deleted: 'Event has been deleted',
            deactivate: 'Event has been deactivated',
            activate: 'Event has been activated',
        },
        events: {
            zero: 'No events',
            one: 'Event',
            many: 'Events',
        },
        relatedEvents: {
            zero: 'No related events',
            one: 'Related event',
            many: 'Related events',
        },
        edit: 'Edit event',
        activate: 'Active event',
        deactivate: 'Deactivate event',
        create: 'Create event',
        createEventFromContentOrLibrary:
            'Event can be created from Story, Information card, Place or Content Library',
    },
    places: {
        places: {
            zero: 'No places',
            one: 'Place',
            many: 'Places',
        },
        relatedPlaces: {
            zero: 'No related places',
            one: 'Related place',
            many: 'Related places',
        },
        response: {
            created: 'Place has been added',
            updated: 'Place has been updated',
            deleted: 'Place has been deleted',
            deactivate: 'Place has been deactivated',
            activate: 'Place has been activated',
        },
        edit: 'Edit place',
        activate: 'Active place',
        deactivate: 'Deactivate place',
        create: 'Create place',
        createFromContentOrLibrary:
            'Place can be created from Story, Information card or Content Library',
        placeToVisit: 'Place to visit',
        happeningHere: "It's happening here",
    },
    informationCards: {
        informationCards: {
            zero: 'No information cards',
            one: 'Information card',
            many: 'Information cards',
        },
        relatedInformationCards: {
            zero: 'No related information cards',
            one: 'Related information card',
            many: 'Related information',
        },
        edit: 'Edit information card',
        activate: 'Active information card',
        deactivate: 'Deactivate information card',
        create: 'Create information card',
        response: {
            created: 'Information card has been added',
            updated: 'Information card has been updated',
            deleted: 'Information card has been deleted',
            deactivate: 'Information card has been deactivated',
            activate: 'Information card has been activated',
        },
        createInformationFromContentOrLibrary:
            'Information Card can be created from Story or Content Library',
    },
    stories: {
        stories: {
            zero: 'No stories',
            one: 'Story',
            many: 'Stories',
        },
        relatedStories: {
            zero: 'No related stories',
            one: 'Related story',
            many: 'Related stories',
        },
        displayOption: 'Display option in system',
        response: {
            created: 'Story has been added',
            updated: 'Story has been updated',
            deleted: 'Story has been deleted',
            deactivate: 'Story has been deactivated',
            activate: 'Story has been activated',
        },
        edit: 'Edit story',
        activate: 'Activate story',
        deactivate: 'Deactivate story',
        create: 'Create story',
        createStoryFromHotelOrLibrary:
            'Story can only be created from the Hotel View or Content Library',
    },
    activities: {
        activities: {
            zero: 'No activities',
            one: 'Activity',
            many: 'Activities',
        },
        relatedActivities: {
            zero: 'No related activities',
            one: 'Related activity',
            many: 'Related activities',
        },
        response: {
            created: 'Activity has been added',
            updated: 'Activity has been updated',
            deleted: 'Activity has been deleted',
            deactivate: 'Activity has been deactivated',
            activate: 'Activity has been activated',
        },
        edit: 'Edit activity',
        activate: 'Activate activity',
        deactivate: 'Deactivate activity',
        create: 'Create activity',
        createActivityFromContentOrLibrary:
            'Activity can be created from Story, Information card, Place or Content Library',
    },
    devices: {
        devices: {
            zero: 'No devices',
            one: 'Device',
            many: 'Devices',
        },
        register: 'Register device',
        edit: 'Edit device',
        activate: 'Activate device',
        deactivate: 'Deactivate device',
        deviceId: 'Device ID',
        models: {
            zero: 'No models',
            one: 'Model',
            many: 'Models',
        },
        serialNumber: 'Serial number',
        portType: 'Port type',
        portIndex: 'Port index',
        response: {
            updated: 'Device has been deleted',
            deleted: 'Device has been updated',
            register: 'Device has been registered',
            deactivate: 'Device has been deactivated',
            activate: 'Device has been activated',
        },
        timestampLastToken: 'Last timestamp of the active device',
        creationDate: 'Creation date',
        deactivationDate: 'Deactivation date',
    },
    images: {
        images: {
            zero: 'No images',
            one: 'Image',
            many: 'Images',
        },
        icons: {
            zero: 'No icons',
            one: 'Icon',
            many: 'Icons',
        },
        response: {
            created: 'Image has been added',
            updated: 'Image has been updated',
            deleted: 'Image has been deleted',
        },
        editImage: 'Edit image',
        createImage: 'Create image',
        deleteImage: 'Delete image',
    },
    tvSeries: {
        tvSeries: {
            zero: 'No TV series',
            one: 'TV series',
            many: 'TV series',
        },
        create: 'Create TV series',
        delete: 'Delete TV series',
        edit: 'Edit TV series',
        response: {
            created: 'TV series has been added',
            deleted: 'TV series has been deleted',
            updated: 'TV series has been updated',
        },
        tllElements: {
            zero: 'No .tll file elements',
            one: '.tll file element',
            many: '.tll file elements',
        },
    },
    menuItems: {
        menuItems: {
            zero: 'No menu items',
            one: 'Menu item',
            many: 'Menu items',
        },
        response: {
            created: 'Menu item has been created',
            deleted: 'Menu item has been deleted',
            updated: 'Menu item has been updated',
        },
        create: 'Create menu item',
        edit: 'Edit menu item',
        delete: 'Delete menu item',
        setCard: 'Set card',
        menuItemsCreateContentDescription:
            'Add menu items if you want the card to be displayed in them as the main point',
    },
    stores: {
        stores: {
            zero: 'No stores',
            one: 'Store',
            many: 'Stores',
        },
        response: {
            created: 'Store has been added',
            updated: 'Store has been updated',
            deleted: 'Store has been deleted',
            deactivate: 'Store has been deactivated',
            activate: 'Store has been activated',
        },
        editStore: 'Edit store',
        createStore: 'Create store',
        activateStore: 'Activate store',
        deactivateStore: 'Deactivate store',
        deliveryHours: {
            deliveryHours: {
                zero: 'No delivery hours',
                one: 'Delivery hour',
                many: 'Delivery hours',
            },
            response: {
                created: 'Delivery hours has been added',
                updated: 'Delivery hours has been updated',
                deleted: 'Delivery hours has been deleted',
            },
        },
        emailLanguage: 'Email message language',
    },
    products: {
        products: {
            zero: 'No products',
            one: 'Product',
            many: 'Products',
        },
        recommendedProducts: {
            zero: 'No recommended products',
            one: 'Recommended product',
            many: 'Recommended products',
        },
        recommendedProductsDescription:
            'In each store, 3 recommended products are displayed, if they are not set, they will be generated randomly.',
        relatedProducts: {
            zero: 'No related products',
            one: 'Related product',
            many: 'Related products',
        },
        response: {
            created: 'Product has been added',
            updated: 'Product has been updated',
            deleted: 'Product has been deleted',
            deactivate: 'Product has been deactivated',
            activate: 'Product has been activated',
        },
        editProduct: 'Edit product',
        createProduct: 'Create product',
        activateProduct: 'Activate product',
        deactivateProduct: 'Deactivate product',
        deliveryTime: {
            zero: 'No delivery time',
            one: 'Delivery time',
            many: 'Delivery times',
        },
        deliveryMethods: {
            zero: 'No delivery methods',
            one: 'Delivery method',
            many: 'Delivery methods',
        },
        contentInProductDescription:
            'If a resource is added, the product will be visible in the content with the option to purchase it.',
        productInContentDescription:
            'If a product is added to the content, the product will be visible in the content with the possibility of purchasing it',
        displayProducts: 'Select a store to display products',
        categories: {
            categories: {
                zero: 'No categories',
                one: 'Category',
                many: 'Categories',
            },
            editCategory: 'Edit category',
            createCategory: 'Create category',
            activateCategory: 'Activate category',
            deactivateCategory: 'Deactivate category',
            pickCategory: 'Pick category',
            response: {
                created: 'Category has been added',
                updated: 'Category has been updated',
                deleted: 'Category has been deleted',
                deactivate: 'Category has been deactivated',
                activate: 'Category has been activated',
            },
        },
    },
    services: {
        services: {
            zero: 'No services',
            one: 'Service',
            many: 'Services',
        },
    },
    contents: {
        contents: {
            zero: 'No content',
            one: 'Content',
            many: 'Contents',
        },
        relatedToContent: (name) => `Related to ${name}`,
        entryFee: {
            zero: 'No entry fee',
            one: 'Entry fee',
            many: 'Entry fees',
        },
        regionExclusions: 'Region Exclusions',
        reservationRequired: {
            zero: 'No required reservation',
            one: 'Required reservation',
            many: 'Required reservations',
        },
        visibilityInMenuItems: 'Visibility in menu items',
        visibilityInHelp: 'Visibility in Help',
        relatedHotelObjectHotelDescription:
            'If a Hotel is added, Content will only be visible in the selected Hotel. Content can be linked with many Hotel Objects(Hotel/Room).',
        relatedHotelObjectRoomDescription:
            'If a Room is added, Content will only be visible in the selected Room. Content can be linked with many Hotel Objects(Hotel/Room).',
        relatedHotelObjectDescription:
            'If a Hotel Object is added, the Content becomes hotel content. The Content can be hotel or regional.',
        regionExclusionsDescription:
            'If a Region(Details) is assigned to the Content, the Content becomes regional content. The Content can be hotel or regional.',
        preview: 'Preview',
        hotelContent: 'Hotel content',
        eventEnded: 'Event ended',
        response: {
            deactivate: 'Content has been deactivated',
            activate: 'Content has been activated',
        },
        activate: 'Activate content',
        deactivate: 'Deactivate content',
    },
    hotels: {
        hotels: {
            zero: 'No hotels',
            one: 'Hotel',
            many: 'Hotels',
        },
        response: {
            created: 'Hotel has been added',
            updated: 'Hotel has been updated',
            deleted: 'Hotel has been deleted',
            deactivate: 'Hotel has been deactivated',
            activate: 'Hotel has been activated',
        },
        editHotel: 'Edit hotel',
        editHotelDetails: 'Edit hotel details',
        createHotel: 'Create hotel',
        activateHotel: 'Activate hotel',
        deactivateHotel: 'Deactivate hotel',
        checkInTime: 'Check in time',
        checkOutTime: 'Check out time',
        relatedHotels: {
            zero: 'No related hotels',
            one: 'Related hotel',
            many: 'Related hotels',
        },
        relatedHotelObjects: {
            zero: 'No related hotel objects',
            one: 'Related hotel object',
            many: 'Related hotel objects',
        },
        hotelServiceMode: 'Display service hotels',
    },
    hotelGroups: {
        hotelGroups: {
            zero: 'No hotel groups',
            one: 'Hotel group',
            many: 'Hotel groups',
        },
        create: 'Create hotel group',
        edit: 'Edit hotel group',
        delete: 'Delete hotel group',
        response: {
            created: 'Hotel group has been created',
            updated: 'Hotel group has been updated',
            deleted: 'Hotel group has been deleted',
        },
    },
    roomGroups: {
        roomGroups: {
            zero: 'No room groups',
            one: 'Room group',
            many: 'Room groups',
        },
        create: 'Create room group',
        edit: 'Edit room group',
        delete: 'Delete room group',
        response: {
            created: 'Room group has been created',
            updated: 'Room group has been updated',
            deleted: 'Room group has been deleted',
        },
    },
    openingHours: {
        openingHours: {
            zero: 'No opening hours',
            one: 'Opening hour',
            many: 'Opening hours',
        },
        openingHoursSchedules: {
            zero: 'No opening hours schedule',
            one: 'Opening hours schedule',
            many: 'Opening hours schedules',
        },
        openingHoursExclusionSchedules: {
            zero: 'No opening hours exclusion schedule',
            one: 'Opening hours exclusion schedule',
            many: 'Opening hours exclusion schedules',
        },
        response: {
            created: 'Opening hours has been added',
            updated: 'Opening hours has been updated',
            deleted: 'Opening hours has been deleted',
        },
        editSchedule: 'Edit opening hours schedule',
        createSchedule: 'Create opening hours schedule',
        editScheduleExclusions: 'Edit opening hours exclusion schedule',
        createScheduleExclusions: 'Create opening hours exclusion schedule',
        repeatDaily: 'Repeatability in the daily cycle',
        repeatWeekly: 'Repeatability in the weekly cycle',
        repeatMonthly: 'Repeatability in the weekly cycle',
        repeatYearly: 'Repeatability in the yearly cycle',
        weekdays: 'No weekdays | Weekday | Weekdays',
        cycleFrequency: 'Cycle frequency',
        dayOrigin: 'Origin day',
        dayOffset: 'Offset in days',
        weekOrigin: 'Origin week',
        weekOffset: 'Offset in weeks',
        closed: 'Closed',
        cycleFrequencyDescription:
            'Select the cycle for the repeatability of the opening hours',
        type: {
            type: 'Period type',
            weekday: 'Weekday',
            weekdaysRange: 'Weekdays range',
            holiday: 'Public holiday',
            other: 'Other',
        },
        hoursRangeType: {
            hoursRangeType: 'Time range type',
            range: 'Hours range',
            off: 'Closed all day',
        },
        validationError: 'The opening hours specification provided is invalid',
        invalidValue: 'Invalid value',
    },
    contacts: {
        contacts: {
            zero: 'No contacts',
            one: 'Contact',
            many: 'Contacts',
        },
        hotelContacts: {
            zero: 'No hotel contacts',
            one: 'Hotel contact',
            many: 'Hotel contacts',
        },
        emergencyContacts: {
            zero: 'No emergency contacts',
            one: 'Emergency contact',
            many: 'Emergency contacts',
        },
        requestCallbackContacts: {
            zero: 'No request callback contacts',
            one: 'Request callback contact',
            many: 'Request callback contacts',
        },
        phoneNumber: 'Phone number',
        emails: {
            zero: 'No emails',
            one: 'Email',
            many: 'Emails',
        },
        response: {
            created: 'Contact has been added',
            updated: 'Contact has been updated',
            deleted: 'Contact has been deleted',
        },
        editEmail: 'Edit email',
        createEmail: 'Create email',
        editContact: 'Edit contact',
        createContact: 'Create contact',
        deleteContact: 'Delete contact',
    },
    address: {
        address: {
            zero: 'No address',
            one: 'Address',
            many: 'Addresses',
        },
        cities: {
            zero: 'No cities',
            one: 'City',
            many: 'Cities',
        },
        latitudeAndLongitude: 'Latitude and Longitude',
        latitudeAndLongitudeDescription:
            'Just click on the place on the map with the right mouse button, then left click on the coordinates, e.g. 50.02128, 19.88629. Paste them into one of the fields below.',
        latitude: 'Latitude',
        longitude: 'Longitude',
        postalCode: 'Postal code',
        state: 'State',
        line1: 'Line first',
        line2: 'Line second',
        line3: 'Line third',
        response: {
            updated: 'Address has been updated',
            deleted: 'Address has been deleted',
        },
        editAddressDetails: 'Edit address details',
        filledIdAutomatically:
            'The address was filled in automatically from the linked resource. You can still edit it.',
    },
    users: {
        users: {
            zero: 'No users',
            one: 'User',
            many: 'Users',
        },
        logins: {
            zero: 'No logins',
            one: 'Login',
            many: 'Logins',
        },
        signIn: 'Sign in',
        signOut: 'Sign out',
        passwordChange: 'Password change',
        myAccount: 'My account',
        usernames: {
            zero: 'No usernames',
            one: 'Username',
            many: 'Usernames',
        },
        password: {
            zero: 'No password',
            one: 'Password',
            many: 'Passwords',
        },
        repeatPassword: 'Repeat password',
        changePassword: 'Change password',
        administrators: {
            zero: 'No administrators',
            one: 'Administrator',
            many: 'Administrators',
        },
        technicians: {
            zero: 'No technicians',
            one: 'Technician',
            many: 'Technicians',
        },
        role: {
            role: 'Role',
            admin: 'Administrator',
            owner: 'Owner',
            hotelier: 'Hotelier',
        },
        response: {
            created: 'User has been added',
            updated: 'User has been updated',
            deleted: 'User has been deleted',
            deactivate: 'User has been deactivated',
            activate: 'User has been activated',
        },
        editTechnician: 'Edit technician',
        createTechnician: 'Create technician',
        activateTechnician: 'Activate technician',
        deactivateTechnician: 'Deactivate technician',
        editAdministrator: 'Edit administrator',
        createAdministrator: 'Create administrator',
        activateAdministrator: 'Activate administrator',
        deactivateAdministrator: 'Deactivate administrator',
    },
    views: {
        views: {
            zero: 'No views',
            one: 'View',
            many: 'Views',
        },
        createView: 'Add a new View to start adding content.',
        experience: 'Experience',
        discover: 'Discover',
        taste: 'Taste',
        help: 'Help',
        hospitals: {
            zero: 'No hospitals',
            one: 'Hospital',
            many: 'Hospitals',
        },
        pharmacies: {
            zero: 'No pharmacies',
            one: 'Pharmacy',
            many: 'Pharmacies',
        },
        pickHotels: 'Select a hotel to display available views',
    },
    contentFeeds: {
        contentFeeds: {
            zero: 'No content feeds',
            one: 'Content feed',
            many: 'Content feeds',
        },
        create: 'Create content feed',
        edit: 'Edit content feed',
        delete: 'Delete content feed',
        activate: 'Activate content feed',
        deactivate: 'Deactivate content feed',
        response: {
            created: 'Content feed has been created',
            updated: 'Content feed has been updated',
            deleted: 'Content feed has been deleted',
            deactivate: 'Content feed has been deactivated',
            activate: 'Content feed has been activated',
        },
    },
    countries: {
        countries: {
            zero: 'No countries',
            one: 'Country',
            many: 'Countries',
        },
        editCountry: 'Edit country',
        createCountry: 'Create country',
        activateCountry: 'Activate country',
        deactivateCountry: 'Deactivate country',
        response: {
            created: 'Country has been added',
            updated: 'Country has been updated',
            deleted: 'Country has been deleted',
            deactivate: 'Country has been deactivated',
            activate: 'Country has been activated',
        },
        countryCode: 'Country code (ISO Alpha 2)',
        countryCodeInvalid: 'Country code does not match ISO Alpha 2 standard',
    },
    regions: {
        regions: {
            zero: 'No regions',
            one: 'Region',
            many: 'Regions',
        },
        response: {
            created: 'Region has been added',
            updated: 'Region has been updated',
            deleted: 'Region has been deleted',
            deactivate: 'Region has been deactivated',
            activate: 'Region has been activated',
        },
        editRegion: 'Edit region',
        createRegion: 'Create region',
        activateRegion: 'Activate region',
        deactivateRegion: 'Deactivate region',
        regionCode: 'Region code',
        polygonDescription:
            'Copy the polygon coordinates to the clipboard, click the link and import coordinates to display the region polygon',
        polygonUrlDescription: 'Show polygon',
        createUrlDescription: 'Create polygon',
        polygons: {
            zero: 'No polygons',
            one: 'Polygon',
            many: 'Polygons',
        },
        coordinates: {
            zero: 'No coordinates',
            one: 'Coordinate',
            many: 'Coordinates',
        },
        closePolygon: "Remember to close Polygon. Under the coordinates - 'type': 'Polygon'",
        polygonNotClosed: 'Polygon has not been closed',
    },
    owners: {
        owners: {
            zero: 'No owners',
            one: 'Owner',
            many: 'Owners',
        },
        create: 'Create owner',
        edit: 'Edit owner',
        delete: 'Delete owner',
        hotelManagement:
            'Overview list of hotels. The owner can be changed from the Hotel details',
        response: {
            created: 'Owner has been created',
            updated: 'Owner has been updated',
            deleted: 'Owner has been deleted',
        },
        cannotCreateLibrary:
            'Content library could not be created. No hotels associated with the Owner',
        cannotCreateStore:
            "Cannot create a Store. There are no hotels associated with the Vendor's Owner",
    },
    contentLibraries: {
        contentLibraries: {
            zero: 'No content libraries',
            one: 'Content library',
            many: 'Content libraries',
        },
        shared: 'Shared',
        sharedLibrary: 'Shared Library',
        cardInSharedLibrary: 'Card in Shared Library',
        create: 'Create content library',
        edit: 'Edit content library',
        delete: 'Delete content library',
        response: {
            created: 'Content library has been created',
            updated: 'Content library has been updated',
            deleted: 'Content library has been deleted',
        },
    },
    rooms: {
        rooms: {
            zero: 'No rooms',
            one: 'Room',
            many: 'Rooms',
        },
        response: {
            created: 'Room has been added',
            updated: 'Room has been updated',
            deleted: 'Room has been deleted',
            deactivate: 'Room has been deactivated',
            activate: 'Room has been activated',
        },
        editRoom: 'Edit room',
        createRoom: 'Create room',
        activateRoom: 'Activate room',
        deactivateRoom: 'Deactivate room',
        roomNumber: 'Room number',
        roomName: 'Room name',
        roomCode: 'Room code',
        maxOccupancy: {
            zero: 'No max occupancy',
            one: 'Max occupancy',
            many: 'Max occupancies',
        },
        roomType: {
            zero: 'No room type',
            one: 'Room type',
            many: 'Room types',
        },
        roomExists: 'Room already exists',
        relatedRooms: {
            zero: 'No related rooms',
            one: 'Related room',
            many: 'Related rooms',
        },
        displayRooms: 'Select a hotel to display rooms',
    },
    guests: {
        guests: {
            zero: 'No guests',
            one: 'Guest',
            many: 'Guests',
        },
        response: {
            created: 'Guest has been added',
            updated: 'Guest has been deleted',
            deleted: 'Guest has been updated',
            deactivate: 'Guest has been deactivated',
            activate: 'Guest has been activated',
        },
        editGuest: 'Edit guest',
        createGuest: 'Create guest',
        activateGuest: 'Activate guest',
        deactivateGuest: 'Deactivate guest',
        classifications: {
            zero: 'No classification',
            one: 'Classification',
            many: 'Classifications',
        },
    },
    checklists: {
        checklists: {
            zero: 'No checklists',
            one: 'Checklist',
            many: 'Checklists',
        },
        predefinedChecklists: {
            zero: 'No predefined checklists',
            one: 'Predefined checklist',
            many: 'Predefined checklists',
        },
        response: {
            created: 'Predefined checklist has been added',
            updated: 'Predefined checklist has been updated',
            deleted: 'Predefined checklist has been deleted',
        },
        editPredefinedChecklists: 'Edit predefined checklist',
        createPredefinedChecklists: 'Create predefined checklist',
    },
    tasks: {
        tasks: {
            zero: 'No tasks',
            one: 'Task',
            many: 'Tasks',
        },
        response: {
            created: 'Predefined task has been added',
            updated: 'Predefined task has been updated',
            deleted: 'Predefined task has been deleted',
            deactivate: 'Predefined task has been deactivated',
            activate: 'Predefined task has been activated',
        },
        editPredefinedTasks: 'Edit predefined task',
        createPredefinedTasks: 'Create predefined task',
        activatePredefinedTasks: 'Activate predefined task',
        deactivatePredefinedTasks: 'Deactivate predefined task',
        predefinedTasks: {
            zero: 'No predefined tasks',
            one: 'Predefined task',
            many: 'Predefined tasks',
        },
    },
    maintenances: {
        maintenances: {
            zero: 'No maintenances',
            one: 'Maintenance',
            many: 'Maintenances',
        },
        response: {
            created: 'Maintenance has been added',
            updated: 'Maintenance has been updated',
            deleted: 'Maintenance has been deleted',
        },
        editMaintenance: 'Edit maintenance',
        createMaintenance: 'Create maintenance',
        deleteMaintenance: 'Delete maintenance',
    },
    azIndex: {
        azIndex: {
            zero: 'No A-Z indexes',
            one: 'A-Z index',
            many: 'A-Z indexes',
        },
        create: 'Create index',
        delete: 'Delete index',
        edit: 'Edit A-Z index',
        response: {
            created: 'Index has been added',
            updated: 'Index has been updated',
            deleted: 'Index has been deleted',
        },
    },
    smartCards: {
        smartCards: {
            zero: 'No smart cards',
            one: 'Smart card',
            many: 'Smart cards',
        },
        smartCardsMenu: 'Menu (Content)',
        response: {
            created: 'Smart card has been added',
            updated: 'Smart card has been updated',
            deleted: 'Smart card has been deleted',
            deactivate: 'Smart card has been deactivated',
            activate: 'Smart card has been activated',
        },
        edit: 'Edit smart card',
        activate: 'Activate smart card',
        deactivate: 'Deactivate smart card',
        create: 'Create smart card',
        type: 'Card type',
    },
    administration: {
        administration: 'Administration',
    },
    systemMessages: {
        systemMessages: {
            zero: 'No system messages',
            one: 'System message',
            many: 'System messages',
        },
        create: 'Create message',
        delete: 'Delete message',
        edit: 'Edit message',
        activate: 'Activate message',
        deactivate: 'Deactivate message',
        response: {
            created: 'Message has been added',
            updated: 'Message has been updated',
            activated: 'Message has been activated',
            deactivated: 'Message has been deactivated',
        },
    },
    notifications: {
        notifications: {
            zero: 'No notification',
            one: 'Notification',
            many: 'Notifications',
        },
        response: {
            sent: 'Notification has been sent',
            updated: 'Notification has been updated',
            deleted: 'Notification has been deleted',
        },
        edit: 'Edit notification',
        create: 'Create notification',
        delete: 'Delete notification',
        send: 'Send notification',
        messages: {
            zero: 'No messages',
            one: 'Message',
            many: 'Messages',
        },
        unread: 'Unread',
        sendingTime: 'Sending time',
    },
    marketplace: {
        marketplace: 'Marketplace',
    },
}
