import { ROUTE } from '@/constants'
import { IRouteConfig } from '@/models'

export const routesHotelDetails: IRouteConfig[] = [
    {
        name: ROUTE.HOTEL_AMENITIES,
        path: 'amenities',
        component: () =>
            import('@hotels/views/HotelDetails/views/HotelAmenities/HotelAmenities.vue'),
        props: true,
        meta: {
            title: (intl) => intl.t().hotels.hotels.one,
        },
    },
    {
        name: ROUTE.HOTEL_ACCOMMODATIONS,
        path: 'accommodations',
        component: () => import('@/components/BaseRouterView/BaseRouterView.vue'),
        redirect: {
            name: ROUTE.HOTEL_ACCOMMODATIONS_LIST,
        },
        props: true,
        meta: {
            title: (intl) => intl.t().hotels.hotels.one,
            label: (intl) => intl.t().accommodations.accommodations.many,
        },
        children: [
            {
                name: ROUTE.HOTEL_ACCOMMODATIONS_LIST,
                path: '',
                component: () =>
                    import(
                        '@hotels/views/HotelDetails/views/Accommodations/Accommodations.vue'
                    ),
                props: true,
                meta: {
                    title: (intl) => intl.t().hotels.hotels.one,
                },
            },
            {
                name: ROUTE.HOTEL_ACCOMMODATION_CREATE,
                path: 'create',
                component: () =>
                    import('@hotels/views/AccommodationCreate/AccommodationCreate.vue'),
                props: true,
                meta: {
                    title: (intl) => intl.t().hotels.hotels.one,
                    guard: (p) => p.accommodations.create,
                    label: (intl) => intl.t().accommodations.create,
                },
            },
            {
                name: ROUTE.HOTEL_ACCOMMODATION,
                path: ':accommodationId',
                component: () =>
                    import('@hotels/views/AccommodationDetails/AccommodationDetails.vue'),
                redirect: {
                    name: ROUTE.ACCOMMODATION_DETAILS,
                },
                props: true,
                meta: {
                    title: (intl) => intl.t().hotels.hotels.one,
                    guard: (p) => p.accommodations.create,
                    label: (intl) => intl.t().accommodations.accommodations.one,
                },
                children: [
                    {
                        name: ROUTE.ACCOMMODATION_DETAILS_NOTIFICATIONS,
                        path: ':isInRoom/notifications',
                        component: () =>
                            import(
                                '@hotels/views/AccommodationDetails/views/Notifications/Notifications.vue'
                            ),
                        props: true,
                        meta: {
                            title: (intl) => intl.t().hotels.hotels.one,
                            guard: (p) => p.notifications.read,
                            permission: (p) => p.notifications,
                        },
                    },
                    {
                        name: ROUTE.ACCOMMODATION_DETAILS,
                        path: ':isInRoom/details',
                        component: () =>
                            import('@/components/BaseRouterView/BaseRouterView.vue'),
                        redirect: { name: ROUTE.ACCOMMODATION_DETAILS_DETAILS },
                        props: true,
                        meta: {
                            guard: (p) => p.accommodations.read,
                            permission: (p) => p.accommodations,
                        },
                        children: [
                            {
                                name: ROUTE.ACCOMMODATION_DETAILS_DETAILS,
                                path: '',
                                component: () =>
                                    import(
                                        '@hotels/views/AccommodationDetails/views/Details/Details.vue'
                                    ),
                                props: true,
                                meta: {
                                    title: (intl) => intl.t().hotels.hotels.one,
                                },
                            },
                            {
                                name: ROUTE.ACCOMMODATION_DETAILS_EDIT,
                                path: 'edit',
                                component: () =>
                                    import(
                                        '@hotels/views/AccommodationDetailsEdit/AccommodationDetailsEdit.vue'
                                    ),
                                props: true,
                                meta: {
                                    title: (intl) => intl.t().hotels.hotels.one,
                                    guard: (p) => p.accommodations.update,
                                    label: (intl) => intl.t().accommodations.edit,
                                },
                            },
                        ],
                    },
                    {
                        name: ROUTE.ACCOMMODATION_GUEST_CREATE,
                        path: 'guest/create/:isInRoom',
                        component: () =>
                            import(
                                '@hotels/views/AccommodationGuestCreate/AccommodationGuestCreate.vue'
                            ),
                        props: true,
                        meta: {
                            title: (intl) => intl.t().hotels.hotels.one,
                            guard: (p) => p.accommodations.guests.create,
                        },
                    },
                    {
                        name: ROUTE.GUEST_EDIT,
                        path: 'guest/edit/:guestId',
                        component: () => import('@hotels/views/GuestEdit/GuestEdit.vue'),
                        props: true,
                        meta: {
                            title: (intl) => intl.t().hotels.hotels.one,
                            guard: (p) => p.guests.update,
                            label: (intl) => intl.t().guests.editGuest,
                        },
                    },
                    {
                        name: ROUTE.GUEST_DETAILS,
                        path: 'guests/:guestId/:isInRoom',
                        component: () => import('@hotels/views/GuestDetails/GuestDetails.vue'),
                        props: true,
                        meta: {
                            title: (intl) => intl.t().hotels.hotels.one,
                            guard: (p) => p.guests.read,
                            permission: (p) => p.guests,
                            label: (intl) => intl.t().guests.guests.one,
                        },
                    },
                ],
            },
        ],
    },
    {
        name: ROUTE.HOTEL_ROOMS,
        path: 'rooms',
        component: () => import('@hotels/views/HotelDetails/views/Rooms/Rooms.vue'),
        props: true,
        redirect: { name: ROUTE.HOTEL_ROOMS_ROOMS },
        meta: {
            label: (intl) => intl.t().rooms.rooms.many,
        },
        children: [
            {
                name: ROUTE.HOTEL_ROOMS_ROOMS,
                path: 'rooms',
                component: () => import('@/components/BaseRouterView/BaseRouterView.vue'),
                redirect: {
                    name: ROUTE.HOTEL_ROOMS_ROOMS_LIST,
                },
                props: true,
                meta: {
                    title: (intl) => intl.t().hotels.hotels.one,
                },
                children: [
                    {
                        name: ROUTE.HOTEL_ROOMS_ROOMS_LIST,
                        path: '',
                        component: () =>
                            import(
                                '@hotels/views/HotelDetails/views/Rooms/views/Rooms/Rooms.vue'
                            ),
                        props: true,
                        meta: {
                            title: (intl) => intl.t().hotels.hotels.one,
                        },
                    },
                    {
                        name: ROUTE.ROOM_CREATE,
                        path: 'create',
                        component: () => import('@hotels/views/RoomEdit/RoomEdit.vue'),
                        props: true,
                        meta: {
                            title: (intl) => intl.t().hotels.hotels.one,
                            guard: (p) => p.rooms.create,
                            label: (intl) => intl.t().rooms.createRoom,
                        },
                    },
                    {
                        name: ROUTE.ROOM_EDIT,
                        path: 'edit/:roomId',
                        component: () => import('@hotels/views/RoomEdit/RoomEdit.vue'),
                        props: true,
                        meta: {
                            title: (intl) => intl.t().hotels.hotels.one,
                            guard: (p) => p.rooms.update,
                            label: (intl) => intl.t().rooms.editRoom,
                        },
                    },
                ],
            },
            {
                name: ROUTE.HOTEL_ROOMS_ROOM_GROUPS,
                path: 'room-groups',
                component: () => import('@/components/BaseRouterView/BaseRouterView.vue'),
                redirect: {
                    name: ROUTE.HOTEL_ROOMS_ROOM_GROUPS_LIST,
                },
                props: true,
                meta: {
                    title: (intl) => intl.t().hotels.hotels.one,
                    label: (intl) => intl.t().roomGroups.roomGroups.many,
                },
                children: [
                    {
                        name: ROUTE.HOTEL_ROOMS_ROOM_GROUPS_LIST,
                        path: '',
                        component: () =>
                            import(
                                '@hotels/views/HotelDetails/views/Rooms/views/RoomGroups/RoomGroups.vue'
                            ),
                        props: true,
                        meta: {
                            title: (intl) => intl.t().hotels.hotels.one,
                        },
                    },
                    {
                        name: ROUTE.ROOM_GROUP_CREATE,
                        path: 'create',
                        component: () =>
                            import('@hotels/views/RoomGroupCreate/RoomGroupCreate.vue'),
                        props: true,
                        meta: {
                            title: (intl) => intl.t().hotels.hotels.one,
                            guard: (p) => p.roomGroups.create,
                            label: (intl) => intl.t().roomGroups.create,
                        },
                    },
                    {
                        name: ROUTE.ROOM_GROUP_DETAILS,
                        path: ':roomGroupId',
                        component: () =>
                            import('@hotels/views/RoomGroupDetails/RoomGroupDetails.vue'),
                        props: true,
                        meta: {
                            title: (intl) => intl.t().hotels.hotels.one,
                            guard: (p) => p.roomGroups.read,
                            permission: (p) => p.roomGroups,
                            label: (intl) => intl.t().roomGroups.roomGroups.one,
                        },
                    },
                ],
            },
            {
                name: ROUTE.ROOM_AMENITIES,
                path: 'amenities',
                component: () =>
                    import(
                        '@hotels/views/HotelDetails/views/Rooms/views/RoomAmenities/RoomAmenities.vue'
                    ),
                props: true,
                meta: {
                    title: (intl) => intl.t().hotels.hotels.one,
                },
            },
            {
                name: ROUTE.ROOM_TYPES,
                path: 'types',
                component: () =>
                    import(
                        '@hotels/views/HotelDetails/views/Rooms/views/RoomTypes/RoomTypes.vue'
                    ),
                props: true,
                meta: {
                    title: (intl) => intl.t().hotels.hotels.one,
                },
            },
        ],
    },
    {
        name: ROUTE.HOTEL_CONTACTS,
        path: 'contacts',
        component: () => import('@hotels/views/HotelDetails/views/Contacts/Contacts.vue'),
        redirect: { name: ROUTE.HOTEL_CONTACTS_GENERAL },
        children: [
            {
                name: ROUTE.HOTEL_CONTACTS_GENERAL,
                path: 'general',
                component: () =>
                    import(
                        '@hotels/views/HotelDetails/views/Contacts/views/GeneralContacts/GeneralContacts.vue'
                    ),
                props: true,
                meta: {
                    title: (intl) => intl.t().hotels.hotels.one,
                },
            },
            {
                name: ROUTE.HOTEL_CONTACTS_REQUEST_CALLBACK,
                path: 'request-callback',
                component: () =>
                    import(
                        '@hotels/views/HotelDetails/views/Contacts/views/RequestCallbackContacts/RequestCallbackContacts.vue'
                    ),
                props: true,
                meta: {
                    title: (intl) => intl.t().hotels.hotels.one,
                },
            },
        ],
    },
    {
        name: ROUTE.HOTEL_MAINTENANCES,
        path: 'maintenances',
        component: () => import('@hotels/views/HotelDetails/views/Maintenance/Maintenance.vue'),
        redirect: { name: ROUTE.HOTEL_MAINTENANCES_CHECKLISTS },
        props: true,
        meta: {
            label: (intl) => intl.t().maintenances.maintenances.many,
        },
        children: [
            {
                name: ROUTE.HOTEL_MAINTENANCES_CHECKLISTS,
                path: 'checklists',
                component: () => import('@/components/BaseRouterView/BaseRouterView.vue'),
                redirect: {
                    name: ROUTE.MAINTENANCES_CHECKLISTS_LIST,
                },
                props: true,
                meta: {
                    title: (intl) => intl.t().hotels.hotels.one,
                    label: (intl) => intl.t().checklists.checklists.many,
                },
                children: [
                    {
                        name: ROUTE.MAINTENANCES_CHECKLISTS_LIST,
                        path: '',
                        component: () =>
                            import(
                                '@hotels/views/HotelDetails/views/Maintenance/views/Checklists/Checklists.vue'
                            ),
                        props: true,
                        meta: {
                            title: (intl) => intl.t().hotels.hotels.one,
                        },
                    },
                    {
                        name: ROUTE.MAINTENANCE_CHECKLIST_DETAILS_CREATE,
                        path: 'create',
                        component: () =>
                            import(
                                '@hotels/views/MaintenanceChecklistEdit/MaintenanceChecklistEdit.vue'
                            ),
                        props: true,
                        meta: {
                            title: (intl) => intl.t().hotels.hotels.one,
                            guard: (p) => p.hotel.one.maintenanceTasksChecklists.create,
                            label: (intl) => intl.t().checklists.createPredefinedChecklists,
                        },
                    },
                    {
                        name: ROUTE.MAINTENANCE_CHECKLIST_DETAILS,
                        path: ':checklistId',
                        component: () =>
                            import(
                                '@hotels/views/MaintenanceChecklistDetails/MaintenanceChecklistDetails.vue'
                            ),
                        props: true,
                        meta: {
                            title: (intl) => intl.t().hotels.hotels.one,
                            guard: (p) => p.hotel.one.maintenanceTasksChecklists.read,
                            permission: (p) => p.hotel.one.maintenanceTasksChecklists,
                            label: (intl) => intl.t().checklists.checklists.one,
                        },
                    },
                    {
                        name: ROUTE.MAINTENANCE_CHECKLIST_DETAILS_EDIT,
                        path: ':checklistId/edit',
                        component: () =>
                            import(
                                '@hotels/views/MaintenanceChecklistEdit/MaintenanceChecklistEdit.vue'
                            ),
                        props: true,
                        meta: {
                            title: (intl) => intl.t().hotels.hotels.one,
                            guard: (p) => p.hotel.one.maintenanceTasksChecklists.update,
                            label: (intl) => intl.t().checklists.editPredefinedChecklists,
                        },
                    },
                ],
            },
            {
                name: ROUTE.HOTEL_MAINTENANCES_TASKS,
                path: 'tasks',
                component: () =>
                    import(
                        '@hotels/views/HotelDetails/views/Maintenance/views/Tasks/Tasks.vue'
                    ),
                props: true,
                meta: {
                    title: (intl) => intl.t().hotels.hotels.one,
                    label: (intl) => intl.t().tasks.tasks.many,
                },
            },
        ],
    },
    {
        name: ROUTE.HOTEL_CONFIGURATIONS,
        path: 'configurations',
        component: () =>
            import('@hotels/views/HotelDetails/views/Configurations/Configurations.vue'),
        redirect: { name: ROUTE.HOTEL_CONFIGURATION_WIFI },
        meta: {
            label: (intl) => intl.t().configurations.configurations.many,
        },
        children: [
            {
                name: ROUTE.HOTEL_CONFIGURATION_WIFI,
                path: 'wifi',
                component: () => import('@/components/BaseRouterView/BaseRouterView.vue'),
                redirect: {
                    name: ROUTE.HOTEL_CONFIGURATION_WIFI_LIST,
                },
                props: true,
                meta: {
                    title: (intl) => intl.t().hotels.hotels.one,
                    guard: (p) => p.hotels.wifi.read,
                    label: (intl) => intl.t().configurations.wifi.many,
                },
                children: [
                    {
                        name: ROUTE.HOTEL_CONFIGURATION_WIFI_LIST,
                        path: '',
                        component: () =>
                            import(
                                '@hotels/views/HotelDetails/views/Configurations/views/HotelWiFi/HotelWiFi.vue'
                            ),
                        props: true,
                        meta: {
                            title: (intl) => intl.t().hotels.hotels.one,
                            guard: (p) => p.hotels.wifi.read,
                        },
                    },
                    {
                        name: ROUTE.HOTEL_CONFIGURATION_WIFI_CREATE,
                        path: 'create',
                        component: () =>
                            import(
                                '@hotels/views/ConfigurationWifiDetailsEdit/ConfigurationWifiDetailsEdit.vue'
                            ),
                        props: true,
                        meta: {
                            title: (intl) => intl.t().hotels.hotels.one,
                            // guard: (p) => p.hotels.wifi.create,
                        },
                    },
                    {
                        name: ROUTE.HOTEL_CONFIGURATION_WIFI_DETAILS,
                        path: ':wifiId',
                        component: () =>
                            import(
                                '@hotels/views/ConfigurationWifiDetails/ConfigurationWifiDetails.vue'
                            ),
                        props: true,
                        meta: {
                            title: (intl) => intl.t().hotels.hotels.one,
                            guard: (p) => p.hotels.wifi.read,
                            permission: (p) => p.hotels.one.wifi,
                        },
                    },
                    {
                        name: ROUTE.HOTEL_CONFIGURATION_WIFI_EDIT,
                        path: ':wifiId/edit',
                        component: () =>
                            import(
                                '@hotels/views/ConfigurationWifiDetailsEdit/ConfigurationWifiDetailsEdit.vue'
                            ),
                        props: true,
                        meta: {
                            title: (intl) => intl.t().hotels.hotels.one,
                            guard: (p) => p.hotels.wifi.update,
                        },
                    },
                ],
            },
            {
                name: ROUTE.HOTEL_CONFIGURATION_INFORMATION_CARDS,
                path: 'information-cards',
                component: () =>
                    import(
                        '@hotels/views/HotelDetails/views/Configurations/views/InformationCards/InformationCards.vue'
                    ),
                props: true,
                redirect: { name: ROUTE.HOTEL_CONFIGURATION_INFORMATION_CARDS_STORES },
                meta: {
                    title: (intl) => intl.t().hotels.hotels.one,
                    guard: (p, a) => p.informationCards.read && a,
                    label: (intl) => intl.t().configurations.informationCards.many,
                },
                children: [
                    {
                        name: ROUTE.HOTEL_CONFIGURATION_INFORMATION_CARDS_STORES,
                        path: 'stores',
                        component: () =>
                            import('@/components/BaseRouterView/BaseRouterView.vue'),
                        redirect: {
                            name: ROUTE.HOTEL_CONFIGURATION_INFORMATION_CARDS_STORES_LIST,
                        },
                        props: true,
                        meta: {
                            title: (intl) => intl.t().hotels.hotels.one,
                            guard: (p) => p.marketplace.stores.read,
                            label: (intl) => intl.t().configurations.informationCards.many,
                        },
                        children: [
                            {
                                name: ROUTE.HOTEL_CONFIGURATION_INFORMATION_CARDS_STORES_LIST,
                                path: '',
                                component: () =>
                                    import(
                                        '@hotels/views/HotelDetails/views/Configurations/views/InformationCards/views/Stores/Stores.vue'
                                    ),
                                props: true,
                                meta: {
                                    title: (intl) => intl.t().hotels.hotels.one,
                                    guard: (p) => p.marketplace.stores.read,
                                },
                            },
                            {
                                name: ROUTE.HOTEL_CONFIGURATION_INFORMATION_CARDS_STORES_CREATE,
                                path: 'create',
                                component: () =>
                                    import(
                                        '@hotels/views/InformationCardEdit/InformationCardEdit.vue'
                                    ),
                                props: true,
                                meta: {
                                    title: (intl) => intl.t().hotels.hotels.one,
                                    guard: (p) => p.informationCards.create,
                                    label: (intl) =>
                                        intl.t().configurations.createInformationCard,
                                },
                            },
                            {
                                name: ROUTE.HOTEL_CONFIGURATION_INFORMATION_CARDS_STORES_DETAILS,
                                path: ':informationId',
                                component: () =>
                                    import(
                                        '@hotels/views/InformationCardDetails/InformationCardDetails.vue'
                                    ),
                                props: true,
                                meta: {
                                    title: (intl) => intl.t().hotels.hotels.one,
                                    guard: (p) => p.informationCards.read,
                                    permission: (p) => p.informationCards,
                                    label: (intl) =>
                                        intl.t().configurations.informationCards.one,
                                },
                            },
                            {
                                name: ROUTE.HOTEL_CONFIGURATION_INFORMATION_CARDS_STORES_EDIT,
                                path: ':informationId/edit',
                                component: () =>
                                    import(
                                        '@hotels/views/InformationCardEdit/InformationCardEdit.vue'
                                    ),
                                props: true,
                                meta: {
                                    title: (intl) => intl.t().hotels.hotels.one,
                                    guard: (p) => p.informationCards.update,
                                    label: (intl) =>
                                        intl.t().configurations.editInformationCard,
                                },
                            },
                        ],
                    },
                    {
                        name: ROUTE.HOTEL_CONFIGURATION_INFORMATION_CARDS_ROOM_SERVICE,
                        path: 'room-service',
                        component: () =>
                            import(
                                '@hotels/views/HotelDetails/views/Configurations/views/InformationCards/views/RoomService/RoomService.vue'
                            ),
                        props: true,
                        meta: {
                            title: (intl) => intl.t().hotels.hotels.one,
                            guard: (p) => p.marketplace.stores.read,
                        },
                    },
                ],
            },
            {
                name: ROUTE.HOTEL_CONFIGURATION_PERIPHERAL_TEMPLATES,
                path: 'peripheral-templates',
                component: () => import('@/components/BaseRouterView/BaseRouterView.vue'),
                redirect: {
                    name: ROUTE.HOTEL_CONFIGURATION_PERIPHERAL_TEMPLATES_LIST,
                },
                props: true,
                meta: {
                    title: (intl) => intl.t().hotels.hotels.one,
                    guard: (p, a) => p.devices.peripherals.templates && a,
                    label: (intl) =>
                        intl.t().configurations.peripheralTemplates.peripheralTemplates.many,
                },
                children: [
                    {
                        name: ROUTE.HOTEL_CONFIGURATION_PERIPHERAL_TEMPLATES_LIST,
                        path: '',
                        component: () =>
                            import(
                                '@hotels/views/HotelDetails/views/Configurations/views/PeripheralTemplates/PeripheralTemplates.vue'
                            ),
                        props: true,
                        meta: {
                            title: (intl) => intl.t().hotels.hotels.one,
                            guard: (p, a) => p.devices.peripherals.templates && a,
                        },
                    },
                    {
                        name: ROUTE.HOTEL_CONFIGURATION_PERIPHERAL_TEMPLATES_CREATE,
                        path: 'create',
                        component: () =>
                            import(
                                '@hotels/views/PeripheralTemplateEdit/PeripheralTemplateEdit.vue'
                            ),
                        props: true,
                        meta: {
                            title: (intl) => intl.t().hotels.hotels.one,
                            guard: (p) => p.devices.peripherals.templates.create,
                            label: (intl) =>
                                intl.t().configurations.peripheralTemplates
                                    .createPeripheralTemplate,
                        },
                    },
                    {
                        name: ROUTE.HOTEL_CONFIGURATION_PERIPHERAL_TEMPLATES_EDIT,
                        path: 'edit/:peripheralTemplateId',
                        component: () =>
                            import(
                                '@hotels/views/PeripheralTemplateEdit/PeripheralTemplateEdit.vue'
                            ),
                        props: true,
                        meta: {
                            title: (intl) => intl.t().hotels.hotels.one,
                            guard: (p) => p.devices.peripherals.templates.update,
                            label: (intl) =>
                                intl.t().configurations.peripheralTemplates
                                    .editPeripheralTemplate,
                        },
                    },
                    {
                        name: ROUTE.HOTEL_CONFIGURATION_PERIPHERAL_TEMPLATES_DETAILS,
                        path: ':peripheralTemplateId',
                        component: () =>
                            import(
                                '@hotels/views/PeripheralTemplateDetails/PeripheralTemplateDetails.vue'
                            ),
                        props: true,
                        meta: {
                            title: (intl) => intl.t().hotels.hotels.one,
                            guard: (p) => p.devices.peripherals.templates.read,
                            permission: (p) => p.devices.peripherals.templates,
                            label: (intl) =>
                                intl.t().configurations.peripheralTemplates.peripheralTemplates
                                    .one,
                        },
                    },
                ],
            },
            {
                name: ROUTE.HOTEL_CONFIGURATION_MENU_ITEMS,
                path: 'menu-items',
                component: () => import('@/components/BaseRouterView/BaseRouterView.vue'),
                redirect: {
                    name: ROUTE.HOTEL_CONFIGURATION_MENU_ITEM_LIST,
                },
                props: true,
                meta: {
                    title: (intl) => intl.t().hotels.hotels.one,
                    guard: (p) => p.mediacontents.menuItemFeeds.read,
                    label: (intl) => intl.t().menuItems.menuItems.many,
                },
                children: [
                    {
                        name: ROUTE.HOTEL_CONFIGURATION_MENU_ITEM_LIST,
                        path: '',
                        component: () =>
                            import(
                                '@hotels/views/HotelDetails/views/Configurations/views/MenuItems/MenuItems.vue'
                            ),
                        props: true,
                        meta: {
                            title: (intl) => intl.t().hotels.hotels.one,
                            guard: (p) => p.mediacontents.menuItemFeeds.read,
                        },
                    },
                    {
                        name: ROUTE.HOTEL_CONFIGURATION_MENU_ITEM_EDIT,
                        path: 'edit/:menuItemFeedId',
                        component: () =>
                            import(
                                '@hotels/views/HotelDetails/views/Configurations/views/MenuItemEdit/MenuItemEdit.vue'
                            ),
                        props: true,
                        meta: {
                            title: (intl) => intl.t().hotels.hotels.one,
                            guard: (p) => p.mediacontents.menuItemFeeds.read,
                            label: (intl) => intl.t().menuItems.edit,
                        },
                    },
                    {
                        name: ROUTE.HOTEL_CONFIGURATION_MENU_ITEM_CREATE,
                        path: 'create',
                        component: () =>
                            import(
                                '@hotels/views/HotelDetails/views/Configurations/views/MenuItemEdit/MenuItemEdit.vue'
                            ),
                        props: true,
                        meta: {
                            title: (intl) => intl.t().hotels.hotels.one,
                            guard: (p) => p.mediacontents.menuItemFeeds.read,
                            label: (intl) => intl.t().menuItems.create,
                        },
                    },
                    {
                        name: ROUTE.HOTEL_CONFIGURATION_MENU_ITEM_DETAILS,
                        path: ':menuItemFeedId',
                        component: () =>
                            import(
                                '@hotels/views/HotelDetails/views/Configurations/views/MenuItemDetails/MenuItemDetails.vue'
                            ),
                        props: true,
                        meta: {
                            title: (intl) => intl.t().hotels.hotels.one,
                            guard: (p) => p.mediacontents.menuItemFeeds.read,
                            label: (intl) => intl.t().menuItems.menuItems.one,
                        },
                    },
                ],
            },
            {
                name: ROUTE.HOTEL_CONFIGURATION_HELP,
                path: 'help',
                component: () => import('@/components/BaseRouterView/BaseRouterView.vue'),
                props: true,
                meta: {
                    title: (intl) => intl.t().hotels.hotels.one,
                    guard: (p) => p.stories.read,
                    label: (intl) => intl.t().views.help,
                },
                redirect: { name: ROUTE.HOTEL_CONFIGURATION_HELP_DETAILS },
                children: [
                    {
                        name: ROUTE.HOTEL_CONFIGURATION_HELP_DETAILS,
                        path: 'details',
                        component: () =>
                            import(
                                '@hotels/views/HotelDetails/views/Configurations/views/Help/Help.vue'
                            ),
                        props: true,
                        meta: {
                            title: (intl) => intl.t().hotels.hotels.one,
                            label: (intl) => intl.t().contents.contents.many,
                        },
                    },
                    {
                        name: ROUTE.CONTENT_SMART_CARD_CREATE_FROM_HELP,
                        path: 'smart-card/create/:helpHotelId',
                        component: () =>
                            import('@content/views/SmartCardCreate/SmartCardCreate.vue'),
                        props: true,
                        meta: {
                            title: (intl) => intl.t().hotels.hotels.one,
                            guard: (p) =>
                                p.mediacontents.contentLibraries.one.smartContentGroups.create,
                            label: (intl) => intl.t().smartCards.create,
                        },
                    },
                ],
            },
            {
                name: ROUTE.HOTEL_AZ_INDEXES,
                path: 'az-index',
                component: () => import('@/components/BaseRouterView/BaseRouterView.vue'),
                props: true,
                redirect: { name: ROUTE.HOTEL_AZ_INDEX },
                meta: {
                    title: (intl) => intl.t().hotels.hotels.one,
                },
                children: [
                    {
                        name: ROUTE.HOTEL_AZ_INDEX,
                        path: '',
                        component: () =>
                            import(
                                '@hotels/views/HotelDetails/views/Configurations/views/AzIndex/AzIndex.vue'
                            ),
                        props: true,
                        meta: {
                            title: (intl) => intl.t().hotels.hotels.one,
                            guard: (p) => p.hotels.one.azIndexItems.read,
                            label: (intl) => intl.t().azIndex.azIndex.one,
                        },
                    },
                    {
                        name: ROUTE.HOTEL_AZ_INDEX_CREATE,
                        path: 'create',
                        component: () => import('@hotels/views/AzIndexEdit/AzIndexEdit.vue'),
                        props: true,
                        meta: {
                            title: (intl) => intl.t().hotels.hotels.one,
                            guard: (p) => p.hotels.one.azIndexItems.create,
                            label: (intl) => intl.t().azIndex.create,
                        },
                    },
                    {
                        name: ROUTE.HOTEL_AZ_INDEX_DETAILS,
                        path: ':azIndexId',
                        component: () =>
                            import('@hotels/views/AzIndexDetails/AzIndexDetails.vue'),
                        props: true,
                        meta: {
                            title: (intl) => intl.t().hotels.hotels.one,
                            guard: (p) => p.hotels.one.azIndexItems.read,
                            permission: () => ({
                                read: true,
                                create: true,
                                delete: true,
                                update: true,
                            }),
                            label: (intl) => intl.t().azIndex.azIndex.one,
                        },
                    },

                    {
                        name: ROUTE.HOTEL_AZ_INDEX_EDIT,
                        path: ':azIndexId/edit',
                        component: () => import('@hotels/views/AzIndexEdit/AzIndexEdit.vue'),
                        props: true,
                        meta: {
                            title: (intl) => intl.t().hotels.hotels.one,
                            label: (intl) => intl.t().azIndex.edit,
                        },
                    },
                ],
            },
        ],
    },
    {
        name: ROUTE.HOTEL_STORES,
        path: 'stores',
        component: () => import('@/components/BaseRouterView/BaseRouterView.vue'),
        redirect: { name: ROUTE.HOTEL_STORES_LIST },
        meta: {
            guard: (p) => p.marketplace.stores.read,
            label: (intl) => intl.t().stores.stores.many,
        },
        children: [
            {
                name: ROUTE.HOTEL_STORES_LIST,
                path: '',
                component: () => import('@hotels/views/HotelDetails/views/Stores/Stores.vue'),
                meta: {
                    title: (intl) => intl.t().stores.stores.many,
                },
            },
            {
                name: ROUTE.HOTEL_STORE_CREATE,
                path: 'create',
                component: () =>
                    import('@hotels/views/HotelDetails/views/StoreCreate/StoreCreate.vue'),
                props: true,
                meta: {
                    title: (intl) => intl.t().stores.createStore,
                    guard: (p) => p.marketplace.stores.create,
                    label: (intl) => intl.t().stores.createStore,
                },
            },
        ],
    },
    {
        name: ROUTE.HOTEL_SMART_CARDS_MENU,
        path: 'smart-cards-menu',
        component: () =>
            import('@hotels/views/HotelDetails/views/SmartCardsMenu/SmartCardsMenu.vue'),
        props: true,
        meta: {
            title: (intl) => intl.t().hotels.hotels.one,
            label: (intl) => intl.t().smartCards.smartCardsMenu,
        },
        children: [
            {
                name: ROUTE.HOTEL_SMART_CARDS_MENU_ITEM,
                path: ':itemId',
                component: () =>
                    import(
                        '@hotels/views/HotelDetails/views/SmartCardsMenu/views/MenuItem/MenuItem.vue'
                    ),
                props: true,
                meta: {
                    title: (intl) => intl.t().hotels.hotels.one,
                },
            },
        ],
    },
]
