import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import pl from 'vuetify/src/locale/pl'
import en from 'vuetify/src/locale/en'
import { getStorageOrBrowserOrFallbackLocale } from '@/utils'

Vue.use(Vuetify)

const currentLanguage = getStorageOrBrowserOrFallbackLocale().slice(0, 2)

export const vuetify = new Vuetify({
    theme: { disable: true },
    icons: {
        iconfont: 'mdiSvg',
    },
    lang: {
        locales: { pl, en },
        current: currentLanguage,
    },
})
