import { Backend, IRawRequestListResponse, IResource } from '@/models'

interface IContentTypeConverter<T> {
    fromRaw(data: IResource): T
}

export class ServiceContentTypes<T extends { id: string }> extends Backend {
    private cacheId: string | undefined

    constructor(
        private path: string,
        private converter: IContentTypeConverter<T>
    ) {
        super()
    }

    async fetchTypes(): Promise<T[]> {
        const { data, id: cacheId } = await this.get<IRawRequestListResponse<IResource>>(
            this.path,
            this.createRequestConfig({ cacheStrategy: 'static' })
        )

        this.cacheId = cacheId

        return data.results.map((type) => this.converter.fromRaw(type))
    }

    async fetchType(id: string): Promise<T> {
        let type = await this.fetchCachedType(id)

        // when type returned from cached entry, clear cache and try again before throwing error,
        // maybe there is new type server-side, not present in cache
        if (!type && this.cacheId) {
            await this.removeCachedEntry(this.cacheId)
            type = await this.fetchCachedType(id)
        }

        if (!type) throw Error(`Type with id ${id} does not exist`)

        return type
    }

    private async fetchCachedType(id: string): Promise<T | undefined> {
        const types = await this.fetchTypes()
        const type = types.find((type) => type.id === id)

        return type
    }
}
