import { IResource, IContentPlaceType } from '@/models'
import { CONTENT_PLACE_TYPE_VALUE } from '@/constants'

export class ConverterPlaceType {
    constructor() {
        throw new Error('Cannot instantiate using a static class')
    }

    static fromRaw({ id, name }: IResource): IContentPlaceType {
        return Object.freeze({ id, name, value: this.getTypeValue(id) })
    }

    private static getTypeValue(typeId: string): CONTENT_PLACE_TYPE_VALUE {
        return (
            Object.values(CONTENT_PLACE_TYPE_VALUE).find((type) => type === typeId) ??
            CONTENT_PLACE_TYPE_VALUE.UNKNOWN
        )
    }
}
