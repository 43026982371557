import axios from 'axios'
import { paramsSerializer } from './utils'

const axiosInstance = axios.create({
    withCredentials: false,
    baseURL: import.meta.env.VITE_APP_IMAGES_BACKEND_URL,
    paramsSerializer,
})

export abstract class BackendImages {
    protected get = axiosInstance.get
}
