import { CONTENT_EVENT_TYPE_VALUE } from '@/constants'
import { IResource, IContentEventType } from '@/models'

export class ConverterEventType {
    constructor() {
        throw new Error('Cannot instantiate using a static class')
    }

    static fromRaw({ id, name }: IResource): IContentEventType {
        return { id, name, value: this.getTypeValue(id) }
    }

    private static getTypeValue(typeId: string): CONTENT_EVENT_TYPE_VALUE {
        return (
            Object.values(CONTENT_EVENT_TYPE_VALUE).find((type) => type === typeId) ??
            CONTENT_EVENT_TYPE_VALUE.UNKNOWN
        )
    }
}
