import { pluralize } from '@/utils'
import { ILocales } from '../models'
import { LANGUAGE } from '@/constants'

const locale = LANGUAGE.DE_DE

export const localesDeDe: ILocales = {
    common: {
        descriptions: {
            zero: 'Keine Beschreibungen',
            one: 'Beschreibung',
            many: 'Beschreibungen',
        },
        none: 'Keine',
        chooseOrAdd:
            'Wählen Sie aus der Liste aus oder fügen Sie eine neue hinzu, indem Sie Enter drücken',
        close: 'Schließen',
        title: {
            zero: 'Kein Titel',
            one: 'Titel',
            many: 'Titel',
        },
        add: 'Hinzufügen',
        addNew: 'Neu hinzufügen',
        create: 'Erstellen',
        edit: 'Bearbeiten',
        save: 'Speichern',
        accept: 'Akzeptieren',
        cancel: 'Abbrechen',
        more: 'Mehr',
        delete: 'Löschen',
        send: 'Senden',
        notSaved: 'Nicht gespeichert',
        details: {
            zero: 'Keine Details',
            one: 'Detail',
            many: 'Details',
        },
        restoreDefault: 'Standard wiederherstellen',
        areYouSure: 'Sind Sie sicher?',
        name: {
            zero: 'Kein Name',
            one: 'Name',
            many: 'Namen',
        },
        code: {
            zero: 'Kein Code',
            one: 'Code',
            many: 'Codes',
        },
        results: {
            zero: 'Keine Ergebnisse',
            one: 'Ergebnis',
            many: 'Ergebnisse',
        },
        kinds: {
            zero: 'Keine Arten',
            one: 'Art',
            many: 'Arten',
        },
        types: {
            zero: 'Keine Typen',
            one: 'Typ',
            many: 'Typen',
        },
        tags: {
            zero: 'Keine Tags',
            one: 'Tag',
            many: 'Tags',
        },
        restore: 'Wiederherstellen',
        min: 'Minimum',
        max: 'Maximum',
        leadDescriptions: {
            zero: 'Keine Lead-Beschreibungen',
            one: 'Lead-Beschreibung',
            many: 'Lead-Beschreibungen',
        },
        fieldIsRequired: 'Dieses Feld ist erforderlich',
        fieldIsTranslated: 'Dieses Feld ist übersetzt',
        fieldTranslatedFallbackInfo:
            'Es gibt keine Übersetzung für die ausgewählte Sprache, der Standardwert wurde verwendet',
        unsupportedLanguageCodeInfo: 'Es gibt keine Sprache im System mit diesem ISO-Code',
        timezone: 'Zeitzone',
        website: 'Website',
        hotelAmenities: {
            zero: 'Keine Hotelausstattung',
            one: 'Hotelausstattung',
            many: 'Hotelausstattungen',
        },
        roomAmenities: {
            zero: 'Keine Zimmerausstattung',
            one: 'Zimmerausstattung',
            many: 'Zimmerausstattungen',
        },
        starRating: 'Hotel-Sternebewertung',
        number: 'Nummer',
        valueInvalid: 'Wert ist ungültig',
        checkIn: 'Check-in',
        checkOut: 'Check-out',
        type: {
            zero: 'Keine Typen',
            one: 'Typ',
            many: 'Typen',
        },
        image: 'Bild',
        mimeType: 'MIME-Typ',
        statuses: {
            zero: 'Kein Status',
            one: 'Status',
            many: 'Status',
        },
        startDate: 'Startdatum',
        endDate: 'Enddatum',
        period: 'Periode',
        dateOfBirth: 'Geburtsdatum',
        nationalityCode: 'Nationalitätscode',
        nationalityCodeInvalid: 'Nationalitätscode entspricht nicht dem ISO Alpha 3-Standard',
        firstName: 'Vorname',
        lastName: 'Nachname',
        genders: {
            zero: 'Kein Geschlecht',
            one: 'Geschlecht',
            many: 'Geschlechter',
        },
        default: 'Standard',
        defaultValue: 'Standardwert',
        fullName: 'Vollständiger Name',
        port: 'Port',
        languages: {
            zero: 'Keine Sprache',
            one: 'Sprache',
            many: 'Sprachen',
        },
        systemLanguages: {
            zero: 'Keine Systemsprache',
            one: 'Systemsprache',
            many: 'Systemsprachen',
        },
        defaultLanguage: {
            zero: 'Keine Standardsprache',
            one: 'Standardsprache',
            many: 'Standardsprachen',
        },
        makeDefault: 'Als Standard festlegen',
        priceLevel: {
            zero: 'Keine Preisstufe',
            one: 'Preisstufe',
            many: 'Preisstufen',
        },
        prices: {
            zero: 'Kein Preis',
            one: 'Preis',
            many: 'Preise',
        },
        yes: 'Ja',
        no: 'Nein',
        dateResourceActivated: 'Aktivierungsdatum der Ressource',
        durations: {
            zero: 'Keine Dauer',
            one: 'Dauer',
            many: 'Dauern',
        },
        required: '*',
        orders: {
            zero: 'Keine Bestellungen',
            one: 'Bestellung',
            many: 'Bestellungen',
        },
        dimensions: {
            zero: 'Keine Dimensionen',
            one: 'Dimension',
            many: 'Dimensionen',
        },
        url: {
            zero: 'Keine URLs',
            one: 'URL',
            many: 'URLs',
        },
        relatedContents: {
            zero: 'Kein verwandter Inhalt',
            one: 'Verwandter Inhalt',
            many: 'Verwandte Inhalte',
        },
        contents: {
            zero: 'Kein Inhalt',
            one: 'Inhalt',
            many: 'Inhalte',
        },
        range: 'Bereich',
        ago: 'Vor',
        active: 'Aktiv',
        inactive: 'Inaktiv',
        inactiveDescription: 'Dieser Eintrag ist deaktiviert',
        times: {
            zero: 'Keine Zeit',
            one: 'Zeit',
            many: 'Zeiten',
        },
        data: {
            zero: 'Keine Daten',
            one: 'Daten',
            many: 'Daten',
        },
        startTimes: {
            zero: 'Keine Startzeit',
            one: 'Startzeit',
            many: 'Startzeiten',
        },
        pluralizedMinutes: (minutes: number) =>
            pluralize(locale, minutes, (_, variation) => variation, {
                lessThanOne: 'Unter einer Minute',
                one: '1 Minute',
                other: `${minutes} Minuten`,
            }),
        pluralizedHours: (hours: number) =>
            pluralize(locale, hours, (_, variation) => variation, {
                lessThanOne: 'Unter einer Stunde',
                one: '1 Stunde',
                other: `${hours} Stunden`,
            }),
        pluralizedDays: (days: number) =>
            pluralize(locale, days, (_, variation) => variation, {
                lessThanOne: 'Unter einem Tag',
                one: 'Tag',
                other: `${days} Tage`,
            }),
        days: {
            zero: 'Keine Tage',
            one: 'Tag',
            many: 'Tage',
        },
        weeks: {
            zero: 'Keine Wochen',
            one: 'Woche',
            many: 'Wochen',
        },
        months: {
            zero: 'Keine Monate',
            one: 'Monat',
            many: 'Monate',
        },
        years: {
            zero: 'Keine Jahre',
            one: 'Jahr',
            many: 'Jahre',
        },
        daily: 'Täglich',
        weekly: 'Wöchentlich',
        monthly: 'Monatlich',
        yearly: 'Jährlich',
        search: 'Suchen',
        showMore: 'Mehr anzeigen...',
        showLess: 'Weniger anzeigen...',
        copyToClipboard: 'In Zwischenablage kopieren',
        copiedToClipboard: 'In Zwischenablage kopiert',
        example: 'Beispiel',
        showActive: 'Aktive anzeigen',
        showInactive: 'Inaktive anzeigen',
        deactivate: 'Deaktivieren',
        activate: 'Aktivieren',
        open: 'Öffnen',
        valueExists: 'Wert existiert bereits',
        import: 'Importieren',
        importLink: 'Link zum Formular',
        dropFile: 'Datei hier ablegen, um hochzuladen',
        or: 'oder',
        selectFile: 'Datei auswählen',
        selectCSVFormat: 'Datei im CSV-Format auswählen',
        selectImageFormat: 'Bild aus der Liste auswählen',
        invalidFormat: 'Ungültiges Format',
        addToFolder: 'soll dem Ordner hinzugefügt werden',
        andSendToServer: 'und auf den Server hochgeladen werden',
        currencies: {
            zero: 'Keine Währungen',
            one: 'Währung',
            many: 'Währungen',
        },
        hasParent: 'Hat übergeordnetes Element',
        hasChildren: 'Hat Kinder',
        availableRequestTypes: {
            zero: 'Keine verfügbaren Anforderungstypen',
            one: 'Verfügbare Anforderungstyp',
            many: 'Verfügbare Anforderungstypen',
        },
        showFilters: 'Filter anzeigen',
        hideFilters: 'Filter ausblenden',
        deleteDescription:
            'Wenn ein Element gelöscht wird, ist es nicht mehr sichtbar und seine Verknüpfungen zu anderen Elementen existieren nicht mehr.',
        deactivateDescription:
            'Wenn das Element aktiviert ist, wird es wieder sichtbar in Verbindung mit anderen Ressourcen.',
        activateDescription:
            'Wenn ein Element deaktiviert ist, wird es nicht mehr sichtbar und seine Verknüpfungen zu anderen Elementen sind nicht mehr sichtbar. Sie können das Element jederzeit wieder aktivieren.',
        next: 'Weiter',
        back: 'Zurück',
        lists: {
            zero: 'Keine Listen',
            one: 'Liste',
            many: 'Listen',
        },
        tiles: {
            zero: 'Keine Kacheln',
            one: 'Kachel',
            many: 'Kacheln',
        },
        searchInAllLanguages: 'In allen Sprachen suchen',
        nextDay: 'Nächster Tag',
        confirmationMethods: {
            zero: 'Keine Transaktionsbestätigungsmethoden',
            one: 'Transaktionsbestätigungsmethode',
            many: 'Transaktionsbestätigungsmethoden',
        },
        media: {
            zero: 'Kein Medium',
            one: 'Medium',
            many: 'Medien',
        },
        contentTarget: {
            zero: 'Kein Inhaltsziel',
            one: 'Inhaltsziel',
            many: 'Inhaltsziele',
        },
        somethingWentWrong: 'Etwas ist schief gelaufen',
        tryAgain: 'Versuchen Sie es erneut',
        manuals: {
            zero: 'Kein Handbuch',
            one: 'Handbuch',
            many: 'Handbücher',
        },
        trash: 'Müll',
        visibleInSystem: 'Im System sichtbar',
        tvCableType: 'Fernsehkabeltyp',
        isVisibleInTouristApp: 'Ist das Hotel in der Touristen-App verfügbar',
        tvAvailableInputs: 'Verfügbare TV-Eingangstypen',
        tvPredefinedApplications: 'Vordefinierte Anwendungen',
        epgProvider: 'EPG-Anbieter',
        main: 'Haupt',
        displayMain: 'Nur Haupt anzeigen',
        featured: 'Hervorgehoben',
        setFeatured: 'Als hervorgehoben festlegen',
        unsetFeatured: 'Hebe die Hervorhebung auf',
        setAsList: 'Als Liste festlegen',
        setAsTile: 'Als Kachel festlegen',
        assignedAsList: 'Als Liste zugewiesen',
        assignedAsTile: 'Als Kachel zugewiesen',
        featuredCardCannotBeEdited: 'Die hervorgehobene Karte kann nicht bearbeitet werden',
        translationRequired: 'Übersetzung erforderlich',
        fillInvalidRequiredTranslatableFields: (count) =>
            `Füllen Sie die erforderlichen Übersetzungsfelder aus (${count})`,
    },
    auth: {
        ownerContextChanged: 'Der Eigentümerkontext wurde geändert',
    },
    accommodations: {
        response: {
            created: 'Unterkunft wurde hinzugefügt',
            updated: 'Unterkunft wurde aktualisiert',
            deleted: 'Unterkunft wurde gelöscht',
            deactivate: 'Unterkunft wurde deaktiviert',
            activate: 'Unterkunft wurde aktiviert',
        },
        accommodations: {
            zero: 'Keine Unterkünfte',
            one: 'Unterkunft',
            many: 'Unterkünfte',
        },
        onboardingFinishedAt: 'Datum des Abschlusses des Onboardings',
        edit: 'Unterkunft bearbeiten',
        create: 'Unterkunft erstellen',
        activate: 'Unterkunft aktivieren',
        deactivate: 'Unterkunft deaktivieren',
    },
    configurations: {
        configurations: {
            zero: 'Keine Konfigurationen',
            one: 'Konfiguration',
            many: 'Konfigurationen',
        },
        wifi: {
            zero: 'Kein WLAN',
            one: 'WLAN',
            many: 'WLAN',
        },
        authenticationType: 'Authentifizierungstyp',
        wifiIsOpen: 'Offenes WLAN',
        passwordAuthentication: 'Passwort-Authentifizierung',
        authenticationRequired: 'Externe Authentifizierung erforderlich',
        editWifi: 'WLAN bearbeiten',
        createWifi: 'WLAN erstellen',
        pickWifi: 'WLAN auswählen',
        ssid: 'SSID',
        hotelDefault: 'Standard für Hotel',
        securityTypes: {
            zero: 'Kein Sicherheitstyp',
            one: 'Sicherheitstyp',
            many: 'Sicherheitstypen',
        },
        cantSaveDefaultHotel: 'Das standardmäßige WLAN des Hotels kann nicht geändert werden',
        wifiResponse: {
            created: 'WLAN wurde hinzugefügt',
            updated: 'WLAN wurde aktualisiert',
            deleted: 'WLAN wurde gelöscht',
        },
        informationCards: {
            zero: 'Keine Informationskarten',
            one: 'Informationskarte',
            many: 'Informationskarten',
        },
        informationCardResponse: {
            created: 'Informationskarte wurde hinzugefügt',
            updated: 'Informationskarte wurde aktualisiert',
            deleted: 'Informationskarte wurde gelöscht',
            deactivate: 'Informationskarte wurde deaktiviert',
            activate: 'Informationskarte wurde aktiviert',
        },
        editInformationCard: 'Informationskarte bearbeiten',
        createInformationCard: 'Informationskarte erstellen',
        deactivateInformationCard: 'Informationskarte aktivieren',
        activateInformationCard: 'Informationskarte deaktivieren',
        navigateToStore: 'Zum Store navigieren',
        navigateToMarketplace: "Zum 'Marketplace' navigieren",
        informationCardsDescription:
            'Informationskarten werden in den Systemansichten ganz oben auf dem Bildschirm angezeigt',
        roomServiceCards: {
            zero: 'Keine Zimmerservicekarten',
            one: 'Zimmerservicekarte',
            many: 'Zimmerservicekarten',
        },
        roomServiceCardDescription: 'Die Zimmerservicekarte wird in allen Ansichten angezeigt',
        storeCards: {
            zero: 'Keine Store-Karten',
            one: 'Store-Karte',
            many: 'Store-Karten',
        },
        storeCardDescription:
            'Setzen Sie die Store-Karte auf die ausgewählte Ansicht, damit sie im System angezeigt wird',
        storyDescription: 'Story-Beschreibung',
        peripheralTemplates: {
            peripheralTemplates: {
                zero: 'Kein externes Zubehör installiert',
                one: 'Externes Zubehör installiert',
                many: 'Externes Zubehör installiert',
            },
            createPeripheralTemplate: 'Installiertes externes Zubehör erstellen',
            editPeripheralTemplate: 'Installiertes externes Zubehör bearbeiten',
            response: {
                created: 'Installiertes externes Zubehör wurde hinzugefügt',
                updated: 'Installiertes externes Zubehör wurde aktualisiert',
                deleted: 'Installiertes externes Zubehör wurde gelöscht',
            },
        },
        peripheralTypes: {
            peripheralTypes: {
                zero: 'Keine externes Zubehör',
                one: 'Externes Zubehör',
                many: 'Externes Zubehör',
            },
            createPeripheralType: 'Externes Zubehör erstellen',
            editPeripheralType: 'Externes Zubehör bearbeiten',
            supportedTechnologies: 'Unterstützte Betriebssysteme',
            response: {
                created: 'Externes Zubehör wurde hinzugefügt',
                updated: 'Externes Zubehör wurde aktualisiert',
                deleted: 'Externes Zubehör wurde gelöscht',
            },
        },
        isMarketplaceAvailable: 'Ist das Marktplatzmodul aktiviert',
    },
    events: {
        response: {
            created: 'Veranstaltung wurde hinzugefügt',
            updated: 'Veranstaltung wurde aktualisiert',
            deleted: 'Veranstaltung wurde gelöscht',
            deactivate: 'Veranstaltung wurde deaktiviert',
            activate: 'Veranstaltung wurde aktiviert',
        },
        events: {
            zero: 'Keine Veranstaltungen',
            one: 'Veranstaltung',
            many: 'Veranstaltungen',
        },
        relatedEvents: {
            zero: 'Keine verwandten Veranstaltungen',
            one: 'Verwandte Veranstaltung',
            many: 'Verwandte Veranstaltungen',
        },
        edit: 'Veranstaltung bearbeiten',
        activate: 'Veranstaltung aktivieren',
        deactivate: 'Veranstaltung deaktivieren',
        create: 'Veranstaltung erstellen',
        createEventFromContentOrLibrary:
            'Eine Veranstaltung kann aus einer Story, einer Informationskarte, einem Ort oder der Content-Bibliothek erstellt werden',
    },
    places: {
        places: {
            zero: 'Keine Orte',
            one: 'Ort',
            many: 'Orte',
        },
        relatedPlaces: {
            zero: 'Keine verwandten Orte',
            one: 'Verwandter Ort',
            many: 'Verwandte Orte',
        },
        response: {
            created: 'Ort wurde hinzugefügt',
            updated: 'Ort wurde aktualisiert',
            deleted: 'Ort wurde gelöscht',
            deactivate: 'Ort wurde deaktiviert',
            activate: 'Ort wurde aktiviert',
        },
        edit: 'Ort bearbeiten',
        activate: 'Ort aktivieren',
        deactivate: 'Ort deaktivieren',
        create: 'Ort erstellen',
        createFromContentOrLibrary:
            'Erstellen Sie einen Ort, wenn Sie ihn noch nicht in der Liste finden.',
        placeToVisit: 'Orte zu besuchen',
        happeningHere: 'Das ist hier los',
    },
    informationCards: {
        informationCards: {
            zero: 'Keine Informationskarten',
            one: 'Informationskarte',
            many: 'Informationskarten',
        },
        relatedInformationCards: {
            zero: 'Keine verwandten Informationskarten',
            one: 'Verwandte Informationskarte',
            many: 'Verwandte Informations',
        },
        edit: 'Informationskarte bearbeiten',
        activate: 'Informationskarte aktivieren',
        deactivate: 'Informationskarte deaktivieren',
        create: 'Informationskarte erstellen',
        response: {
            created: 'Informationskarte wurde hinzugefügt',
            updated: 'Informationskarte wurde aktualisiert',
            deleted: 'Informationskarte wurde gelöscht',
            deactivate: 'Informationskarte wurde deaktiviert',
            activate: 'Informationskarte wurde aktiviert',
        },
        createInformationFromContentOrLibrary:
            'Informationskarte kann aus der Story oder der Content-Bibliothek erstellt werden',
    },
    stories: {
        stories: {
            zero: 'Keine Geschichten',
            one: 'Geschichte',
            many: 'Geschichten',
        },
        relatedStories: {
            zero: 'Keine verwandten Geschichten',
            one: 'Verwandte Geschichte',
            many: 'Verwandte Geschichten',
        },
        displayOption: 'Anzeigeoption im System',
        response: {
            created: 'Geschichte wurde hinzugefügt',
            updated: 'Geschichte wurde aktualisiert',
            deleted: 'Geschichte wurde gelöscht',
            deactivate: 'Geschichte wurde deaktiviert',
            activate: 'Geschichte wurde aktiviert',
        },
        edit: 'Geschichte bearbeiten',
        activate: 'Geschichte aktivieren',
        deactivate: 'Geschichte deaktivieren',
        create: 'Geschichte erstellen',
        createStoryFromHotelOrLibrary:
            'Geschichte kann nur aus der Hotelansicht oder der Content-Bibliothek erstellt werden',
    },
    activities: {
        activities: {
            zero: 'Keine Aktivitäten',
            one: 'Aktivität',
            many: 'Aktivitäten',
        },
        relatedActivities: {
            zero: 'Keine verwandten Aktivitäten',
            one: 'Verwandte Aktivität',
            many: 'Verwandte Aktivitäten',
        },
        response: {
            created: 'Aktivität wurde hinzugefügt',
            updated: 'Aktivität wurde aktualisiert',
            deleted: 'Aktivität wurde gelöscht',
            deactivate: 'Aktivität wurde deaktiviert',
            activate: 'Aktivität wurde aktiviert',
        },
        edit: 'Aktivität bearbeiten',
        activate: 'Aktivität aktivieren',
        deactivate: 'Aktivität deaktivieren',
        create: 'Aktivität erstellen',
        createActivityFromContentOrLibrary:
            'Eine Aktivität kann aus der Story, der Informationskarte, dem Ort oder der Content-Bibliothek erstellt werden',
    },
    devices: {
        devices: {
            zero: 'Keine Geräte',
            one: 'Gerät',
            many: 'Geräte',
        },
        register: 'Gerät registrieren',
        edit: 'Gerät bearbeiten',
        activate: 'Gerät aktivieren',
        deactivate: 'Gerät deaktivieren',
        deviceId: 'Geräte-ID',
        models: {
            zero: 'Keine Gerätemodelle',
            one: 'Gerätemodell',
            many: 'Gerätemodelle',
        },
        serialNumber: 'Seriennummer',
        portType: 'Porttyp',
        portIndex: 'Port-Nummer',
        response: {
            updated: 'Gerät wurde gelöscht',
            deleted: 'Gerät wurde aktualisiert',
            register: 'Gerät wurde registriert',
            deactivate: 'Gerät wurde deaktiviert',
            activate: 'Gerät wurde aktiviert',
        },
        timestampLastToken: 'Letzter Zeitstempel des aktiven Geräts',
        creationDate: 'Erstellungsdatum',
        deactivationDate: 'Deaktivierungsdatum',
    },
    images: {
        images: {
            zero: 'Keine Bilder',
            one: 'Bild',
            many: 'Bilder',
        },
        icons: {
            zero: 'Keine Icons',
            one: 'Icon',
            many: 'Icons',
        },
        response: {
            created: 'Bild wurde hinzugefügt',
            updated: 'Bild wurde aktualisiert',
            deleted: 'Bild wurde gelöscht',
        },
        editImage: 'Bild bearbeiten',
        createImage: 'Bild erstellen',
        deleteImage: 'Bild löschen',
    },
    tvSeries: {
        tvSeries: {
            zero: 'Keine TV-Serie',
            one: 'TV-Serie',
            many: 'TV-Serien',
        },
        create: 'TV-Serie erstellen',
        delete: 'TV-Serie löschen',
        edit: 'TV-Serie bearbeiten',
        response: {
            created: 'TV-Serie wurde hinzugefügt',
            deleted: 'TV-Serie wurde gelöscht',
            updated: 'TV-Serie wurde aktualisiert',
        },
        tllElements: {
            zero: 'Keine .tll-Dateielemente',
            one: '.tll-Dateielement',
            many: '.tll-Dateielemente',
        },
    },
    menuItems: {
        menuItems: {
            zero: 'Keine Menüpunkte',
            one: 'Menüpunkt',
            many: 'Menüpunkte',
        },
        response: {
            created: 'Menüpunkt wurde erstellt',
            deleted: 'Menüpunkt wurde gelöscht',
            updated: 'Menüpunkt wurde aktualisiert',
        },
        create: 'Menüpunkt erstellen',
        edit: 'Menüpunkt bearbeiten',
        delete: 'Menüpunkt löschen',
        setCard: 'Karte setzen',
        menuItemsCreateContentDescription:
            'Fügen Sie Menüpunkte hinzu, wenn Sie möchten, dass die Karte darin als Hauptpunkt angezeigt wird',
    },
    stores: {
        stores: {
            zero: 'Keine Geschäfte',
            one: 'Geschäft',
            many: 'Geschäfte',
        },
        response: {
            created: 'Geschäft wurde hinzugefügt',
            updated: 'Geschäft wurde aktualisiert',
            deleted: 'Geschäft wurde gelöscht',
            deactivate: 'Geschäft wurde deaktiviert',
            activate: 'Geschäft wurde aktiviert',
        },
        editStore: 'Geschäft bearbeiten',
        createStore: 'Geschäft erstellen',
        activateStore: 'Geschäft aktivieren',
        deactivateStore: 'Geschäft deaktivieren',
        deliveryHours: {
            deliveryHours: {
                zero: 'Keine Lieferzeiten',
                one: 'Lieferzeit',
                many: 'Lieferzeiten',
            },
            response: {
                created: 'Lieferzeiten wurden hinzugefügt',
                updated: 'Lieferzeiten wurden aktualisiert',
                deleted: 'Lieferzeiten wurden gelöscht',
            },
        },
        emailLanguage: 'E-Mail-Nachrichtensprache',
    },
    products: {
        products: {
            zero: 'Keine ',
            one: 'Produkt',
            many: 'Produkte',
        },
        recommendedProducts: {
            zero: 'Keine empfohlenen Produkte',
            one: 'Empfohlenes Produkt',
            many: 'Empfohlene Produkte',
        },
        recommendedProductsDescription:
            'In jedem Geschäft werden 3 empfohlene Produkte angezeigt. Wenn keine festgelegt sind, werden sie zufällig generiert.',
        relatedProducts: {
            zero: 'Keine verwandten Produkte',
            one: 'Verwandtes Produkt',
            many: 'Verwandte Produkte',
        },
        response: {
            created: 'Produkt wurde hinzugefügt',
            updated: 'Produkt wurde aktualisiert',
            deleted: 'Produkt wurde gelöscht',
            deactivate: 'Produkt wurde deaktiviert',
            activate: 'Produkt wurde aktiviert',
        },
        editProduct: 'Produkt bearbeiten',
        createProduct: 'Produkt erstellen',
        activateProduct: 'Produkt aktivieren',
        deactivateProduct: 'Produkt deaktivieren',
        deliveryTime: {
            zero: 'Keine Lieferzeit',
            one: 'Lieferzeit',
            many: 'Lieferzeiten',
        },
        deliveryMethods: {
            zero: 'Keine Liefermethoden',
            one: 'Liefermethode',
            many: 'Liefermethoden',
        },
        contentInProductDescription:
            'Wenn eine Ressource hinzugefügt wird, ist das Produkt im Inhalt sichtbar und kann gekauft werden.',
        productInContentDescription:
            'Wenn ein Produkt dem Inhalt hinzugefügt wird, ist das Produkt im Inhalt sichtbar und kann gekauft werden.',
        displayProducts: 'Wählen Sie ein Geschäft aus, um Produkte anzuzeigen',
        categories: {
            categories: {
                zero: 'Keine Kategorien',
                one: 'Kategorie',
                many: 'Kategorien',
            },
            editCategory: 'Kategorie bearbeiten',
            createCategory: 'Kategorie erstellen',
            activateCategory: 'Kategorie aktivieren',
            deactivateCategory: 'Kategorie deaktivieren',
            pickCategory: 'Kategorie auswählen',
            response: {
                created: 'Kategorie wurde hinzugefügt',
                updated: 'Kategorie wurde aktualisiert',
                deleted: 'Kategorie wurde gelöscht',
                deactivate: 'Kategorie wurde deaktiviert',
                activate: 'Kategorie wurde aktiviert',
            },
        },
    },
    services: {
        services: {
            zero: 'Keine Dienste',
            one: 'Dienst',
            many: 'Dienste',
        },
    },
    contents: {
        contents: {
            zero: 'Kein Inhalt',
            one: 'Inhalt',
            many: 'Inhalte',
        },
        relatedToContent: (name) => `Bezogen auf ${name}`,
        entryFee: {
            zero: 'Keine Eintrittsgebühr',
            one: 'Eintrittsgebühr',
            many: 'Eintrittsgebühren',
        },
        regionExclusions: 'Regionale Ausschlüsse',
        reservationRequired: {
            zero: 'Keine erforderliche Reservierung',
            one: 'Erforderliche Reservierung',
            many: 'Erforderliche Reservierungen',
        },
        visibilityInMenuItems: 'Sichtbarkeit in Menüpunkten',
        visibilityInHelp: 'Sichtbarkeit in Hilfe',
        relatedHotelObjectHotelDescription:
            'Wenn ein Hotel hinzugefügt wird, ist der Inhalt nur im ausgewählten Hotel sichtbar. Der Inhalt kann mit vielen Hotelobjekten (Hotel/Zimmer) verknüpft werden.',
        relatedHotelObjectRoomDescription:
            'Wenn ein Zimmer hinzugefügt wird, ist der Inhalt nur im ausgewählten Zimmer sichtbar. Der Inhalt kann mit vielen Hotelobjekten (Hotel/Zimmer) verknüpft werden.',
        relatedHotelObjectDescription:
            'Wenn ein Hotelobjekt hinzugefügt wird, wird der Inhalt zu Hotelinhalt. Der Inhalt kann hotelbezogen oder regional sein.',
        regionExclusionsDescription:
            'Wenn einer Region (Details) der Inhalt zugewiesen wird, wird der Inhalt zu regionalen Inhalten. Der Inhalt kann hotelbezogen oder regional sein.',
        preview: 'Vorschau',
        hotelContent: 'Hotelinhalt',
        eventEnded: 'Ereignis beendet',
        response: {
            deactivate: 'Der Inhalt wurde deaktiviert',
            activate: 'Der Inhalt wurde aktiviert',
        },
        activate: 'Inhalt aktivieren',
        deactivate: 'Inhalt deaktivieren',
    },
    hotels: {
        hotels: {
            zero: 'Keine Hotels',
            one: 'Hotel',
            many: 'Hotels',
        },
        response: {
            created: 'Hotel wurde hinzugefügt',
            updated: 'Hotel wurde aktualisiert',
            deleted: 'Hotel wurde gelöscht',
            deactivate: 'Hotel wurde deaktiviert',
            activate: 'Hotel wurde aktiviert',
        },
        editHotel: 'Hotel bearbeiten',
        editHotelDetails: 'Hotelinformationen bearbeiten',
        createHotel: 'Hotel erstellen',
        activateHotel: 'Hotel aktivieren',
        deactivateHotel: 'Hotel deaktivieren',
        checkInTime: 'Check-in-Zeit',
        checkOutTime: 'Check-out-Zeit',
        relatedHotels: {
            zero: 'Keine verbundenen Hotels',
            one: 'Verbundenes Hotel',
            many: 'Verbundene Hotels',
        },
        relatedHotelObjects: {
            zero: 'Keine verbundenen Hotelobjekte',
            one: 'Verbundenes Hotelobjekt',
            many: 'Verbundene Hotelobjekte',
        },
        hotelServiceMode: 'Hotelservice anzeigen',
    },
    hotelGroups: {
        hotelGroups: {
            zero: 'Keine Hotelgruppen',
            one: 'Hotelgruppe',
            many: 'Hotelgruppen',
        },
        create: 'Hotelgruppe erstellen',
        edit: 'Hotelgruppe bearbeiten',
        delete: 'Hotelgruppe löschen',
        response: {
            created: 'Hotelgruppe wurde erstellt',
            updated: 'Hotelgruppe wurde aktualisiert',
            deleted: 'Hotelgruppe wurde gelöscht',
        },
    },
    roomGroups: {
        roomGroups: {
            zero: 'Keine Zimmergruppen',
            one: 'Zimmergruppe',
            many: 'Zimmergruppen',
        },
        create: 'Zimmergruppe erstellen',
        edit: 'Zimmergruppe bearbeiten',
        delete: 'Zimmergruppe löschen',
        response: {
            created: 'Zimmergruppe wurde erstellt',
            updated: 'Zimmergruppe wurde aktualisiert',
            deleted: 'Zimmergruppe wurde gelöscht',
        },
    },
    openingHours: {
        openingHours: {
            zero: 'Keine Öffnungszeiten',
            one: 'Öffnungszeit',
            many: 'Öffnungszeiten',
        },
        openingHoursSchedules: {
            zero: 'Kein Öffnungszeitenplan',
            one: 'Öffnungszeitenplan',
            many: 'Öffnungszeitenpläne',
        },
        openingHoursExclusionSchedules: {
            zero: 'Kein Ausnahmenplan für Öffnungszeiten',
            one: 'Ausnahmenplan für Öffnungszeiten',
            many: 'Ausnahmenpläne für Öffnungszeiten',
        },
        response: {
            created: 'Öffnungszeiten wurden hinzugefügt',
            updated: 'Öffnungszeiten wurden aktualisiert',
            deleted: 'Öffnungszeiten wurden gelöscht',
        },
        editSchedule: 'Öffnungszeitenplan bearbeiten',
        createSchedule: 'Öffnungszeitenplan erstellen',
        editScheduleExclusions: 'Ausnahmenplan für Öffnungszeiten bearbeiten',
        createScheduleExclusions: 'Ausnahmenplan für Öffnungszeiten erstellen',
        repeatDaily: 'Wiederholung im täglichen Zyklus',
        repeatWeekly: 'Wiederholung im wöchentlichen Zyklus',
        repeatMonthly: 'Wiederholung im monatlichen Zyklus',
        repeatYearly: 'Wiederholung im jährlichen Zyklus',
        weekdays: 'Keine Wochentage | Wochentag | Wochentage',
        cycleFrequency: 'Zyklushäufigkeit',
        dayOrigin: 'Ursprungstag',
        dayOffset: 'Versatz in Tagen',
        weekOrigin: 'Ursprungswoche',
        weekOffset: 'Versatz in Wochen',
        closed: 'Geschlossen',
        cycleFrequencyDescription:
            'Wählen Sie den Zyklus für die Wiederholung der Öffnungszeiten aus',
        type: {
            type: 'Periodentyp',
            weekday: 'Wochentag',
            weekdaysRange: 'Wochentagsbereich',
            holiday: 'Feiertage',
            other: 'Andere',
        },
        hoursRangeType: {
            hoursRangeType: 'Zeitbereichstyp',
            range: 'Stundenbereich',
            off: 'Den ganzen Tag geschlossen',
        },
        validationError: 'Die angegebene Öffnungszeitenangabe ist ungültig',
        invalidValue: 'Ungültiger Wert',
    },
    contacts: {
        contacts: {
            zero: 'Keine Kontakte',
            one: 'Kontakt',
            many: 'Kontakte',
        },
        hotelContacts: {
            zero: 'Keine Hotelkontakte',
            one: 'Hotelkontakt',
            many: 'Hotelkontakte',
        },
        emergencyContacts: {
            zero: 'Keine Notfallkontakte',
            one: 'Notfallkontakt',
            many: 'Notfallkontakte',
        },
        requestCallbackContacts: {
            zero: 'Keine Rückrufkontakte',
            one: 'Rückrufkontakt',
            many: 'Rückrufkontakte',
        },
        phoneNumber: 'Telefonnummer',
        emails: {
            zero: 'Keine E-Mails',
            one: 'E-Mail',
            many: 'E-Mails',
        },
        response: {
            created: 'Kontakt wurde hinzugefügt',
            updated: 'Kontakt wurde aktualisiert',
            deleted: 'Kontakt wurde gelöscht',
        },
        editEmail: 'E-Mail bearbeiten',
        createEmail: 'E-Mail erstellen',
        editContact: 'Kontakt bearbeiten',
        createContact: 'Kontakt erstellen',
        deleteContact: 'Kontakt löschen',
    },
    address: {
        address: {
            zero: 'Keine Adresse',
            one: 'Adresse',
            many: 'Adressen',
        },
        cities: {
            zero: 'Keine Städte',
            one: 'Stadt',
            many: 'Städte',
        },
        latitudeAndLongitude: 'Breitengrad und Längengrad',
        latitudeAndLongitudeDescription:
            'Klicken Sie mit der rechten Maustaste auf den gewünschten Ort auf der Karte und dann mit der linken Maustaste auf die Koordinaten, z.B. 50.02128, 19.88629. Fügen Sie sie in eines der folgenden Felder ein.',
        latitude: 'Breitengrad',
        longitude: 'Längengrad',
        postalCode: 'Postleitzahl',
        state: 'Bundesland',
        line1: 'Adresszeile 1',
        line2: 'Adresszeile 2',
        line3: 'Adresszeile 3',
        response: {
            updated: 'Adresse wurde aktualisiert',
            deleted: 'Adresse wurde gelöscht',
        },
        editAddressDetails: 'Adressdetails bearbeiten',
        filledIdAutomatically:
            'Die Adresse wurde automatisch aus der verknüpften Ressource ausgefüllt. Sie können sie trotzdem bearbeiten.',
    },
    users: {
        users: {
            zero: 'Keine Benutzer',
            one: 'Benutzer',
            many: 'Benutzer',
        },
        logins: {
            zero: 'Keine Anmeldungen',
            one: 'Anmeldung',
            many: 'Anmeldungen',
        },
        signIn: 'Anmelden',
        signOut: 'Abmelden',
        passwordChange: 'Passwortänderung',
        myAccount: 'Mein Konto',
        usernames: {
            zero: 'Keine Benutzernamen',
            one: 'Benutzername',
            many: 'Benutzernamen',
        },
        password: {
            zero: 'Kein Passwort',
            one: 'Passwort',
            many: 'Passwörter',
        },
        repeatPassword: 'Passwort wiederholen',
        changePassword: 'Passwort ändern',
        administrators: {
            zero: 'Keine Administratoren',
            one: 'Administrator',
            many: 'Administratoren',
        },
        technicians: {
            zero: 'Keine Techniker',
            one: 'Techniker',
            many: 'Techniker',
        },
        role: {
            role: 'Rolle',
            admin: 'Administrator',
            owner: 'Eigentümer',
            hotelier: 'Hotelier',
        },
        response: {
            created: 'Benutzer wurde hinzugefügt',
            updated: 'Benutzer wurde aktualisiert',
            deleted: 'Benutzer wurde gelöscht',
            deactivate: 'Benutzer wurde deaktiviert',
            activate: 'Benutzer wurde aktiviert',
        },
        editTechnician: 'Techniker bearbeiten',
        createTechnician: 'Techniker erstellen',
        activateTechnician: 'Techniker aktivieren',
        deactivateTechnician: 'Techniker deaktivieren',
        editAdministrator: 'Administrator bearbeiten',
        createAdministrator: 'Administrator erstellen',
        activateAdministrator: 'Administrator aktivieren',
        deactivateAdministrator: 'Administrator deaktivieren',
    },
    views: {
        views: {
            zero: 'Keine Ansichten',
            one: 'Ansicht',
            many: 'Ansichten',
        },
        createView:
            'Fügen Sie eine neue Ansicht hinzu, um mit dem Hinzufügen von Inhalten zu beginnen.',
        experience: 'Erlebnis',
        discover: 'Entdecken',
        taste: 'Geschmack',
        help: 'Hilfe',
        hospitals: {
            zero: 'Keine Krankenhäuser',
            one: 'Krankenhaus',
            many: 'Krankenhäuser',
        },
        pharmacies: {
            zero: 'Keine Apotheken',
            one: 'Apotheke',
            many: 'Apotheken',
        },
        pickHotels: 'Wählen Sie ein Hotel aus, um verfügbare Ansichten anzuzeigen',
    },
    contentFeeds: {
        contentFeeds: {
            zero: 'Keine Inhaltsfeeds',
            one: 'Inhaltsfeed',
            many: 'Inhaltsfeeds',
        },
        create: 'Inhaltsfeed erstellen',
        edit: 'Inhaltsfeed bearbeiten',
        delete: 'Inhaltsfeed löschen',
        activate: 'Inhaltsfeed aktivieren',
        deactivate: 'Inhaltsfeed deaktivieren',
        response: {
            created: 'Inhaltsfeed wurde erstellt',
            updated: 'Inhaltsfeed wurde aktualisiert',
            deleted: 'Inhaltsfeed wurde gelöscht',
            deactivate: 'Inhaltsfeed wurde deaktiviert',
            activate: 'Inhaltsfeed wurde aktiviert',
        },
    },
    countries: {
        countries: {
            zero: 'Keine Länder',
            one: 'Land',
            many: 'Länder',
        },
        editCountry: 'Land bearbeiten',
        createCountry: 'Land erstellen',
        activateCountry: 'Land aktivieren',
        deactivateCountry: 'Land deaktivieren',
        response: {
            created: 'Land wurde hinzugefügt',
            updated: 'Land wurde aktualisiert',
            deleted: 'Land wurde gelöscht',
            deactivate: 'Land wurde deaktiviert',
            activate: 'Land wurde aktiviert',
        },
        countryCode: 'Ländercode (ISO Alpha 2)',
        countryCodeInvalid: 'Ländercode entspricht nicht dem ISO Alpha 2-Standard',
    },
    regions: {
        regions: {
            zero: 'Keine Regionen',
            one: 'Region',
            many: 'Regionen',
        },
        response: {
            created: 'Region wurde hinzugefügt',
            updated: 'Region wurde aktualisiert',
            deleted: 'Region wurde gelöscht',
            deactivate: 'Region wurde deaktiviert',
            activate: 'Region wurde aktiviert',
        },
        editRegion: 'Region bearbeiten',
        createRegion: 'Region erstellen',
        activateRegion: 'Region aktivieren',
        deactivateRegion: 'Region deaktivieren',
        regionCode: 'Regionscode',
        polygonDescription:
            'Kopieren Sie die Polygon-Koordinaten in die Zwischenablage, klicken Sie auf den Link und importieren Sie die Koordinaten, um das Regionspolygon anzuzeigen',
        polygonUrlDescription: 'Polygon anzeigen',
        createUrlDescription: 'Polygon erstellen',
        polygons: {
            zero: 'Keine Polygone',
            one: 'Polygon',
            many: 'Polygone',
        },
        coordinates: {
            zero: 'Keine Koordinaten',
            one: 'Koordinate',
            many: 'Koordinaten',
        },
        closePolygon:
            "Vergessen Sie nicht, das Polygon zu schließen. Unter den Koordinaten - 'type': 'Polygon'",
        polygonNotClosed: 'Polygon wurde nicht geschlossen',
    },
    owners: {
        owners: {
            zero: 'Keine Besitzer',
            one: 'Besitzer',
            many: 'Besitzer',
        },
        create: 'Eigentümer erstellen',
        edit: 'Eigentümer bearbeiten',
        delete: 'Besitzer löschen',
        hotelManagement:
            'Übersichtsliste der Hotels. Der Eigentümer kann in den Hoteldetails geändert werden',
        response: {
            created: 'Eigentümer wurde erstellt',
            updated: 'Der Besitzer wurde aktualisiert',
            deleted: 'Besitzer wurde gelöscht',
        },
        cannotCreateLibrary:
            'Inhaltsbibliothek konnte nicht erstellt werden. Keine mit dem Eigentümer verbundenen Hotels',
        cannotCreateStore:
            'Es kann kein Store erstellt werden. Mit dem Eigentümer des Anbieters sind keine Hotels verbunden',
    },
    contentLibraries: {
        contentLibraries: {
            zero: 'Keine Inhaltsbibliotheken',
            one: 'Inhaltsbibliothek',
            many: 'Inhaltsbibliotheken',
        },
        shared: 'Geteilt',
        sharedLibrary: 'Geteilte Bibliothek',
        cardInSharedLibrary: 'Karte in geteilter Bibliothek',
        create: 'Inhaltsbibliothek erstellen',
        edit: 'Inhaltsbibliothek bearbeiten',
        delete: 'Inhaltsbibliothek löschen',
        response: {
            created: 'Inhaltsbibliothek wurde erstellt',
            updated: 'Inhaltsbibliothek wurde aktualisiert',
            deleted: 'Inhaltsbibliothek wurde gelöscht',
        },
    },
    rooms: {
        rooms: {
            zero: 'Keine Zimmer',
            one: 'Zimmer',
            many: 'Zimmer',
        },
        response: {
            created: 'Zimmer wurde hinzugefügt',
            updated: 'Zimmer wurde aktualisiert',
            deleted: 'Zimmer wurde gelöscht',
            deactivate: 'Zimmer wurde deaktiviert',
            activate: 'Zimmer wurde aktiviert',
        },
        editRoom: 'Zimmer bearbeiten',
        createRoom: 'Zimmer erstellen',
        activateRoom: 'Zimmer aktivieren',
        deactivateRoom: 'Zimmer deaktivieren',
        roomNumber: 'Zimmernummer',
        roomName: 'Zimmername',
        roomCode: 'Zimmercode',
        maxOccupancy: {
            zero: 'Keine maximale Belegung',
            one: 'Maximale Belegung',
            many: 'Maximale Belegungen',
        },
        roomType: {
            zero: 'Kein Zimmertyp',
            one: 'Zimmertyp',
            many: 'Zimmertypen',
        },
        roomExists: 'Zimmer existiert bereits',
        relatedRooms: {
            zero: 'Keine verbundenen Zimmer',
            one: 'Verbundenes Zimmer',
            many: 'Verbundene Zimmer',
        },
        displayRooms: 'Wählen Sie ein Hotel aus, um Zimmer anzuzeigen',
    },
    guests: {
        guests: {
            zero: 'Keine Gäste',
            one: 'Gast',
            many: 'Gäste',
        },
        response: {
            created: 'Gast wurde hinzugefügt',
            updated: 'Gast wurde aktualisiert',
            deleted: 'Gast wurde gelöscht',
            deactivate: 'Gast wurde deaktiviert',
            activate: 'Gast wurde aktiviert',
        },
        editGuest: 'Gast bearbeiten',
        createGuest: 'Gast erstellen',
        activateGuest: 'Gast aktivieren',
        deactivateGuest: 'Gast deaktivieren',
        classifications: {
            zero: 'Keine Klassifizierung',
            one: 'Klassifizierung',
            many: 'Klassifizierungen',
        },
    },
    checklists: {
        checklists: {
            zero: 'Keine Checklisten',
            one: 'Checkliste',
            many: 'Checklisten',
        },
        predefinedChecklists: {
            zero: 'Keine vordefinierten Checklisten',
            one: 'Vordefinierte Checkliste',
            many: 'Vordefinierte Checklisten',
        },
        response: {
            created: 'Vordefinierte Checkliste wurde hinzugefügt',
            updated: 'Vordefinierte Checkliste wurde aktualisiert',
            deleted: 'Vordefinierte Checkliste wurde gelöscht',
        },
        editPredefinedChecklists: 'Vordefinierte Checkliste bearbeiten',
        createPredefinedChecklists: 'Vordefinierte Checkliste erstellen',
    },
    tasks: {
        tasks: {
            zero: 'Keine Aufgaben',
            one: 'Aufgabe',
            many: 'Aufgaben',
        },
        response: {
            created: 'Vordefinierte Aufgabe wurde hinzugefügt',
            updated: 'Vordefinierte Aufgabe wurde aktualisiert',
            deleted: 'Vordefinierte Aufgabe wurde gelöscht',
            deactivate: 'Vordefinierte Aufgabe wurde deaktiviert',
            activate: 'Vordefinierte Aufgabe wurde aktiviert',
        },
        editPredefinedTasks: 'Vordefinierte Aufgabe bearbeiten',
        createPredefinedTasks: 'Vordefinierte Aufgabe erstellen',
        activatePredefinedTasks: 'Vordefinierte Aufgabe aktivieren',
        deactivatePredefinedTasks: 'Vordefinierte Aufgabe deaktivieren',
        predefinedTasks: {
            zero: 'Keine vordefinierten Aufgaben',
            one: 'Vordefinierte Aufgabe',
            many: 'Vordefinierte Aufgaben',
        },
    },
    maintenances: {
        maintenances: {
            zero: 'Keine Wartungen',
            one: 'Wartung',
            many: 'Wartungen',
        },
        response: {
            created: 'Wartung wurde hinzugefügt',
            updated: 'Wartung wurde aktualisiert',
            deleted: 'Wartung wurde gelöscht',
        },
        editMaintenance: 'Wartung bearbeiten',
        createMaintenance: 'Wartung erstellen',
        deleteMaintenance: 'Wartung löschen',
    },
    azIndex: {
        azIndex: {
            zero: 'Keine A-Z-Indizes',
            one: 'A-Z-Index',
            many: 'A-Z-Indizes',
        },
        create: 'Index erstellen',
        delete: 'Index löschen',
        edit: 'A-Z-Index bearbeiten',
        response: {
            created: 'Index wurde hinzugefügt',
            updated: 'Index wurde aktualisiert',
            deleted: 'Index wurde gelöscht',
        },
    },
    smartCards: {
        smartCards: {
            zero: 'Keine Smart-Karten',
            one: 'Smart-Karte',
            many: 'Smart-Karten',
        },
        smartCardsMenu: 'Menü (Inhalt)',
        response: {
            created: 'Smart-Karte wurde hinzugefügt',
            updated: 'Smart-Karte wurde aktualisiert',
            deleted: 'Smart-Karte wurde gelöscht',
            deactivate: 'Smart-Karte wurde deaktiviert',
            activate: 'Smart-Karte wurde aktiviert',
        },
        edit: 'Smart-Karte bearbeiten',
        activate: 'Smart-Karte aktivieren',
        deactivate: 'Smart-Karte deaktivieren',
        create: 'Smart-Karte erstellen',
        type: 'Kartentyp',
    },
    administration: {
        administration: 'Administration',
    },
    systemMessages: {
        systemMessages: {
            zero: 'Keine Systemmeldungen',
            one: 'Systemnachricht',
            many: 'Systemmeldungen',
        },
        create: 'Systemnachricht erstellen',
        delete: 'Systemnachricht löschen',
        edit: 'Systemnachricht bearbeiten',
        activate: 'Systemnachricht aktivieren',
        deactivate: 'Systemnachricht deaktivieren',
        response: {
            created: 'Systemnachricht wurde hinzugefügt',
            updated: 'Systemnachricht wurde aktualisiert',
            activated: 'Systemnachricht been aktiviert',
            deactivated: 'Systemnachricht been deaktiviert',
        },
    },
    notifications: {
        notifications: {
            zero: 'Keine Benachrichtigungen',
            one: 'Benachrichtigung',
            many: 'Benachrichtigungen',
        },
        response: {
            sent: 'Benachrichtigung wurde gesendet',
            updated: 'Benachrichtigung wurde aktualisiert',
            deleted: 'Benachrichtigung wurde gelöscht',
        },
        edit: 'Benachrichtigung bearbeiten',
        create: 'Benachrichtigung erstellen',
        delete: 'Benachrichtigung löschen',
        send: 'Benachrichtigung senden',
        messages: {
            zero: 'Keine Nachrichten',
            one: 'Nachricht',
            many: 'Nachrichten',
        },
        unread: 'Ungelesen',
        sendingTime: 'Sendezeit',
    },
    marketplace: {
        marketplace: 'Marktplatz',
    },
}
