import { LANGUAGE, LANGUAGE_FALLBACK, LOCAL_STORAGE_KEY } from '@/constants'

export const getStorageOrBrowserOrFallbackLocale = (): LANGUAGE => {
    const languageCodes = Object.values(LANGUAGE)
    const browserLang = navigator.language.toLowerCase()
    const storageLang = localStorage.getItem(LOCAL_STORAGE_KEY.LOCALE)
    if (storageLang) {
        const storageExactLang = languageCodes.find(
            (code) => code.toString().toLowerCase() === storageLang
        )
        if (storageExactLang) return storageExactLang
    }
    const exactLang = languageCodes.find(
        (code) => code.toString().toLowerCase() === browserLang
    )
    if (exactLang) return exactLang
    const genericLang = languageCodes.find(
        (code) => code.toString().substring(0, 2).toLowerCase() === browserLang.substring(0, 2)
    )
    if (genericLang) return genericLang

    return LANGUAGE_FALLBACK
}
