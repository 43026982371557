import { ROUTE } from '@/constants'
import { IRouteConfig } from '@/models'

export const routesMarketplace: IRouteConfig[] = [
    {
        path: 'marketplace',
        component: () => import('@/components/BaseRouterView/BaseRouterView.vue'),
        children: [
            {
                name: ROUTE.MARKETPLACE_STORES,
                path: 'stores',
                component: () => import('@/components/BaseRouterView/BaseRouterView.vue'),
                redirect: { name: ROUTE.MARKETPLACE_STORES_LIST },
                meta: {
                    guard: (p) => p.marketplace.stores.read,
                    label: (intl) => intl.t().stores.stores.many,
                },
                children: [
                    {
                        name: ROUTE.MARKETPLACE_STORES_LIST,
                        path: '',
                        component: () => import('@marketplace/views/Stores/Stores.vue'),
                        meta: {
                            title: (intl) => intl.t().stores.stores.many,
                        },
                    },
                    {
                        name: ROUTE.MARKETPLACE_STORE,
                        path: ':storeId',
                        component: () =>
                            import('@marketplace/views/StoreDetails/StoreDetails.vue'),
                        redirect: { name: ROUTE.MARKETPLACE_STORE_DETAILS },
                        props: true,
                        meta: {
                            guard: (p) => p.marketplace.stores.read,
                            permission: (p) => p.marketplace.stores,
                            label: (intl) => intl.t().stores.stores.one,
                        },
                        children: [
                            {
                                name: ROUTE.MARKETPLACE_STORE_DETAILS,
                                path: 'details',
                                component: () =>
                                    import('@/components/BaseRouterView/BaseRouterView.vue'),
                                redirect: { name: ROUTE.MARKETPLACE_STORE_DETAILS_DETAILS },
                                props: true,
                                meta: {
                                    guard: (p) => p.marketplace.stores.read,
                                    permission: (p) => p.marketplace.stores,
                                },
                                children: [
                                    {
                                        name: ROUTE.MARKETPLACE_STORE_DETAILS_DETAILS,
                                        path: '',
                                        component: () =>
                                            import(
                                                '@marketplace/views/StoreDetails/views/Details/Details.vue'
                                            ),
                                        props: true,
                                        meta: {
                                            title: (intl) => intl.t().stores.stores.one,
                                            label: (intl) => intl.t().common.details.many,
                                        },
                                    },
                                    {
                                        name: ROUTE.MARKETPLACE_STORE_EDIT_ADDRESS,
                                        path: 'address/edit/:addressId',
                                        component: () =>
                                            import(
                                                '@marketplace/views/StoreEditAddress/StoreEditAddress.vue'
                                            ),
                                        props: true,
                                        meta: {
                                            title: (intl) => intl.t().stores.stores.one,
                                            guard: (p) => p.addresses.update,
                                            label: (intl) =>
                                                intl.t().address.editAddressDetails,
                                        },
                                    },
                                    {
                                        name: ROUTE.MARKETPLACE_STORE_EDIT_DETAILS,
                                        path: 'edit',
                                        component: () =>
                                            import(
                                                '@marketplace/views/StoreEditDetails/StoreEditDetails.vue'
                                            ),
                                        props: true,
                                        meta: {
                                            title: (intl) => intl.t().stores.editStore,
                                            guard: (p) => p.marketplace.stores.update,
                                            label: (intl) => intl.t().stores.editStore,
                                        },
                                    },
                                ],
                            },
                            {
                                name: ROUTE.MARKETPLACE_STORE_DETAILS_CATEGORIES,
                                path: 'categories',
                                component: () =>
                                    import('@/components/BaseRouterView/BaseRouterView.vue'),
                                redirect: {
                                    name: ROUTE.MARKETPLACE_STORE_DETAILS_CATEGORIES_LIST,
                                },
                                props: true,
                                meta: {
                                    title: (intl) => intl.t().stores.stores.one,
                                    guard: (p) => p.marketplace.store.categories.read,
                                    label: (intl) =>
                                        intl.t().products.categories.categories.many,
                                },
                                children: [
                                    {
                                        name: ROUTE.MARKETPLACE_STORE_DETAILS_CATEGORIES_LIST,
                                        path: '',
                                        component: () =>
                                            import(
                                                '@marketplace/views/StoreDetails/views/Categories/Categories.vue'
                                            ),
                                        props: true,
                                        meta: {
                                            title: (intl) => intl.t().stores.stores.one,
                                        },
                                    },
                                    {
                                        name: ROUTE.MARKETPLACE_CATEGORY_CREATE,
                                        path: 'create',
                                        props: true,
                                        component: () =>
                                            import(
                                                '@marketplace/views/StoreCategoryEdit/StoreCategoryEdit.vue'
                                            ),
                                        meta: {
                                            title: (intl) => intl.t().stores.stores.one,
                                            label: (intl) =>
                                                intl.t().products.categories.createCategory,
                                        },
                                    },
                                    {
                                        name: ROUTE.MARKETPLACE_CATEGORY,
                                        path: ':categoryId',
                                        props: true,
                                        redirect: {
                                            name: ROUTE.MARKETPLACE_CATEGORY_DETAILS,
                                        },
                                        component: () =>
                                            import(
                                                '@/components/BaseRouterView/BaseRouterView.vue'
                                            ),
                                        meta: {
                                            title: (intl) => intl.t().stores.stores.one,
                                        },
                                        children: [
                                            {
                                                name: ROUTE.MARKETPLACE_CATEGORY_DETAILS,
                                                path: '',
                                                props: true,
                                                component: () =>
                                                    import(
                                                        '@marketplace/views/StoreCategoryDetails/StoreCategoryDetails.vue'
                                                    ),
                                                meta: {
                                                    title: (intl) => intl.t().stores.stores.one,
                                                    label: (intl) =>
                                                        intl.t().products.categories.categories
                                                            .one,
                                                },
                                            },
                                            {
                                                name: ROUTE.MARKETPLACE_CATEGORY_EDIT,
                                                path: 'edit',
                                                props: true,
                                                component: () =>
                                                    import(
                                                        '@marketplace/views/StoreCategoryEdit/StoreCategoryEdit.vue'
                                                    ),
                                                meta: {
                                                    title: (intl) => intl.t().stores.stores.one,
                                                    label: (intl) =>
                                                        intl.t().products.categories
                                                            .editCategory,
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                name: ROUTE.MARKETPLACE_STORE_DETAILS_PRODUCTS,
                                path: 'products',
                                component: () =>
                                    import(
                                        '@marketplace/views/StoreDetails/views/Products/Products.vue'
                                    ),
                                props: true,
                                redirect: {
                                    name: ROUTE.MARKETPLACE_STORE_DETAILS_PRODUCTS_PRODUCTS,
                                },
                                meta: {
                                    guard: (p) => p.marketplace.stores.one.products.read,
                                    label: (intl) => intl.t().products.products.many,
                                },
                                children: [
                                    {
                                        name: ROUTE.MARKETPLACE_PRODUCT_CREATE,
                                        path: 'products/create',
                                        component: () =>
                                            import(
                                                '@marketplace/views/ProductCreate/ProductCreate.vue'
                                            ),
                                        props: true,
                                        meta: {
                                            title: (intl) => intl.t().stores.stores.one,
                                            guard: (p) => p.marketplace.products.create,
                                            label: (intl) => intl.t().products.createProduct,
                                        },
                                    },
                                    {
                                        name: ROUTE.MARKETPLACE_STORE_DETAILS_PRODUCTS_PRODUCTS,
                                        path: 'products',
                                        component: () =>
                                            import(
                                                '@marketplace/views/StoreDetails/views/Products/views/Products/Products.vue'
                                            ),
                                        props: true,
                                        meta: {
                                            title: (intl) => intl.t().stores.stores.one,
                                        },
                                    },
                                    {
                                        name: ROUTE.MARKETPLACE_STORE_DETAILS_PRODUCTS_RECOMMENDED_PRODUCTS,
                                        path: 'recommended-products',
                                        component: () =>
                                            import(
                                                '@marketplace/views/StoreDetails/views/Products/views/RecommendedProducts/RecommendedProducts.vue'
                                            ),
                                        props: true,
                                        meta: {
                                            title: (intl) => intl.t().stores.stores.one,
                                            label: (intl) =>
                                                intl.t().products.recommendedProducts.many,
                                            permission: () => ({
                                                read: true,
                                                create: true,
                                                delete: true,
                                                update: true,
                                            }),
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        name: ROUTE.MARKETPLACE_STORE_IN_PRODUCTS,
                        path: ':storeId',
                        component: () =>
                            import('@/components/BaseRouterView/BaseRouterView.vue'),
                        redirect: { name: ROUTE.MARKETPLACE_PRODUCTS },
                        props: true,
                        meta: {
                            guard: (p) => p.marketplace.stores.read,
                            permission: (p) => p.marketplace.stores,
                            label: (intl) => intl.t().stores.stores.one,
                        },
                        children: [
                            {
                                name: ROUTE.MARKETPLACE_PRODUCTS,
                                path: 'products',
                                component: () =>
                                    import('@/components/BaseRouterView/BaseRouterView.vue'),
                                redirect: { name: ROUTE.MARKETPLACE_PRODUCTS_LIST },
                                meta: {
                                    title: (intl) => intl.t().products.products.many,
                                    guard: (p) => p.marketplace.products.read,
                                    label: (intl) => intl.t().products.products.many,
                                },
                                children: [
                                    {
                                        name: ROUTE.MARKETPLACE_PRODUCTS_LIST,
                                        path: '',
                                        component: () =>
                                            import('@marketplace/views/Products/Products.vue'),
                                        meta: {
                                            title: (intl) => intl.t().products.products.many,
                                            guard: (p) => p.marketplace.products.read,
                                        },
                                    },
                                    {
                                        name: ROUTE.MARKETPLACE_PRODUCT,
                                        path: ':productId',
                                        component: () =>
                                            import(
                                                '@marketplace/views/ProductDetails/ProductDetails.vue'
                                            ),
                                        redirect: { name: ROUTE.MARKETPLACE_PRODUCT_DETAILS },
                                        props: true,
                                        meta: {
                                            guard: (p) => p.marketplace.products.read,
                                            label: (intl) => intl.t().products.products.one,
                                        },
                                        children: [
                                            {
                                                name: ROUTE.MARKETPLACE_PRODUCT_DETAILS,
                                                path: 'details',
                                                component: () =>
                                                    import(
                                                        '@/components/BaseRouterView/BaseRouterView.vue'
                                                    ),
                                                redirect: {
                                                    name: ROUTE.MARKETPLACE_PRODUCT_DETAILS_DETAILS,
                                                },
                                                props: true,
                                                meta: {
                                                    guard: (p) => p.marketplace.products.read,
                                                },
                                                children: [
                                                    {
                                                        name: ROUTE.MARKETPLACE_PRODUCT_DETAILS_DETAILS,
                                                        path: '',
                                                        component: () =>
                                                            import(
                                                                '@marketplace/views/ProductDetails/views/Details/Details.vue'
                                                            ),
                                                        props: true,
                                                        meta: {
                                                            title: (intl) =>
                                                                intl.t().products.products.one,
                                                        },
                                                    },
                                                    {
                                                        name: ROUTE.MARKETPLACE_PRODUCT_DETAILS_EDIT,
                                                        path: 'edit',
                                                        component: () =>
                                                            import(
                                                                '@marketplace/views/ProductDetailsEdit/ProductDetailsEdit.vue'
                                                            ),
                                                        props: true,
                                                        meta: {
                                                            title: (intl) =>
                                                                intl.t().products.products.one,
                                                            guard: (p) =>
                                                                p.marketplace.products.update,
                                                            label: (intl) =>
                                                                intl.t().products.editProduct,
                                                        },
                                                    },
                                                ],
                                            },
                                            {
                                                name: ROUTE.MARKETPLACE_PRODUCT_DETAILS_IMAGES,
                                                path: 'images',
                                                component: () =>
                                                    import(
                                                        '@marketplace/views/ProductDetails/views/Images/Images.vue'
                                                    ),
                                                meta: {
                                                    title: (intl) =>
                                                        intl.t().products.products.one,
                                                    guard: (p) =>
                                                        p.marketplace.products.images.read,
                                                    permission: () => ({
                                                        read: true,
                                                        create: true,
                                                        delete: true,
                                                        update: true,
                                                    }),
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
]
