import { ROUTE } from '@/constants'
import { IRouteConfig } from '@/models'

export const routesDevices: IRouteConfig[] = [
    {
        path: 'devices',
        component: () => import('@/components/BaseRouterView/BaseRouterView.vue'),
        meta: {
            guard: (_p, a) => a,
        },
        children: [
            {
                name: ROUTE.DEVICES,
                path: 'devices',
                component: () => import('@/components/BaseRouterView/BaseRouterView.vue'),
                redirect: {
                    name: ROUTE.DEVICES_LIST,
                },
                meta: {
                    title: (intl) => intl.t().devices.devices.many,
                    guard: (p) => p.devices.read,
                    permission: (p) => p.devices,
                    label: (intl) => intl.t().devices.devices.many,
                },
                children: [
                    {
                        name: ROUTE.DEVICES_LIST,
                        path: '',
                        component: () => import('@devices/views/Devices/Devices.vue'),
                        meta: {
                            title: (intl) => intl.t().devices.devices.many,
                            guard: (p) => p.devices.read,
                            permission: (p) => p.devices,
                        },
                    },
                    {
                        name: ROUTE.DEVICE,
                        path: ':deviceId',
                        component: () =>
                            import('@/components/BaseRouterView/BaseRouterView.vue'),
                        redirect: {
                            name: ROUTE.DEVICE_DETAILS,
                        },
                        props: true,
                        meta: {
                            title: (intl) => intl.t().devices.devices.one,
                            label: (intl) => intl.t().devices.devices.one,
                        },
                        children: [
                            {
                                name: ROUTE.DEVICE_DETAILS,
                                path: 'details/:isInRoom',
                                component: () =>
                                    import('@devices/views/DeviceDetails/DeviceDetails.vue'),
                                props: true,
                                meta: {
                                    title: (intl) => intl.t().devices.devices.one,
                                },
                            },
                            {
                                name: ROUTE.DEVICE_DETAILS_EDIT,
                                path: 'details/edit/:isInRoom',
                                component: () =>
                                    import('@devices/views/DeviceEdit/DeviceEdit.vue'),
                                props: true,
                                meta: {
                                    title: (intl) => intl.t().devices.devices.one,
                                    guard: (p) => p.devices.update,
                                    label: (intl) => intl.t().devices.edit,
                                },
                            },
                        ],
                    },
                ],
            },
            {
                name: ROUTE.PERIPHERAL_TYPES,
                path: 'peripheral-types',
                component: () => import('@/components/BaseRouterView/BaseRouterView.vue'),
                redirect: {
                    name: ROUTE.PERIPHERAL_TYPES_LIST,
                },
                meta: {
                    title: (intl) =>
                        intl.t().configurations.peripheralTypes.peripheralTypes.many,
                    guard: (p) => p.devices.peripherals.types.read,
                    permission: (p) => p.devices.peripherals.types,
                    label: (intl) =>
                        intl.t().configurations.peripheralTypes.peripheralTypes.many,
                },
                children: [
                    {
                        name: ROUTE.PERIPHERAL_TYPES_LIST,
                        path: '',
                        component: () =>
                            import('@devices/views/PeripheralTypes/PeripheralTypes.vue'),
                        meta: {
                            title: (intl) =>
                                intl.t().configurations.peripheralTypes.peripheralTypes.many,
                            guard: (p) => p.devices.peripherals.types.read,
                            permission: (p) => p.devices.peripherals.types,
                        },
                    },
                    {
                        name: ROUTE.PERIPHERAL_TYPE_CREATE,
                        path: 'create',
                        component: () =>
                            import('@devices/views/PeripheralTypeEdit/PeripheralTypeEdit.vue'),
                        props: true,
                        meta: {
                            title: (intl) =>
                                intl.t().configurations.peripheralTypes.peripheralTypes.one,
                            guard: (p) => p.devices.peripherals.types.create,
                            label: (intl) =>
                                intl.t().configurations.peripheralTypes.createPeripheralType,
                        },
                    },
                    {
                        name: ROUTE.PERIPHERAL_TYPE,
                        path: ':peripheralTypeId',
                        component: () =>
                            import('@/components/BaseRouterView/BaseRouterView.vue'),
                        redirect: {
                            name: ROUTE.PERIPHERAL_TYPE_DETAILS,
                        },
                        props: true,
                        meta: {
                            title: (intl) =>
                                intl.t().configurations.peripheralTypes.peripheralTypes.one,
                            label: (intl) =>
                                intl.t().configurations.peripheralTypes.peripheralTypes.one,
                        },
                        children: [
                            {
                                name: ROUTE.PERIPHERAL_TYPE_DETAILS,
                                path: 'details',
                                component: () =>
                                    import(
                                        '@devices/views/PeripheralTypeDetails/PeripheralTypeDetails.vue'
                                    ),
                                props: true,
                                meta: {
                                    title: (intl) =>
                                        intl.t().configurations.peripheralTypes.peripheralTypes
                                            .one,
                                },
                            },
                            {
                                name: ROUTE.PERIPHERAL_TYPE_EDIT,
                                path: 'edit',
                                component: () =>
                                    import(
                                        '@devices/views/PeripheralTypeEdit/PeripheralTypeEdit.vue'
                                    ),
                                props: true,
                                meta: {
                                    title: (intl) =>
                                        intl.t().configurations.peripheralTypes.peripheralTypes
                                            .one,
                                    guard: (p) => p.devices.peripherals.types.update,
                                    label: (intl) =>
                                        intl.t().configurations.peripheralTypes
                                            .editPeripheralType,
                                },
                            },
                        ],
                    },
                ],
            },
            {
                name: ROUTE.DEVICE_TV_SERIES,
                path: 'tv-series',
                component: () => import('@/components/BaseRouterView/BaseRouterView.vue'),
                redirect: {
                    name: ROUTE.DEVICE_TV_SERIES_LIST,
                },
                meta: {
                    title: (intl) => intl.t().tvSeries.tvSeries.many,
                    label: (intl) => intl.t().tvSeries.tvSeries.many,
                },
                children: [
                    {
                        name: ROUTE.DEVICE_TV_SERIES_LIST,
                        path: '',
                        component: () => import('@devices/views/TvSeries/TvSeries.vue'),
                        meta: {
                            title: (intl) => intl.t().tvSeries.tvSeries.many,
                        },
                    },
                    {
                        name: ROUTE.DEVICE_TV_SERIES_CREATE,
                        path: 'create',
                        component: () => import('@devices/views/TvSeriesEdit/TvSeriesEdit.vue'),
                        meta: {
                            title: (intl) => intl.t().tvSeries.tvSeries.one,
                            label: (intl) => intl.t().tvSeries.create,
                        },
                    },
                    {
                        name: ROUTE.DEVICE_TV_SERIES_ITEM,
                        path: ':tvSeriesId',
                        component: () =>
                            import('@/components/BaseRouterView/BaseRouterView.vue'),
                        props: true,
                        meta: {
                            title: (intl) => intl.t().tvSeries.tvSeries.one,
                            label: (intl) => intl.t().tvSeries.tvSeries.one,
                        },
                        children: [
                            {
                                name: ROUTE.DEVICE_TV_SERIES_DETAILS,
                                path: 'details',
                                component: () =>
                                    import(
                                        '@devices/views/TvSeriesDetails/TvSeriesDetails.vue'
                                    ),
                                props: true,
                                meta: {
                                    title: (intl) => intl.t().tvSeries.tvSeries.one,
                                },
                            },
                            {
                                name: ROUTE.DEVICE_TV_SERIES_EDIT,
                                path: 'edit',
                                component: () =>
                                    import('@devices/views/TvSeriesEdit/TvSeriesEdit.vue'),
                                props: true,
                                meta: {
                                    title: (intl) => intl.t().tvSeries.tvSeries.one,
                                    label: (intl) => intl.t().tvSeries.edit,
                                },
                            },
                        ],
                    },
                ],
            },
        ],
    },
]
