import { IServiceErrorResponse, ServiceError } from '@/models/ServiceError'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import { signOut } from './utils'

export function useResponseErrorInterceptor(instance: AxiosInstance): number {
    return instance.interceptors.response.use(
        (response: AxiosResponse): AxiosResponse => response,
        (error: AxiosError): Promise<IServiceErrorResponse> => {
            const serviceError = new ServiceError(error)

            if (serviceError.response.status === 401) signOut()

            // @see: https://github.com/axios/axios/issues/960
            return Promise.reject(serviceError.response)
        }
    )
}
