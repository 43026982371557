export enum ROUTE {
    START = 'Start',
    SIGN_IN = 'SignIn',
    PASSWORD_CHANGE = 'PasswordChange',

    MY_ACCOUNT = 'MyAccount',
    USERS = 'Users',

    MARKETPLACE = 'Marketplace',
    MARKETPLACE_STORES = 'MarketplaceStores',
    MARKETPLACE_STORES_LIST = 'MarketplaceStoresList',
    MARKETPLACE_STORE = 'MarketplaceStore',
    MARKETPLACE_STORE_IN_PRODUCTS = 'MarketplaceStoreInProducts',
    MARKETPLACE_STORE_DETAILS = 'MarketplaceStoreDetails',
    MARKETPLACE_STORE_DETAILS_DETAILS = 'MarketplaceStoreDetailsDetails',
    MARKETPLACE_STORE_DETAILS_PRODUCTS = 'MarketplaceStoreDetailsProducts',
    MARKETPLACE_STORE_DETAILS_PRODUCTS_PRODUCTS = 'MarketplaceStoreDetailsProductsProducts',
    MARKETPLACE_STORE_DETAILS_PRODUCTS_RECOMMENDED_PRODUCTS = 'MarketplaceStoreDetailsProductsRecommendedProducts',
    MARKETPLACE_STORE_DETAILS_CATEGORIES = 'MarketplaceStoreDetailsCategories',
    MARKETPLACE_STORE_DETAILS_CATEGORIES_LIST = 'MarketplaceStoreDetailsCategoriesList',
    MARKETPLACE_STORE_DETAILS_DELIVERY_HOURS = 'MarketplaceStoreDetailsDeliveryHours',
    MARKETPLACE_STORE_DETAILS_DELIVERY_HOURS_LIST = 'MarketplaceStoreDetailsDeliveryHoursList',
    MARKETPLACE_STORE_EDIT_ADDRESS = 'MarketplaceStoreEditAddress',
    MARKETPLACE_STORE_EDIT_DETAILS = 'MarketplaceStoreEditDetails',
    MARKETPLACE_STORE_CREATE = 'MarketplaceStoreCreate',
    MARKETPLACE_STORE_DELIVERY_HOURS_EDIT = 'MarketplaceStoreDeliveryHoursEdit',
    MARKETPLACE_STORE_DELIVERY_HOURS_CREATE = 'MarketplaceStoreDeliveryHoursCreate',

    MARKETPLACE_CATEGORY = 'MarketplaceCategory',
    MARKETPLACE_CATEGORY_DETAILS = 'MarketplaceCategoryDetails',
    MARKETPLACE_CATEGORY_CREATE = 'MarketplaceCategoryCreate',
    MARKETPLACE_CATEGORY_EDIT = 'MarketplaceCategoryEdit',

    MARKETPLACE_PRODUCTS = 'MarketplaceProducts',
    MARKETPLACE_PRODUCTS_LIST = 'MarketplaceProductsList',
    MARKETPLACE_PRODUCT_CREATE = 'MarketplaceProductCreate',
    MARKETPLACE_PRODUCT = 'MarketplaceProduct',
    MARKETPLACE_PRODUCT_DETAILS = 'MarketplaceProductDetails',
    MARKETPLACE_PRODUCT_DETAILS_EDIT = 'MarketplaceProductDetailsEdit',
    MARKETPLACE_PRODUCT_DETAILS_DETAILS = 'MarketplaceProductDetailsDetails',
    MARKETPLACE_PRODUCT_DETAILS_RELATED = 'MarketplaceProductDetailsRelated',
    MARKETPLACE_PRODUCT_DETAILS_IMAGES = 'MarketplaceProductDetailsImages',
    MARKETPLACE_PRODUCT_DETAILS_RELATED_STORIES = 'MarketplaceProductDetailsRelatedStories',
    MARKETPLACE_PRODUCT_DETAILS_RELATED_INFORMATION = 'MarketplaceProductDetailsRelatedInformation',
    MARKETPLACE_PRODUCT_DETAILS_RELATED_PLACES = 'MarketplaceProductDetailsRelatedPlaces',
    MARKETPLACE_PRODUCT_DETAILS_RELATED_EVENTS = 'MarketplaceProductDetailsRelatedEvents',
    MARKETPLACE_PRODUCT_DETAILS_RELATED_ACTIVITIES = 'MarketplaceProductDetailsRelatedActivities',

    USERS_TECHNICIANS = 'UsersTechnicians',
    USERS_TECHNICIAN_DETAILS = 'UsersTechnicianDetails',
    USERS_TECHNICIAN_DETAILS_EDIT = 'UsersTechnicianDetailsEdit',
    USERS_TECHNICIAN_CREATE = 'UsersTechnicianCreate',

    USERS_ADMINISTRATORS = 'UsersAdministrators',
    USERS_ADMINISTRATORS_LIST = 'UsersAdministratorsList',
    USERS_ADMINISTRATOR = 'UsersAdministrator',
    USERS_ADMINISTRATOR_DETAILS = 'UsersAdministratorDetails',
    USERS_ADMINISTRATOR_DETAILS_EDIT = 'UsersAdministratorDetailsEdit',
    USERS_ADMINISTRATOR_ROLE_EDIT = 'UsersAdministratorRoleEdit',
    USERS_ADMINISTRATOR_CREATE = 'UsersAdministratorCreate',

    SYSTEM_MESSAGES = 'SystemMessages',
    SYSTEM_MESSAGES_LIST = 'SystemMessagesList',
    SYSTEM_MESSAGE = 'SystemMessage',
    SYSTEM_MESSAGE_DETAILS = 'SystemMessageDetails',
    SYSTEM_MESSAGE_DETAILS_EDIT = 'SystemMessageDetailsEdit',
    SYSTEM_MESSAGE_CREATE = 'SystemMessageCreate',

    CONTENT = 'Content',

    DEVICES = 'Devices',
    DEVICES_LIST = 'DevicesList',
    DEVICE = 'Device',
    DEVICE_DETAILS = 'DeviceDetails',
    DEVICE_DETAILS_EDIT = 'DeviceDetailsEdit',

    DEVICE_TV_SERIES = 'DeviceTvSeries',
    DEVICE_TV_SERIES_LIST = 'DeviceTvSeriesList',
    DEVICE_TV_SERIES_ITEM = 'DeviceTvSeriesItem',
    DEVICE_TV_SERIES_DETAILS = 'DeviceTvSeriesDetails',
    DEVICE_TV_SERIES_EDIT = 'DeviceTvSeriesEdit',
    DEVICE_TV_SERIES_CREATE = 'DeviceTvSeriesCreate',

    PERIPHERAL_TYPES = 'PeripheralTypes',
    PERIPHERAL_TYPES_LIST = 'PeripheralTypesList',
    PERIPHERAL_TYPE = 'PeripheralType',
    PERIPHERAL_TYPE_DETAILS = 'PeripheralTypeDetails',
    PERIPHERAL_TYPE_EDIT = 'PeripheralTypeEdit',
    PERIPHERAL_TYPE_CREATE = 'PeripheralTypeCreate',

    OWNERS = 'Owners',
    OWNERS_LIST = 'OwnersList',
    OWNER_DETAILS = 'OwnerDetails',
    OWNER = 'Owner',
    OWNER_DETAILS_DETAILS = 'OwnerDetailsDetails',
    OWNER_DETAILS_MENU_ITEMS = 'OwnerDetailsMenuItems',
    OWNER_DETAILS_MENU_ITEMS_LIST = 'OwnerDetailsMenuItemsList',
    OWNER_CREATE = 'OwnerCreate',

    MENU_ITEM = 'MenuItem',
    MENU_ITEM_DETAILS = 'MenuItemDetails',
    MENU_ITEM_CREATE = 'MenuItemCreate',
    MENU_ITEM_EDIT = 'MenuItemEdit',

    CONTENT_LIBRARIES = 'ContentLibraries',
    CONTENT_LIBRARIES_LIST = 'ContentLibrariesList',
    CONTENT_LIBRARY = 'ContentLibrary',
    CONTENT_LIBRARY_DETAILS = 'ContentLibraryDetails',
    CONTENT_LIBRARY_CREATE = 'ContentLibraryCreate',
    CONTENT_LIBRARY_EDIT = 'ContentLibraryEdit',
    CONTENT_LIBRARY_DETAILS_DETAILS = 'ContentLibraryDetailsDetails',
    CONTENT_LIBRARY_DETAILS_RELATED_HOTELS = 'ContentLibraryDetailsRelatedHotels',
    CONTENT_LIBRARY_DETAILS_SMART_CONTENT = 'ContentLibraryDetailsSmartContent',
    CONTENT_LIBRARY_DETAILS_SMART_CARDS = 'ContentLibraryDetailsSmartCards',

    CONTENT_SMART_CARDS = 'ContentSmartCards',
    CONTENT_SMART_CARDS_LIST = 'ContentSmartCardsList',
    CONTENT_SMART_CARD = 'ContentSmartCard',
    CONTENT_SMART_CARD_CREATE = 'ContentSmartCardCreate',
    CONTENT_SMART_CARD_CREATE_FROM_LIBRARY = 'ContentSmartCardCreateFromLibrary',
    CONTENT_SMART_CARD_CREATE_FROM_SMART_CARD = 'ContentSmartCardCreateFromSmartCard',
    CONTENT_SMART_CARD_CREATE_FROM_HELP = 'ContentSmartCardCreateFromHelp',
    CONTENT_SMART_CARD_DETAILS = 'ContentSmartCardDetails',
    CONTENT_SMART_CARD_DETAILS_DETAILS = 'ContentSmartCardDetailsDetails',
    CONTENT_SMART_CARD_DETAILS_IMAGES = 'ContentSmartCardDetailsImages',
    CONTENT_SMART_CARD_DETAILS_RELATED_CARDS = 'ContentSmartCardDetailsRelatedCards',
    CONTENT_SMART_CARD_DETAILS_RELATED_CARDS_LIST = 'ContentSmartCardDetailsRelatedCardsList',
    CONTENT_SMART_CARD_EDIT_DETAILS = 'ContentSmartCardEditDetails',
    CONTENT_SMART_CARD_EDIT_ADDRESS = 'ContentSmartCardEditAddress',
    CONTENT_SMART_CARD_DETAILS_RELATED_PRODUCTS = 'ContentSmartCardDetailsRelatedProducts',
    CONTENT_SMART_CARD_DETAILS_RELATED_PRODUCTS_PRODUCTS = 'ContentSmartCardDetailsRelatedProductsProducts',
    CONTENT_SMART_CARD_DETAILS_RELATED_PRODUCTS_SERVICES = 'ContentSmartCardDetailsRelatedProductsServices',
    CONTENT_SMART_CARD_DETAILS_VISIBILITY_IN_MENU_ITEMS = 'ContentSmartCardDetailsVisibilityInSystem',
    CONTENT_SMART_CARD_DETAILS_VISIBILITY_HELP = 'ContentSmartCardDetailsVisibilityHelp',
    CONTENT_SMART_CARD_DETAILS_VISIBILITY_IN_SYSTEM_HOTELS = 'ContentSmartCardDetailsVisibilityInSystemHotels',
    CONTENT_SMART_CARD_DETAILS_VISIBILITY_IN_SYSTEM_ROOM_GROUPS = 'ContentSmartCardDetailsVisibilityInSystemRoomGroups',

    REGION = 'Region',
    REGIONS_REGIONS = 'RegionsRegions',
    REGIONS_LIST = 'RegionsList',
    REGION_DETAILS = 'RegionDetails',
    REGION_EDIT = 'RegionEdit',
    REGION_CREATE = 'RegionsCreate',

    REGIONS_COUNTRIES = 'RegionsCountries',
    COUNTRIES_LIST = 'CountriesList',
    COUNTRY = 'Country',
    COUNTRY_DETAILS = 'CountryDetails',
    COUNTRY_DETAILS_DETAILS = 'CountryDetailsDetails',
    COUNTRY_DETAILS_CONTACTS_EMERGENCY = 'CountryDetailsContactsEmergency',
    COUNTRY_EDIT = 'CountryEdit',
    COUNTRY_CREATE = 'CountryCreate',

    HOTELS = 'Hotels',
    HOTELS_LIST = 'HotelsList',
    HOTEL = 'Hotel',
    HOTEL_CHILDREN_ROOM = 'HotelChildrenRoom',
    HOTEL_CHILD_ROOMS = 'HotelChildRooms',
    HOTEL_DETAILS = 'HotelDetails',
    HOTEL_DETAILS_DETAILS = 'HotelDetailsDetails',
    HOTEL_CREATE = 'HotelCreate',
    HOTEL_EDIT_DETAILS = 'HotelDetailsEdit',
    HOTEL_EDIT_ADDRESS = 'HotelEditAddress',
    HOTEL_AMENITIES = 'HotelAmenities',
    HOTEL_CONTACTS = 'HotelContacts',
    HOTEL_CONTACTS_GENERAL = 'HotelContactsGeneral',
    HOTEL_CONTACTS_REQUEST_CALLBACK = 'HotelContactsRequestCallback',
    HOTEL_AZ_INDEXES = 'HotelAzIndexes',
    HOTEL_AZ_INDEX = 'HotelAzIndex',
    HOTEL_CONFIGURATIONS = 'HotelConfigurations',
    HOTEL_CONFIGURATION_WIFI = 'HotelConfigurationWifi',
    HOTEL_CONFIGURATION_INFORMATION_CARDS = 'HotelConfigurationInformationCards',

    HOTEL_CONFIGURATION_HELP = 'HotelConfigurationHelp',
    HOTEL_CONFIGURATION_HELP_DETAILS = 'HotelConfigurationHelpDetails',
    HOTEL_CONFIGURATION_INFORMATION_CARDS_STORES = 'HotelConfigurationInformationCardsStores',
    HOTEL_CONFIGURATION_INFORMATION_CARDS_STORES_LIST = 'HotelConfigurationInformationCardsStoresList',
    HOTEL_CONFIGURATION_INFORMATION_CARDS_ROOM_SERVICE = 'HotelConfigurationInformationCardsRoomService',
    HOTEL_CONFIGURATION_WIFI_LIST = 'HotelConfigurationWifiList',
    HOTEL_CONFIGURATION_WIFI_ITEM = 'HotelConfigurationWifiItem',
    HOTEL_CONFIGURATION_WIFI_EDIT = 'HotelConfigurationWifiEdit',
    HOTEL_CONFIGURATION_WIFI_CREATE = 'HotelConfigurationWifiCreate',
    HOTEL_CONFIGURATION_WIFI_DETAILS = 'HotelConfigurationWifiDetails',
    HOTEL_CONFIGURATION_INFORMATION_CARDS_STORES_CREATE = 'HotelConfigurationInformationCardsStoresCreate',
    HOTEL_CONFIGURATION_INFORMATION_CARDS_STORES_EDIT = 'HotelConfigurationInformationCardsStoresEdit',
    HOTEL_CONFIGURATION_INFORMATION_CARDS_STORES_DETAILS = 'HotelConfigurationInformationCardsStoresDetails',
    HOTEL_CONFIGURATION_PERIPHERAL_TEMPLATES = 'HotelConfigurationPeripheralTemplates',
    HOTEL_CONFIGURATION_PERIPHERAL_TEMPLATES_LIST = 'HotelConfigurationPeripheralTemplatesList',
    HOTEL_CONFIGURATION_PERIPHERAL_TEMPLATES_EDIT = 'HotelConfigurationPeripheralTemplatesEdit',
    HOTEL_CONFIGURATION_PERIPHERAL_TEMPLATES_CREATE = 'HotelConfigurationPeripheralTemplatesCreate',
    HOTEL_CONFIGURATION_PERIPHERAL_TEMPLATES_DETAILS = 'HotelConfigurationPeripheralTemplatesDetails',
    HOTEL_CONFIGURATION_MENU_ITEMS = 'HotelConfigurationMenuItems',
    HOTEL_CONFIGURATION_MENU_ITEM_LIST = 'HotelConfigurationMenuItemList',
    HOTEL_CONFIGURATION_MENU_ITEM_EDIT = 'HotelConfigurationMenuItemEdit',
    HOTEL_CONFIGURATION_MENU_ITEM_CREATE = 'HotelConfigurationMenuItemCreate',
    HOTEL_CONFIGURATION_MENU_ITEM_DETAILS = 'HotelConfigurationMenuItemDetails',
    HOTEL_ACCOMMODATIONS = 'HotelAccommodations',
    HOTEL_ACCOMMODATIONS_LIST = 'HotelAccommodationsList',
    HOTEL_ACCOMMODATION = 'HotelAccommodation',
    HOTEL_ACCOMMODATION_CREATE = 'HotelAccommodationCreate',
    HOTEL_MAINTENANCES = 'HotelMaintenances',
    HOTEL_MAINTENANCES_TASKS = 'HotelMaintenancesTasks',
    HOTEL_MAINTENANCES_CHECKLISTS = 'HotelMaintenancesChecklists',

    HOTEL_CONTENT = 'HotelContent',

    HOTEL_SMART_CARDS_MENU = 'HotelSmartCardsMenu',
    HOTEL_SMART_CARDS_MENU_ITEM = 'HotelSmartCardsMenuItem',

    HOTEL_STORES = 'HotelStores',
    HOTEL_STORES_LIST = 'HotelStoresList',
    HOTEL_STORE_CREATE = 'HotelStoreCreate',

    HOTEL_ROOMS = 'HotelRoom',
    HOTEL_ROOMS_ROOMS = 'HotelRoomsRooms',
    HOTEL_ROOMS_ROOMS_LIST = 'HotelRoomsRoomsList',
    HOTEL_ROOMS_ROOM_GROUPS = 'HotelRoomsRoomGroups',
    HOTEL_ROOMS_ROOM_GROUPS_LIST = 'HotelRoomsRoomGroupsList',
    ROOM_DETAILS = 'RoomDetails',
    ROOM_DETAILS_DETAILS = 'RoomDetailsDetails',
    ROOM_AMENITIES = 'RoomAmenities',
    ROOM_TYPES = 'RoomTypes',
    ROOM_EDIT = 'RoomEdit',
    ROOM_CREATE = 'RoomCreate',
    ROOM_GROUP_CREATE = 'RoomGroupCreate',
    ROOM_GROUP_DETAILS = 'RoomGroupDetails',
    ROOM_CONFIGURATION_WIFI = 'RoomConfigurationWifi',
    ROOM_CONFIGURATION_WIFI_EDIT = 'RoomConfigurationWifiEdit',
    ROOM_CONFIGURATION_WIFI_CREATE = 'RoomConfigurationWifiCreate',

    GUEST_DETAILS = 'GuestDetails',
    GUEST_EDIT = 'GuestEdit',

    ACCOMMODATION_DETAILS = 'AccommodationDetails',
    ACCOMMODATION_DETAILS_DETAILS = 'AccommodationDetailsDetails',
    ACCOMMODATION_DETAILS_NOTIFICATIONS = 'AccommodationDetailsNotifications',

    ROOM_ACCOMMODATION_CREATE = 'AccommodationCreate',
    ACCOMMODATION_DETAILS_EDIT = 'AccommodationDetailsEdit',
    ACCOMMODATION_GUEST_CREATE = 'ACCOMMODATION_GUEST_CREATE',
    ROOM_ACCOMMODATIONS = 'RoomAccommodations',
    ROOM_MAINTENANCES = 'RoomMaintenances',

    MAINTENANCES_CHECKLISTS_LIST = 'MaintenancesChecklistsList',
    MAINTENANCE_CHECKLIST = 'MaintenanceChecklist',
    MAINTENANCE_CHECKLIST_DETAILS = 'MaintenanceChecklistDetails',
    MAINTENANCE_CHECKLIST_DETAILS_CREATE = 'MaintenanceChecklistDetailsCreate',
    MAINTENANCE_CHECKLIST_DETAILS_EDIT = 'MaintenanceChecklistDetailsEdit',

    HOTEL_GROUPS = 'HotelsGroups',
    HOTEL_GROUPS_LIST = 'HotelsGroupsList',
    HOTEL_GROUP = 'HotelGroup',
    HOTEL_GROUP_CREATE = 'HotelsGroupsCreate',
    HOTEL_GROUP_EDIT = 'HotelsGroupsEdit',
    HOTEL_GROUP_DETAILS = 'HotelGroupDetails',

    HOTEL_AZ_INDEX_DETAILS = 'HotelAzIndexDetails',
    HOTEL_AZ_INDEX_CREATE = 'HotelAzIndexCreate',
    HOTEL_AZ_INDEX_EDIT = 'HotelAzIndexEdit',
}
