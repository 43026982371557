export enum STORE_MODULE {
    AUTH = 'Auth',
    TOASTS = 'Toast',

    COUNTRY_LIST = 'CountryList',
    COUNTRY_CONTACTS_EMERGENCY_LIST = 'CountryContactsEmergencyList',
    REGION_LIST = 'RegionList',
    HOTEL_LIST = 'HotelList',
    HOTEL_GROUP_LIST = 'HotelGroupList',
    HOTEL_CONTACT_LIST = 'HotelContactList',
    HOTEL_CONFIG_WIFI_LIST = 'HotelConfigWifiList',
    HOTEL_CONTACT_REQUEST_CALLBACK_LIST = 'HotelContactRequestCallbackList',
    HOTEL_MAINTENANCE_CHECKLIST_LIST = 'HotelMaintenanceChecklistList',
    HOTEL_MAINTENANCE_TASK_LIST = 'HotelTaskList',
    HOTEL_MAINTENANCE_CHECKLIST_TASK_LIST = 'checklistTaskList',
    HOTEL_ACCOMMODATION_LIST = 'HotelAccommodationList',
    CONTENT_GROUP_HOTEL_LIST = 'ContentGroupHotelList',
    ROOM_LIST = 'RoomList',
    ROOM_GROUP_LIST = 'RoomGroupList',
    ACCOMMODATION_GUEST_LIST = 'AccommodationGuestList',
    ROOM_ACCOMMODATION_LIST = 'RoomAccommodationList',
    ROOM_MAINTENANCE_LIST = 'RoomMaintenanceList',
    GUEST_CONTACT_LIST = 'GuestContactList',
    STORE_LIST = 'StoreList',
    STORE_CATEGORY_LIST = 'StoreCategoryList',
    HOTEL_RESOURCE_LIST = 'HotelResourceList',
    DEVICE_LIST = 'DeviceList',
    NOTIFICATION_LIST = 'NotificationList',
    AZ_INDEX_LIST = 'AzIndexList',

    OWNER_LIST = 'OwnerList',

    CONTENT_LIBRARY_AFFILIATION = 'ContentLibraryAffiliation',

    CONTENT_FEED_LIST = 'ContentFeedList',
    CONTENT_FEED_RELATED_STORY_LIST = 'ContentFeedRelatedStoryList',

    MENU_ITEM_FEED_RELATED_SMART_CARDS = 'MenuItemFeedRelatedSmartCards',
    SMART_CARD_RELATED_MENU_ITEM_FEEDS = 'SmartCardRelatedMenuItemFeeds',

    CONTENT_GROUP_RELATED_SMART_CARD_LIST = 'ContentGroupRelatedSmartCardList',
    CONTENT_GROUP_RELATED_HOTEL_LIST = 'ContentGroupRelatedHotelList',
    CONTENT_GROUP_RELATED_ROOM_LIST = 'ContentGroupRelatedRoomList',
    CONTENT_GROUP_RELATED_PRODUCT_LIST = 'ContentGroupRelatedProductList',
    SMART_RELATED_PRODUCT_LIST = 'SmartRelatedProductList',
    CONTENT_ROOM_LIST = 'ContentRoomList',
    CONTENT_PRODUCT_LIST = 'ContentProductList',
    RECOMMENDED_PRODUCT_LIST = 'RecommendedProductList',

    ADMINISTRATOR_LIST = 'AdministratorList',
    TECHNICIAN_LIST = 'TechnicianList',

    IMAGE_VIEWER = 'ImageViewer',
}
