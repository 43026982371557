import {
    Backend,
    IContentCounters,
    IContentLimits,
    IMarketplaceLimits,
    IMediaLimits,
    IHotelCounters,
    IStoreCounters,
} from '@/models'
import {
    IRawContentCounters,
    IRawContentLimits,
    IRawMediaLimits,
    IRawMarketplaceLimits,
    IRawHotelCounters,
    IRawStoreCounters,
} from './models'

export const ServiceLimits = new (class ServiceLimits extends Backend {
    async fetchMediaLimits(): Promise<IMediaLimits> {
        const { data } = await this.get<IRawMediaLimits>(
            `mediacontents/smart-content-groups/media-limits/`,
            this.createRequestConfig({ cacheStrategy: 'static' })
        )

        return data
    }

    async fetchContentLimits(): Promise<IContentLimits> {
        const { data } = await this.get<IRawContentLimits>(
            `mediacontents/content-libraries/content-limits/`,
            this.createRequestConfig({ cacheStrategy: 'static' })
        )

        return {
            smartContentGroups: data.smart_content_groups,
        }
    }

    async fetchContentCounters(contentLibraryId: string): Promise<IContentCounters> {
        const { data } = await this.get<IRawContentCounters>(
            `mediacontents/content-libraries/${contentLibraryId}/content-counters/`
        )

        return {
            smartContentGroups: data.smart_content_groups,
        }
    }

    async fetchMarketplaceLimits(): Promise<IMarketplaceLimits> {
        const { data } = await this.get<IRawMarketplaceLimits>(
            `marketplace/limits/`,
            this.createRequestConfig({ cacheStrategy: 'static' })
        )
        return {
            hotelStores: data.hotel_stores,
            products: data.products,
            services: data.services,
            productImages: data.product_images,
            serviceImages: data.service_images,
        }
    }

    async fetchHotelCounters(hotelId: string): Promise<IHotelCounters> {
        const { data } = await this.get<IRawHotelCounters>(`hotels/${hotelId}/counters/`)
        return data
    }

    async fetchStoreCounters(hotelId: string): Promise<IStoreCounters> {
        const { data } = await this.get<IRawStoreCounters>(
            `marketplace/stores/${hotelId}/counters/`
        )
        return data
    }
})()
