import Vue from 'vue'
import App from './App.vue'
import { router } from './router'
import { store } from './store'
import { vuetify, intl, pinia, toasts, sentry } from './plugins'

Vue.config.productionTip = false
Vue.use(toasts)
Vue.use(intl)
Vue.use(sentry, router)

new Vue({
    router,
    store,
    vuetify,
    pinia,
    render: (h) => h(App),
}).$mount('#app')
