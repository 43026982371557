import { ROUTE } from '@/constants'
import { IRouteConfig } from '@/models'

export const routesRoomDetails: IRouteConfig[] = [
    {
        name: ROUTE.ROOM_DETAILS,
        path: 'rooms/:roomId',
        component: () => import('@hotels/views/RoomDetails/RoomDetails.vue'),
        redirect: { name: ROUTE.ROOM_DETAILS_DETAILS },
        props: true,
        meta: {
            guard: (p) => p.rooms.read,
            permission: (p) => p.rooms,
            label: (intl) => intl.t().rooms.rooms.one,
        },
        children: [
            {
                name: ROUTE.ROOM_DETAILS_DETAILS,
                path: 'details',
                component: () => import('@hotels/views/RoomDetails/views/Details/Details.vue'),
                props: true,
                meta: {
                    title: (intl) => intl.t().rooms.rooms.one,
                },
            },
            {
                name: ROUTE.ROOM_ACCOMMODATIONS,
                path: 'accommodations',
                component: () =>
                    import('@hotels/views/RoomDetails/views/Accommodations/Accommodations.vue'),
                props: true,
                meta: {
                    title: (intl) => intl.t().rooms.rooms.one,
                    guard: (p) => p.rooms.one.accommodations.read,
                    permission: (p) => p.rooms.one.accommodations,
                },
            },
            {
                name: ROUTE.ROOM_CONFIGURATION_WIFI,
                path: 'wifi',
                component: () =>
                    import(
                        '@hotels/views/RoomDetails/views/ConfigurationWifi/ConfigurationWifi.vue'
                    ),
                props: true,
                meta: {
                    title: (intl) => intl.t().rooms.rooms.one,
                    guard: (p) => p.hotels.wifi.read,
                    permission: (p) => p.hotels.wifi,
                },
            },
            {
                name: ROUTE.ROOM_MAINTENANCES,
                path: 'maintenances',
                component: () =>
                    import('@hotels/views/RoomDetails/views/Maintenances/Maintenances.vue'),
                props: true,
                meta: {
                    title: (intl) => intl.t().rooms.rooms.one,
                    guard: (p) => p.hotels.maintenances.read,
                    permission: (p) => p.hotels.maintenances,
                },
            },
            {
                name: ROUTE.ROOM_ACCOMMODATION_CREATE,
                path: 'accommodations/create',
                component: () =>
                    import('@hotels/views/AccommodationCreate/AccommodationCreate.vue'),
                props: true,
                meta: {
                    title: (intl) => intl.t().rooms.rooms.one,
                    guard: (p) => p.rooms.one.accommodations.create,
                },
            },
            {
                name: ROUTE.ROOM_CONFIGURATION_WIFI_CREATE,
                path: 'wifi/create',
                component: () => import('@hotels/views/RoomWifiCreate/RoomWifiCreate.vue'),
                props: true,
                meta: {
                    title: (intl) => intl.t().rooms.rooms.one,
                    guard: (p) => p.hotels.wifi.create,
                },
            },
            {
                name: ROUTE.ROOM_CONFIGURATION_WIFI_EDIT,
                path: 'wifi/edit',
                component: () => import('@hotels/views/RoomWifiEdit/RoomWifiEdit.vue'),
                props: true,
                meta: {
                    title: (intl) => intl.t().rooms.rooms.one,
                    guard: (p) => p.hotels.wifi.update,
                },
            },
        ],
    },
]
