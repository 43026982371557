import {
    setupCache,
    buildKeyGenerator,
    CacheOptions,
    CacheRequestConfig,
} from 'axios-cache-interceptor'

const generator = ({ baseURL = '', url = '', headers, params }: CacheRequestConfig) => {
    const path = baseURL + (baseURL && url ? '/' : '') + url
    const language = headers?.['Accept-Language']

    return { url: path, params, language }
}
const cacheOptions: CacheOptions = {
    ttl: 0,
    generateKey: buildKeyGenerator(generator),
}

export { setupCache, cacheOptions }
