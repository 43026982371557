import { ROUTE } from '@/constants'
import { IRouteConfig } from '@/models'

export const routesOwners: IRouteConfig[] = [
    {
        name: ROUTE.OWNERS,
        path: 'owners',
        component: () => import('@/components/BaseRouterView/BaseRouterView.vue'),
        redirect: {
            name: ROUTE.OWNERS_LIST,
        },
        props: true,
        meta: {
            title: (intl) => intl.t().owners.owners.many,
            guard: (p) => p.access.owners.read,
            permission: (p) => p.access.owners,
            label: (intl) => intl.t().owners.owners.many,
        },
        children: [
            {
                name: ROUTE.OWNERS_LIST,
                path: '',
                component: () => import('@owners/views/Owners/Owners.vue'),
                props: true,
                meta: {
                    title: (intl) => intl.t().owners.owners.many,
                    guard: (p) => p.access.owners.read,
                    permission: (p) => p.access.owners,
                },
            },
            {
                name: ROUTE.OWNER_CREATE,
                path: 'create',
                component: () => import('@owners/views/OwnerCreate/OwnerCreate.vue'),
                props: true,
                meta: {
                    title: (intl) => intl.t().owners.owners.one,
                    guard: (p) => p.access.owners.create,
                    label: (intl) => intl.t().owners.create,
                },
            },
            {
                name: ROUTE.OWNER,
                path: ':ownerId',
                component: () => import('@owners/views/OwnerDetails/OwnerDetails.vue'),
                props: true,
                redirect: { name: ROUTE.OWNER_DETAILS },
                meta: {
                    guard: (p) => p.access.owners.read,
                    permission: (p) => p.access.owners,
                    label: (intl) => intl.t().owners.owners.one,
                },
                children: [
                    {
                        name: ROUTE.OWNER_DETAILS,
                        path: 'details',
                        component: () =>
                            import('@/components/BaseRouterView/BaseRouterView.vue'),
                        props: true,
                        redirect: { name: ROUTE.OWNER_DETAILS_DETAILS },
                        meta: {
                            guard: (p) => p.access.owners.read,
                            permission: (p) => p.access.owners,
                        },
                        children: [
                            {
                                name: ROUTE.OWNER_DETAILS_DETAILS,
                                path: '',
                                component: () =>
                                    import(
                                        '@owners/views/OwnerDetails/views/Details/Details.vue'
                                    ),
                                props: true,
                                meta: {
                                    title: (intl) => intl.t().owners.owners.one,
                                },
                            },
                        ],
                    },
                    {
                        name: ROUTE.OWNER_DETAILS_MENU_ITEMS,
                        path: 'menu-items',
                        component: () =>
                            import('@/components/BaseRouterView/BaseRouterView.vue'),
                        redirect: {
                            name: ROUTE.OWNER_DETAILS_MENU_ITEMS_LIST,
                        },
                        props: true,
                        meta: {
                            title: (intl) => intl.t().owners.owners.one,
                            label: (intl) => intl.t().menuItems.menuItems.many,
                        },
                        children: [
                            {
                                name: ROUTE.OWNER_DETAILS_MENU_ITEMS_LIST,
                                path: '',
                                component: () =>
                                    import(
                                        '@owners/views/OwnerDetails/views/MenuItems/MenuItems.vue'
                                    ),
                                props: true,
                                meta: {
                                    title: (intl) => intl.t().owners.owners.one,
                                },
                            },
                            {
                                name: ROUTE.MENU_ITEM_CREATE,
                                path: 'create',
                                component: () =>
                                    import('@owners/views/MenuItemEdit/MenuItemEdit.vue'),
                                props: true,
                                meta: {
                                    title: (intl) => intl.t().owners.owners.one,
                                    guard: (p) => p.access.owners.create,
                                    label: (intl) => intl.t().menuItems.create,
                                },
                            },
                            {
                                name: ROUTE.MENU_ITEM,
                                path: ':menuItemId',
                                component: () =>
                                    import('@/components/BaseRouterView/BaseRouterView.vue'),
                                props: true,
                                redirect: {
                                    name: ROUTE.MENU_ITEM_DETAILS,
                                },
                                meta: {
                                    title: (intl) => intl.t().owners.owners.one,
                                    guard: (p) => p.access.owners.create,
                                    label: (intl) => intl.t().menuItems.menuItems.one,
                                },
                                children: [
                                    {
                                        name: ROUTE.MENU_ITEM_DETAILS,
                                        path: 'details',
                                        component: () =>
                                            import(
                                                '@owners/views/MenuItemDetails/MenuItemDetails.vue'
                                            ),
                                        props: true,
                                        meta: {
                                            title: (intl) => intl.t().owners.owners.one,
                                            guard: (p) => p.access.owners.create,
                                        },
                                    },
                                    {
                                        name: ROUTE.MENU_ITEM_EDIT,
                                        path: 'edit',
                                        component: () =>
                                            import(
                                                '@owners/views/MenuItemEdit/MenuItemEdit.vue'
                                            ),
                                        props: true,
                                        meta: {
                                            title: (intl) => intl.t().owners.owners.one,
                                            guard: (p) => p.access.owners.create,
                                            label: (intl) => intl.t().menuItems.edit,
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
]
