export enum CONTENT_ACTIVITY_TYPE_VALUE {
    ARCHITECTURE = 'architecture',
    ART = 'art',
    BEAUTY = 'beauty',
    CULTURE = 'culture',
    ENTERTAINMENT = 'entertainment',
    HISTORY = 'history',
    LEISURE = 'leisure',
    NATURE = 'nature',
    RELIGIOUS = 'religious',
    SCIENCE = 'science',
    SPORT = 'sport',
    UNKNOWN = 'unknown', // should not be more like default value: Attraction?
}

export enum CONTENT_EVENT_TYPE_VALUE {
    ART = 'art',
    CONCERT = 'concert',
    CONFERENCE = 'conference',
    CULTURE = 'culture',
    ENTERTAINMENT = 'entertainment',
    HISTORICAL = 'historical',
    PARTY = 'party',
    PERFORMANCE = 'performance',
    RELIGIOUS = 'religious',
    SPORT = 'sport',
    UNKNOWN = 'unknown',
}

export enum CONTENT_PLACE_TYPE_VALUE {
    AIRPORT = 'airport',
    AMUSEMENT_PARK = 'amusement_park',
    ART_GALLERY = 'art_gallery',
    BAR = 'bar',
    BOTANICAL_PLACE = 'botanical_place',
    BREWERY = 'brewery',
    CAFE = 'cafe',
    CASINO = 'casino',
    CULTURE = 'culture',
    ENTERTAINMENT = 'entertainment',
    HISTORY = 'history',
    HOSPITAL = 'hospital',
    LEISURE = 'leisure',
    MARINA = 'marina',
    MONUMENT = 'monument',
    MUSEUM = 'museum',
    NATURE = 'nature',
    NIGHT_LIFE = 'night_life',
    PARK = 'park',
    PHARMACY = 'pharmacy',
    POLICE = 'police',
    PORT = 'port',
    RAILWAY_STATION = 'railway_station',
    RESTAURANT = 'restaurant',
    SCIENCE_CENTER = 'science_center',
    SHOPPING = 'shopping',
    SHRINE = 'shrine',
    SKI_AREA = 'ski_area',
    SPA = 'spa',
    SPORT_PLACE = 'sport_place',
    STADIUM = 'stadium',
    TEMPLE = 'temple',
    THEATER = 'theater',
    TRADE_PLACE = 'trade_place',
    WINERY = 'winery',
    WORSHIP = 'worship',
    ZOO = 'zoo',
    UNKNOWN = 'unknown',
}

export enum CONTENT_STORY_TYPE_VALUE {
    SIGHTSEEING = 'sightseeing',
    CULTURE = 'culture',
    LOCAL_CUISINE = 'local_cuisine',
    ENTERTAINMENT = 'entertainment',
    HISTORY = 'history',
    SCIENCE = 'science',
    LEISURE = 'leisure',
    NATURE = 'nature',
    INFORMATIONS = 'informations',
    UNKNOWN = 'unknown',
}

export enum CONTENT_PRODUCT_TYPE_VALUE {
    BEVERAGES = 'beverages',
    DRINKS = 'drinks',
    FOOD = 'food',
    SOUVENIRS = 'souvenirs',
    UNKNOWN = 'unknown',
}
