import { AxiosInstance } from 'axios'
import { getUserAccount, isTokenRefreshRequired, refreshToken } from './utils'

export function useRequestAuthInterceptor(instance: AxiosInstance): number {
    return instance.interceptors.request.use(async (config) => {
        let userAccount = getUserAccount()

        if (
            userAccount &&
            isTokenRefreshRequired(userAccount.accessTokenExpirationTimestampMs)
        ) {
            await refreshToken()
            userAccount = getUserAccount()
        }

        if (userAccount) {
            config.headers = {
                ['Authorization']: `Bearer ${userAccount.accessToken}`,
                ...config.headers,
            }
        }

        return config
    })
}
