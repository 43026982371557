import { TranslatableOwner } from '@/models'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useAuthStore = defineStore('auth', () => {
    const owner = ref<TranslatableOwner | null>(null)
    const setOwner = (newOwner: TranslatableOwner | null) => {
        owner.value = newOwner
    }
    return {
        owner,
        setOwner,
    }
})
