import { ROUTE } from '@/constants'
import { IRouteConfig } from '@/models'

export const routesSmartCards: IRouteConfig[] = [
    {
        name: ROUTE.CONTENT_SMART_CARDS,
        path: 'smart-cards',
        redirect: {
            name: ROUTE.CONTENT_SMART_CARDS_LIST,
        },
        component: () => import('@/components/BaseRouterView/BaseRouterView.vue'),
        meta: {
            label: (intl) => intl.t().smartCards.smartCards.many,
            guard: (p) => p.mediacontents.contentLibraries.smartContentGroups.read,
        },
        children: [
            {
                name: ROUTE.CONTENT_SMART_CARDS_LIST,
                path: '',
                component: () => import('@content/views/SmartCards/SmartCards.vue'),
                meta: {
                    title: (intl) => intl.t().smartCards.smartCards.many,
                    guard: (p) => p.mediacontents.contentLibraries.smartContentGroups.read,
                    permission: (p) => p.mediacontents.contentLibraries.smartContentGroups,
                },
            },
            {
                name: ROUTE.CONTENT_SMART_CARD_CREATE,
                path: 'create',
                component: () => import('@content/views/SmartCardCreate/SmartCardCreate.vue'),
                props: true,
                meta: {
                    title: (intl) => intl.t().smartCards.create,
                    label: (intl) => intl.t().smartCards.create,
                    guard: (p) =>
                        p.mediacontents.contentLibraries.one.smartContentGroups.create,
                },
            },
            {
                name: ROUTE.CONTENT_SMART_CARD,
                path: ':smartCardId',
                redirect: {
                    name: ROUTE.CONTENT_SMART_CARD_DETAILS,
                },
                component: () => import('@content/views/SmartCardDetails/SmartCardDetails.vue'),
                props: true,
                meta: {
                    label: (intl) => intl.t().smartCards.smartCards.one,
                    guard: (p) => p.mediacontents.contentLibraries.smartContentGroups.read,
                },
                children: [
                    {
                        name: ROUTE.CONTENT_SMART_CARD_DETAILS,
                        path: 'details',
                        component: () =>
                            import('@/components/BaseRouterView/BaseRouterView.vue'),
                        redirect: { name: ROUTE.CONTENT_SMART_CARD_DETAILS_DETAILS },
                        props: true,
                        meta: {
                            guard: (p) =>
                                p.mediacontents.contentLibraries.smartContentGroups.read,
                        },
                        children: [
                            {
                                name: ROUTE.CONTENT_SMART_CARD_DETAILS_DETAILS,
                                path: '',
                                component: () =>
                                    import(
                                        '@content/views/SmartCardDetails/views/Details/Details.vue'
                                    ),
                                props: true,
                                meta: {
                                    title: (intl) => intl.t().smartCards.smartCards.one,
                                    label: (intl) => intl.t().common.details.many,
                                    permission: (p) =>
                                        p.mediacontents.contentLibraries.smartContentGroups,
                                },
                            },
                            {
                                name: ROUTE.CONTENT_SMART_CARD_EDIT_DETAILS,
                                path: 'edit',
                                component: () =>
                                    import(
                                        '@content/views/SmartCardEditDetails/SmartCardEditDetails.vue'
                                    ),
                                props: true,
                                meta: {
                                    title: (intl) => intl.t().smartCards.smartCards.one,
                                    guard: (p) =>
                                        p.mediacontents.contentLibraries.smartContentGroups
                                            .update,
                                    label: (intl) => intl.t().smartCards.edit,
                                },
                            },
                            {
                                name: ROUTE.CONTENT_SMART_CARD_EDIT_ADDRESS,
                                path: 'address/edit/:addressId',
                                component: () =>
                                    import(
                                        '@content/views/SmartCardEditAddress/SmartCardEditAddress.vue'
                                    ),
                                props: true,
                                meta: {
                                    title: (intl) => intl.t().smartCards.smartCards.one,
                                    guard: (p) =>
                                        p.mediacontents.contentLibraries.smartContentGroups
                                            .update,

                                    label: (intl) => intl.t().address.editAddressDetails,
                                },
                            },
                        ],
                    },
                    {
                        name: ROUTE.CONTENT_SMART_CARD_DETAILS_IMAGES,
                        path: 'images',
                        component: () =>
                            import('@content/views/SmartCardDetails/views/Images/Images.vue'),
                        meta: {
                            title: (intl) => intl.t().smartCards.smartCards.one,
                            label: (intl) => intl.t().images.images.many,
                        },
                    },
                    {
                        name: ROUTE.CONTENT_SMART_CARD_DETAILS_RELATED_CARDS,
                        path: 'related-cards',
                        component: () =>
                            import('@/components/BaseRouterView/BaseRouterView.vue'),
                        props: true,
                        meta: {
                            title: (intl) => intl.t().smartCards.smartCards.one,
                            label: (intl) => intl.t().common.relatedContents.many,
                        },
                        redirect: {
                            name: ROUTE.CONTENT_SMART_CARD_DETAILS_RELATED_CARDS_LIST,
                        },
                        children: [
                            {
                                name: ROUTE.CONTENT_SMART_CARD_DETAILS_RELATED_CARDS_LIST,
                                path: '',
                                component: () =>
                                    import(
                                        '@content/views/SmartCardDetails/views/RelatedCards/RelatedCards.vue'
                                    ),
                                props: true,
                                meta: {
                                    title: (intl) => intl.t().smartCards.smartCards.one,
                                },
                            },
                            {
                                name: ROUTE.CONTENT_SMART_CARD_CREATE_FROM_SMART_CARD,
                                path: 'create',
                                component: () =>
                                    import(
                                        '@content/views/SmartCardCreate/SmartCardCreate.vue'
                                    ),
                                props: true,
                                meta: {
                                    title: (intl) => intl.t().smartCards.smartCards.one,
                                    guard: (p) =>
                                        p.mediacontents.contentLibraries.one.smartContentGroups
                                            .create,
                                    label: (intl) => intl.t().smartCards.create,
                                },
                            },
                        ],
                    },
                    {
                        name: ROUTE.CONTENT_SMART_CARD_DETAILS_RELATED_PRODUCTS,
                        path: 'related-products',
                        component: () =>
                            import(
                                '@content/views/SmartCardDetails/views/RelatedProducts/RelatedProducts.vue'
                            ),
                        redirect: {
                            name: ROUTE.CONTENT_SMART_CARD_DETAILS_RELATED_PRODUCTS_PRODUCTS,
                        },
                        meta: {
                            guard: (p, a) => p.marketplace.products.read && a,
                            label: (intl) => intl.t().products.relatedProducts.many,
                        },
                        children: [
                            {
                                name: ROUTE.CONTENT_SMART_CARD_DETAILS_RELATED_PRODUCTS_PRODUCTS,
                                path: 'products',
                                component: () =>
                                    import(
                                        '@content/views/SmartCardDetails/views/RelatedProducts/views/Products/Products.vue'
                                    ),
                                props: true,
                                meta: {
                                    title: (intl) => intl.t().smartCards.smartCards.one,
                                    label: (intl) => intl.t().products.products.many,
                                },
                            },
                            {
                                name: ROUTE.CONTENT_SMART_CARD_DETAILS_RELATED_PRODUCTS_SERVICES,
                                path: 'services',
                                component: () =>
                                    import(
                                        '@content/views/SmartCardDetails/views/RelatedProducts/views/Services/Services.vue'
                                    ),
                                props: true,
                                meta: {
                                    title: (intl) => intl.t().smartCards.smartCards.one,
                                    label: (intl) => intl.t().services.services.many,
                                },
                            },
                        ],
                    },
                    {
                        name: ROUTE.CONTENT_SMART_CARD_DETAILS_VISIBILITY_IN_MENU_ITEMS,
                        path: 'visibility-in-system',
                        component: () =>
                            import(
                                '@content/views/SmartCardDetails/views/VisibilityMenuItems/VisibilityMenuItems.vue'
                            ),
                        redirect: {
                            name: ROUTE.CONTENT_SMART_CARD_DETAILS_VISIBILITY_IN_SYSTEM_HOTELS,
                        },
                        meta: {
                            label: (intl) => intl.t().contents.visibilityInMenuItems,
                        },
                        children: [
                            {
                                name: ROUTE.CONTENT_SMART_CARD_DETAILS_VISIBILITY_IN_SYSTEM_HOTELS,
                                path: 'hotels',
                                component: () =>
                                    import(
                                        '@content/views/SmartCardDetails/views/VisibilityMenuItems/views/Hotels/Hotels.vue'
                                    ),
                                props: true,
                                meta: {
                                    title: (intl) => intl.t().smartCards.smartCards.one,
                                    label: (intl) => intl.t().hotels.hotels.many,
                                },
                            },
                            {
                                name: ROUTE.CONTENT_SMART_CARD_DETAILS_VISIBILITY_IN_SYSTEM_ROOM_GROUPS,
                                path: 'room-groups',
                                component: () =>
                                    import(
                                        '@content/views/SmartCardDetails/views/VisibilityMenuItems/views/RoomGroups/RoomGroups.vue'
                                    ),
                                props: true,
                                meta: {
                                    title: (intl) => intl.t().smartCards.smartCards.one,
                                    label: (intl) => intl.t().roomGroups.roomGroups.many,
                                },
                            },
                        ],
                    },
                    // {
                    //     name: ROUTE.CONTENT_SMART_CARD_DETAILS_VISIBILITY_HELP,
                    //     path: 'visibility-in-help',
                    //     component: () =>
                    //         import(
                    //             '@content/views/SmartCardDetails/views/VisibilityInHelp/VisibilityInHelp.vue'
                    //         ),
                    //     meta: {
                    //         title: (intl) => intl.t().smartCards.smartCards.one,
                    //         label: (intl) => intl.t().contents.visibilityInHelp,
                    //     },
                    // },
                ],
            },
        ],
    },
]
