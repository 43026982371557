import decodeJWT from 'jwt-decode'
import {
    IUserAccount,
    IUserAccountTokens,
    IUserDetails,
    IUserDetailsWithPermissions,
} from '@/models'
import { ITokenData } from '.'

export class UserAccount implements IUserAccount {
    readonly id: string
    readonly name: string
    readonly details: IUserDetailsWithPermissions
    readonly accessToken: string
    readonly refreshToken: string
    readonly accessTokenExpirationTimestampMs: number

    constructor(
        { accessToken, refreshToken }: IUserAccountTokens,
        userDetails: IUserDetailsWithPermissions
    ) {
        const { user_id, username, exp } = decodeJWT<ITokenData>(accessToken)

        this.id = user_id
        this.name = username
        this.details = userDetails
        this.accessToken = accessToken
        this.refreshToken = refreshToken
        this.accessTokenExpirationTimestampMs = exp * 1e3
    }
}
