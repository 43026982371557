import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import VueRouter from 'vue-router'
import { VueConstructor } from 'vue/types/umd'

export const sentry = (Vue: VueConstructor, router: VueRouter) => {
    const dsn = import.meta.env.VITE_APP_SENTRY_DSN

    if (dsn) {
        Sentry.init({
            Vue,
            dsn,
            environment: import.meta.env.VITE_APP_SENTRY_ENV,
            integrations: [
                new Sentry.BrowserTracing({
                    routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                }),
                new Sentry.Replay(),
            ],
            tracesSampleRate: 0.2,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        })
    }
}
