export enum ACTION_AUTH {
    SIGN_IN_FROM_CREDENTIALS = 'SignInFromCredentials',
    SIGN_IN_FROM_REFRESH_TOKEN = 'SignInFromRefreshToken',
    SIGN_IN = 'SignIn',
    SIGN_OUT = 'SignOut',
    REFRESH_ACCESS_TOKEN = 'RefreshAccessToken',
    UPDATE_OWNER_CONTEXT = 'UpdateOwnerContext',
}

export enum ACTION_TOASTS {
    CREATE_TOAST = 'CreateToast',
}

export enum ACTION_LIST {
    FETCH_AND_SET_ITEMS = 'FetchAndSetItems',
    FETCH_AND_ADD_MORE_ITEMS = 'FetchAndAddMoreItems',
}
