import { BackendAuth, IUserAccount, IUserAccountCredentials, IUserDetails } from '@/models'
import { ConverterUserDetails } from '@/converters'
import { UserAccount } from './models'
import {
    IRawUserAccountTokens,
    IRawUserAccountTokensOwner,
    IRawUserDetailsWithPermissions,
    IUserDetailsWithPermissions,
} from '@/models/User'

export const ServiceAuth = new (class ServiceAuth extends BackendAuth {
    async fetchUserAccountFromCredentials(
        credentials: IUserAccountCredentials
    ): Promise<IUserAccount> {
        const {
            data: { refresh: refreshToken, access: accessToken },
        } = await this.post<IRawUserAccountTokens>(
            'authentication/backoffice-administrators/token',
            credentials
        )
        const userDetails = await this.fetchUserDetails(accessToken)

        return new UserAccount({ refreshToken, accessToken }, userDetails)
    }

    async updateOwnerContext(owner: string, token: string): Promise<IUserAccount> {
        const config = { headers: { Authorization: `Bearer ${token}` } }
        const {
            data: { refresh: refreshToken, access: accessToken },
        } = await this.post<IRawUserAccountTokensOwner>(
            `users/backoffice-administrators/owners/${owner}/token/`,
            {
                make_default_owner: true,
            },
            config
        )
        const userDetails = await this.fetchUserDetails(accessToken)

        return new UserAccount({ refreshToken, accessToken }, userDetails)
    }

    async fetchUserAccountFromToken(refreshToken: string): Promise<IUserAccount> {
        const config = { headers: { Authorization: `Bearer ${refreshToken}` } }
        const {
            data: { access: accessToken },
        } = await this.post<{ access: string }>(
            'token/refresh',
            { refresh: refreshToken },
            config
        )
        const userDetails = await this.fetchUserDetails(accessToken)

        return new UserAccount({ refreshToken, accessToken }, userDetails)
    }

    async fetchUserDetails(token: string): Promise<IUserDetailsWithPermissions> {
        const { data } = await this.get<IRawUserDetailsWithPermissions>(
            `users/backoffice-administrators/me`,
            {
                headers: { Authorization: `Bearer ${token}` },
            }
        )
        return ConverterUserDetails.fromRawWithPermissions(data)
    }

    async generatePasswordResetToken(ticket: string): Promise<string> {
        const {
            data: { access },
        } = await this.post<{ access: string }>(
            `authentication/backoffice-administrators/password-change/token`,
            { ticket }
        )
        return access
    }

    async changePassword(password: string, token: string): Promise<void> {
        await this.put<{ password: string }>(
            `authentication/backoffice-administrators/me/password`,
            { password },
            {
                headers: { Authorization: `Bearer ${token}` },
            }
        )
    }
})()
