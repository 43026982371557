import { LOCAL_STORAGE_KEY } from '@/constants'
import { AxiosInstance } from 'axios'

export function useRequestLanguageInterceptor(instance: AxiosInstance): number {
    return instance.interceptors.request.use(async (config) => {
        const language = localStorage.getItem(LOCAL_STORAGE_KEY.LOCALE)
        if (language) {
            config.headers = { ['Accept-Language']: language, ...config.headers }
        }

        return config
    })
}
