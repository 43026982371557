import { ROUTE } from '@/constants'
import { IRouteConfig } from '@/models'
import { router } from './instance'
import { navigationGuard } from './utils'
import VueRouter, { Route } from 'vue-router'
import { routesRegions } from '@regions/routes'
import { routesHotels } from '@hotels/routes'
import { routesDevices } from '@devices/routes'
import { routesUsers } from '@users/routes'
import { routesContent } from '@content/routes'
import { routesMarketplace } from '@marketplace/routes'
import { routesHotelGroups } from '@/apps/hotelGroups/routes'
import { routesOwners } from '@/apps/owners/routes'
import { routesContentLibraries } from '@contentLibraries/routes'
import { inject } from 'vue'
import { routesAdministration } from '@administration/routes'

const routes: IRouteConfig[] = [
    {
        name: ROUTE.START,
        path: '/',
        redirect: (to: Route) => {
            return { name: ROUTE.MY_ACCOUNT, query: to.query }
        },
    },
    {
        name: ROUTE.SIGN_IN,
        path: '/sign-in',
        component: () => import('@/views/SignIn/SignIn.vue'),
        props: true,
        meta: {
            title: (intl) => intl.t().users.signIn,
        },
    },
    {
        name: ROUTE.PASSWORD_CHANGE,
        path: '/password-change/:ticket',
        component: () => import('@/views/PasswordChange/PasswordChange.vue'),
        props: true,
        meta: {
            title: (intl) => intl.t().users.passwordChange,
        },
    },
    {
        path: '/app',
        component: () => import('@/views/Authenticated/Authenticated.vue'),
        redirect: (to: Route) => {
            return { name: ROUTE.MY_ACCOUNT, query: to.query }
        },
        children: [
            ...routesRegions,
            ...routesHotels,
            ...routesDevices,
            ...routesUsers,
            ...routesOwners,
            ...routesContentLibraries,
            ...routesContent,
            ...routesMarketplace,
            ...routesHotelGroups,
            ...routesAdministration,
        ],
        meta: {
            authenticated: true,
        },
    },
    {
        path: '*', // 404
        redirect: { name: ROUTE.START },
    },
]

routes.forEach((route) => router.addRoute(route))
router.beforeEach(navigationGuard)

export { router }
export const routerSymbol = Symbol()
export function useRouter() {
    const routerInstance = inject<VueRouter>(routerSymbol)
    if (!routerInstance) throw new Error('No router provided!')

    return routerInstance
}
