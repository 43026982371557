import { ACTION_AUTH, LOCAL_STORAGE_KEY, ROUTE, STORE_MODULE } from '@/constants'
import VueRouter, { NavigationGuard, Route, RawLocation } from 'vue-router'
import { IRouteGuard } from '@/models'
import { store } from '@/store'
import { getUserAccount } from '@/models/Backend/interceptors/utils'

export const navigationGuard: NavigationGuard = async (to, from, next): Promise<void> => {
    if (!isSignedIn()) await signInFromStoredToken()
    if (canNavigateTo(to)) return next()
    if (!isSignedIn() && isTargetRouteForAuthenticated(to)) {
        return next(getSignInRoute(to))
    }

    return from.name ? next(false) : next({ name: ROUTE.START })
}

function isTargetRouteForAuthenticated(to: Route): boolean {
    return to.matched.some((r) => r.meta?.authenticated)
}

function isSignedIn(): boolean {
    return !!getUserAccount()
}

export function canNavigateTo(to: Route): boolean {
    const userAccount = getUserAccount()
    const userDetails = userAccount?.details
    return to.matched.every((route) => {
        const guard: IRouteGuard | undefined = route.meta?.guard
        if (!guard) return true
        return (
            (userDetails && guard(userDetails.permissions, userDetails.isSuperAdmin)) ?? false
        )
    })
}

export const canNavigateToLocation = (router: VueRouter, to: RawLocation): boolean => {
    const resolvedRoute = router.resolve(to).route
    return canNavigateTo(resolvedRoute)
}

async function signInFromStoredToken(): Promise<void> {
    const refreshToken = localStorage.getItem(LOCAL_STORAGE_KEY.REFRESH_TOKEN)

    if (!refreshToken) return

    return store
        .dispatch(
            `${STORE_MODULE.AUTH}/${ACTION_AUTH.SIGN_IN_FROM_REFRESH_TOKEN}`,
            refreshToken
        )
        .catch(() => {})
}

function getSignInRoute(to: Route): RawLocation {
    return {
        name: ROUTE.SIGN_IN,
        params: { previousRoutePath: to.fullPath },
    }
}
