import { IResource, IContentSmartCardType, SMART_CARD_TYPE_VALUE } from '@/models'

export class ConverterSmartCardType {
    constructor() {
        throw new Error('Cannot instantiate using a static class')
    }

    static fromRaw({ id, name }: IResource): IContentSmartCardType {
        return Object.freeze({ id, name, value: this.getTypeValue(id) })
    }

    private static getTypeValue(typeId: string): SMART_CARD_TYPE_VALUE {
        return (
            Object.values(SMART_CARD_TYPE_VALUE).find(
                (type) => type.toLocaleLowerCase() === typeId.toLocaleLowerCase()
            ) ?? SMART_CARD_TYPE_VALUE.STORY
        )
    }
}
