import { ROUTE } from '@/constants'
import { IRouteConfig } from '@/models'

export const routesCountries: IRouteConfig[] = [
    {
        name: ROUTE.REGIONS_COUNTRIES,
        path: 'countries',
        component: () => import('@/components/BaseRouterView/BaseRouterView.vue'),
        redirect: {
            name: ROUTE.COUNTRIES_LIST,
        },
        meta: {
            title: (intl) => intl.t().countries.countries.many,
            guard: (p) => p.countries.read,
            label: (intl) => intl.t().countries.countries.many,
        },
        children: [
            {
                name: ROUTE.COUNTRIES_LIST,
                path: '',
                component: () => import('@regions/views/Countries/Countries/Countries.vue'),
                meta: {
                    title: (intl) => intl.t().countries.countries.many,
                    guard: (p) => p.countries.read,
                },
            },
            {
                name: ROUTE.COUNTRY_CREATE,
                path: 'create',
                component: () => import('@regions/views/Countries/CountryEdit/CountryEdit.vue'),
                meta: {
                    title: (intl) => intl.t().countries.createCountry,
                    guard: (p) => p.countries.create,
                    label: (intl) => intl.t().countries.createCountry,
                },
            },
            {
                name: ROUTE.COUNTRY,
                path: ':countryId',
                component: () =>
                    import('@regions/views/Countries/CountryDetails/CountryDetails.vue'),
                redirect: { name: ROUTE.COUNTRY_DETAILS },
                props: true,
                meta: {
                    guard: (p) => p.countries.read,
                    label: (intl) => intl.t().countries.countries.one,
                },
                children: [
                    {
                        name: ROUTE.COUNTRY_DETAILS,
                        path: 'details',
                        component: () =>
                            import('@/components/BaseRouterView/BaseRouterView.vue'),
                        redirect: { name: ROUTE.COUNTRY_DETAILS_DETAILS },
                        props: true,
                        meta: {
                            guard: (p) => p.countries.read,
                        },
                        children: [
                            {
                                name: ROUTE.COUNTRY_DETAILS_DETAILS,
                                path: '',
                                component: () =>
                                    import(
                                        '@regions/views/Countries/CountryDetails/views/Details/Details.vue'
                                    ),
                                props: true,
                                meta: {
                                    title: (intl) => intl.t().countries.countries.one,
                                },
                            },
                            {
                                name: ROUTE.COUNTRY_EDIT,
                                path: 'edit',
                                component: () =>
                                    import(
                                        '@regions/views/Countries/CountryEdit/CountryEdit.vue'
                                    ),
                                props: true,
                                meta: {
                                    title: (intl) => intl.t().countries.editCountry,
                                    guard: (p) => p.countries.update,
                                    label: (intl) => intl.t().countries.editCountry,
                                },
                            },
                        ],
                    },
                    {
                        name: ROUTE.COUNTRY_DETAILS_CONTACTS_EMERGENCY,
                        path: 'contacts-emergency',
                        component: () =>
                            import(
                                '@regions/views/Countries/CountryDetails/views/ContactsEmergency/ContactsEmergency.vue'
                            ),
                        props: true,
                        meta: {
                            title: (intl) => intl.t().countries.countries.one,
                            guard: (p) => p.countries.emergencyContacts.read,
                            label: (intl) => intl.t().contacts.emergencyContacts.many,
                        },
                    },
                ],
            },
        ],
    },
]
