import { ACTION_TOASTS, MUTATION_TOASTS, STORE_MODULE } from '@/constants'
import { store } from '@/store'
import { IToastConfig, Toast, IServiceErrorResponse } from '@/models'

export const ServiceToasts = new (class ServiceToasts {
    createToast(config: IToastConfig): Promise<Toast> {
        return store.dispatch(this.getCallerPath(ACTION_TOASTS.CREATE_TOAST), config)
    }

    createServiceErrorToast(errorResponse: IServiceErrorResponse | Error): Promise<Toast> {
        const config: IToastConfig = {
            type: 'error',
            message: this.getErrorMessage(errorResponse),
            timeout: 0,
        }

        return store.dispatch(this.getCallerPath(ACTION_TOASTS.CREATE_TOAST), config)
    }

    private getErrorMessage(errorResponse: IServiceErrorResponse | Error) {
        const serviceError = errorResponse as IServiceErrorResponse
        const message = serviceError.data.detail ?? serviceError.data.message

        return errorResponse instanceof Error
            ? errorResponse.toString() // If some shit happens in code and input will be js Error
            : message
    }

    removeToast(uuid: string): void {
        store.commit(this.getCallerPath(MUTATION_TOASTS.REMOVE_TOAST), uuid)
    }

    private getCallerPath(callerName: MUTATION_TOASTS | ACTION_TOASTS): string {
        return `${STORE_MODULE.TOASTS}/${callerName}`
    }
})()
