import { assertUnreachable } from '@/utils'
import { CacheProperties } from 'axios-cache-interceptor'
import { Duration } from 'luxon'

export type CacheStrategy = 'static' | 'dynamic'

export function getCacheStrategy(strategy: CacheStrategy): Partial<CacheProperties> {
    switch (strategy) {
        case 'static':
            return { ttl: Duration.fromObject({ hours: 6 }).valueOf() }
        case 'dynamic':
            return { ttl: Duration.fromObject({ minutes: 15 }).valueOf() }
        default:
            assertUnreachable(strategy)
    }
}
