import { ROUTE } from '@/constants'
import { IRouteConfig } from '@/models'

export const routesRegionsRegions: IRouteConfig[] = [
    {
        name: ROUTE.REGIONS_REGIONS,
        path: 'regions',
        component: () => import('@/components/BaseRouterView/BaseRouterView.vue'),
        redirect: {
            name: ROUTE.REGIONS_LIST,
        },
        meta: {
            title: (intl) => intl.t().regions.regions.many,
            guard: (p) => p.regions.read,
            label: (intl) => intl.t().regions.regions.many,
        },
        children: [
            {
                name: ROUTE.REGIONS_LIST,
                path: '',
                component: () => import('@regions/views/Regions/Regions/Regions.vue'),
                meta: {
                    title: (intl) => intl.t().regions.regions.many,
                    guard: (p) => p.regions.read,
                },
            },
            {
                name: ROUTE.REGION_CREATE,
                path: 'create',
                component: () => import('@regions/views/Regions/RegionEdit/RegionEdit.vue'),
                meta: {
                    title: (intl) => intl.t().regions.createRegion,
                    guard: (p) => p.regions.create,
                    label: (intl) => intl.t().regions.createRegion,
                },
            },
            {
                name: ROUTE.REGION,
                path: ':regionId',
                component: () => import('@/components/BaseRouterView/BaseRouterView.vue'),
                redirect: {
                    name: ROUTE.REGION_DETAILS,
                },
                props: true,
                meta: {
                    title: (intl) => intl.t().regions.regions.one,
                    guard: (p) => p.regions.read,
                    label: (intl) => intl.t().regions.regions.one,
                },
                children: [
                    {
                        name: ROUTE.REGION_DETAILS,
                        path: '',
                        component: () =>
                            import('@regions/views/Regions/RegionDetails/RegionDetails.vue'),
                        props: true,
                        meta: {
                            title: (intl) => intl.t().regions.regions.one,
                            guard: (p) => p.regions.read,
                        },
                    },
                    {
                        name: ROUTE.REGION_EDIT,
                        path: 'edit',
                        component: () =>
                            import('@regions/views/Regions/RegionEdit/RegionEdit.vue'),
                        props: true,
                        meta: {
                            title: (intl) => intl.t().regions.editRegion,
                            guard: (p) => p.regions.update,
                            label: (intl) => intl.t().regions.editRegion,
                        },
                    },
                ],
            },
        ],
    },
]
