import { ROUTE } from '@/constants'
import { IRouteConfig } from '@/models'
import { routesCountries, routesRegionsRegions } from './routes'

export const routesRegions: IRouteConfig[] = [
    {
        path: 'regions',
        component: () => import('@/components/BaseRouterView/BaseRouterView.vue'),
        redirect: { name: ROUTE.REGIONS_REGIONS },
        meta: {
            guard: (_p, a) => a,
            permission: (p) => p.regions,
        },
        children: [...routesCountries, ...routesRegionsRegions],
    },
]
