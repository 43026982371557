import { MENU_ITEM_TYPE_VALUE } from '@/constants'
import { IMenuItemType, IResource } from '@/models'

export class ConverterMenuItemType {
    constructor() {
        throw new Error('Cannot instantiate using a static class')
    }

    static fromRaw({ id, name }: IResource): IMenuItemType {
        return { id, name, value: this.getTypeValue(id) }
    }

    private static getTypeValue(typeId: string): MENU_ITEM_TYPE_VALUE {
        return (
            Object.values(MENU_ITEM_TYPE_VALUE).find((type) => type === typeId) ??
            MENU_ITEM_TYPE_VALUE.HOTEL
        )
    }
}
