import { ROUTE } from '@/constants'
import { IRouteConfig } from '@/models'
import { routesHotelDetails, routesRoomDetails } from './routes'

export const routesHotels: IRouteConfig[] = [
    {
        name: ROUTE.HOTELS,
        path: 'hotels',
        component: () => import('@/components/BaseRouterView/BaseRouterView.vue'),
        redirect: {
            name: ROUTE.HOTELS_LIST,
        },
        meta: {
            label: (intl) => intl.t().hotels.hotels.many,
        },
        children: [
            {
                name: ROUTE.HOTELS_LIST,
                path: '',
                component: () => import('@hotels/views/Hotels/Hotels.vue'),
                meta: {
                    title: (intl) => intl.t().hotels.hotels.many,
                    guard: (p) => p.hotels.read,
                    permission: (p) => p.hotels,
                },
            },
            {
                name: ROUTE.HOTEL_CREATE,
                path: 'create',
                component: () => import('@hotels/views/HotelCreate/HotelCreate.vue'),
                meta: {
                    title: (intl) => intl.t().hotels.createHotel,
                    guard: (p) => p.hotels.create,
                    label: (intl) => intl.t().hotels.createHotel,
                },
            },
            {
                name: ROUTE.HOTEL,
                path: ':hotelId',
                component: () => import('@hotels/views/HotelDetails/HotelDetails.vue'),
                redirect: {
                    name: ROUTE.HOTEL_DETAILS,
                },
                props: true,
                meta: {
                    label: (intl) => intl.t().hotels.hotels.one,
                },
                children: [
                    {
                        name: ROUTE.HOTEL_DETAILS,
                        path: 'details',
                        component: () =>
                            import('@/components/BaseRouterView/BaseRouterView.vue'),
                        redirect: { name: ROUTE.HOTEL_DETAILS_DETAILS },
                        props: true,
                        meta: {
                            title: (intl) => intl.t().configurations.editWifi,
                            guard: (p) => p.hotels.read,
                        },
                        children: [
                            {
                                name: ROUTE.HOTEL_DETAILS_DETAILS,
                                path: '',
                                component: () =>
                                    import(
                                        '@hotels/views/HotelDetails/views/Details/Details.vue'
                                    ),
                                props: true,
                                meta: {
                                    title: (intl) => intl.t().hotels.hotels.one,
                                    permission: (p) => p.hotels,
                                },
                            },
                            {
                                name: ROUTE.HOTEL_EDIT_DETAILS,
                                path: 'edit',
                                component: () =>
                                    import(
                                        '@hotels/views/HotelEditDetails/HotelEditDetails.vue'
                                    ),
                                props: true,
                                meta: {
                                    title: (intl) => intl.t().hotels.editHotelDetails,
                                    guard: (p) => p.hotels.update,
                                    label: (intl) => intl.t().hotels.editHotel,
                                },
                            },
                            {
                                name: ROUTE.HOTEL_EDIT_ADDRESS,
                                path: 'address/edit/:addressId',
                                component: () =>
                                    import(
                                        '@hotels/views/HotelEditAddress/HotelEditAddress.vue'
                                    ),
                                props: true,
                                meta: {
                                    title: (intl) => intl.t().address.editAddressDetails,
                                    guard: (p, a) => p.addresses.update && a,
                                    label: (intl) => intl.t().address.editAddressDetails,
                                },
                            },
                        ],
                    },
                    ...routesHotelDetails,
                ],
            },
            {
                name: ROUTE.HOTEL_CHILDREN_ROOM,
                path: ':hotelId',
                component: () => import('@/components/BaseRouterView/BaseRouterView.vue'),
                props: true,
                meta: {
                    label: (intl) => intl.t().hotels.hotels.one,
                },
                redirect: { name: ROUTE.HOTEL_CHILD_ROOMS },
                children: [
                    {
                        name: ROUTE.HOTEL_CHILD_ROOMS,
                        path: 'rooms',
                        component: () =>
                            import('@/components/BaseRouterView/BaseRouterView.vue'),
                        props: true,
                        meta: {
                            label: (intl) => intl.t().rooms.rooms.many,
                        },
                        redirect: { name: ROUTE.HOTEL_ROOMS },
                        children: [...routesRoomDetails],
                    },
                ],
            },
        ],
    },
]
