import { DateTime } from 'luxon'
import { LatLng, TranslatableAddressConfig, TranslatableAddressFormConfig } from '../Address'
import {
    TranslatableUnwrapped,
    TranslatableTranslationsUnwrapped,
    TranslatableTranslations,
} from '../Translatable'
import {
    IContentStoryType,
    IMetadata,
    IContentEventType,
    IContentActivityType,
    IContentPlaceType,
} from '../Content'

import { IImageWithBlobConfig, IImageWithBlobFormConfig, ImageUrl } from '../Image'
import { IMenuItemFeed } from '../IMenuItemFeed'
import { TranslatableContentLibrary } from '../ContentLibrary'

export enum SMART_CARD_TYPE_VALUE {
    PLACE = 'Place',
    STORY = 'Story',
    EVENT = 'Event',
    ACTIVITY = 'Activity',
}

export interface IAffiliatedSmartCardHotel {
    id: string
    name: TranslatableTranslationsUnwrapped
}

export interface IContentSmartCardType {
    id: string
    name: string
    value: SMART_CARD_TYPE_VALUE
}

// -------------- DETAILS INTERFACES --------------

export interface ISmartCardDetailsContentLibrary {
    id: string
    affiliatedHotels: string[]
    affiliatedLanguages: TranslatableTranslations
}

export interface ISmartCardDetailsAddress {
    id: string
    line1: string
    line2: string
    line3: string
    city: TranslatableTranslationsUnwrapped | null
    state: string
    country: {
        name: TranslatableTranslationsUnwrapped
        code: string
    }
    postalCode: string
    latLng: LatLng
}
export type TranslatableSmartCardDetailsAddress =
    TranslatableUnwrapped<ISmartCardDetailsAddress>

export interface ISmartCardBaseDetails {
    id: string
}

export interface ISmartCardStoryDetails extends ISmartCardBaseDetails {
    type: IContentStoryType
}

export interface ISmartCardPlaceDetails extends ISmartCardBaseDetails {
    type: IContentPlaceType
    address: TranslatableSmartCardDetailsAddress
    openingHoursSpecification: string
    isEntryFeeRequired: boolean
    isReservationRequired: boolean
    priceLevel?: number
}

export interface ISmartCardActivityDetails extends ISmartCardBaseDetails {
    type: IContentActivityType
    openingHoursSpecification: string
    isReservationRequired: boolean
    isEntryFeeRequired: boolean
    duration?: string
    priceLevel?: number
}

export interface ISmartCardEventDetails extends ISmartCardBaseDetails {
    type: IContentEventType
    timestampBegin: DateTime
    timestampEnd?: DateTime
}

export interface ISmartCardDetails {
    id: string
    name: TranslatableTranslationsUnwrapped
    description: TranslatableTranslationsUnwrapped
    contentLibrary: ISmartCardDetailsContentLibrary
    imgUrl: ImageUrl
    type: IContentSmartCardType
    story?: ISmartCardStoryDetails
    place?: ISmartCardPlaceDetails
    activity?: ISmartCardActivityDetails
    event?: ISmartCardEventDetails
    affiliatedLanguages?: TranslatableTranslationsUnwrapped
    isEntrypoint: boolean
    relatedSmartContentGroups: string[]
    relatedProducts: string[]
    relatedServices: string[]
    timestampActivatedAt: DateTime
    timestampDeactivatedAt?: DateTime
}

export type TranslatableSmartCardDetails = TranslatableUnwrapped<ISmartCardDetails>

// -------------- MANAGEMENT --------------
export type ISmartCardBaseDetailsFormConfig = Omit<ISmartCardBaseDetails, 'id'>
export type ISmartCardStoryDetailsFormConfig = Omit<ISmartCardStoryDetails, 'id' | 'type'> & {
    type?: IContentStoryType
}
export type ISmartCardPlaceDetailsFormConfig = Omit<
    ISmartCardPlaceDetails,
    'id' | 'address' | 'type'
> & { type?: IContentPlaceType }
export type ISmartCardEventDetailsFormConfig = Omit<ISmartCardEventDetails, 'id' | 'type'> & {
    type?: IContentEventType
}
export type ISmartCardActivityDetailsFormConfig = Omit<
    ISmartCardActivityDetails,
    'id' | 'type'
> & { type?: IContentActivityType }

export type ISmartCardBaseDetailsConfig = Required<ISmartCardBaseDetailsFormConfig>
export type ISmartCardStoryDetailsConfig = Required<ISmartCardStoryDetailsFormConfig>
export type ISmartCardPlaceDetailsConfig = Required<ISmartCardPlaceDetailsFormConfig>
export type ISmartCardEventDetailsConfig = Required<ISmartCardEventDetailsFormConfig>
export type ISmartCardActivityDetailsConfig = Required<ISmartCardActivityDetailsFormConfig>

export interface ISmartCardDetailsFormConfig {
    name: TranslatableTranslationsUnwrapped
    description: TranslatableTranslationsUnwrapped
    story: ISmartCardStoryDetailsFormConfig | null
    place: ISmartCardPlaceDetailsFormConfig | null
    event: ISmartCardEventDetailsFormConfig | null
    activity: ISmartCardActivityDetailsFormConfig | null
    relatedSmartContentGroups?: string[]
    affiliatedLanguages?: TranslatableTranslationsUnwrapped
    relatedProducts?: string[]
    relatedServices?: string[]
    timestampActivatedAt: DateTime
    timestampDeactivatedAt?: DateTime
}

export interface ISmartCardDetailsConfig {
    name: TranslatableTranslationsUnwrapped
    description: TranslatableTranslationsUnwrapped
    story: ISmartCardStoryDetailsConfig | null
    place: ISmartCardPlaceDetailsConfig | null
    event: ISmartCardEventDetailsConfig | null
    activity: ISmartCardActivityDetailsConfig | null
    relatedSmartContentGroups: string[]
    relatedProducts: string[]
    relatedServices: string[]
    timestampActivatedAt: DateTime
    timestampDeactivatedAt?: DateTime
}

export type TranslatableSmartCardFormConfig = TranslatableUnwrapped<ISmartCardDetailsFormConfig>
export type TranslatableSmartCardConfig = TranslatableUnwrapped<ISmartCardDetailsConfig>

export interface ISmartCardMenuItemConfig {
    menuFeed: IMenuItemFeed
    metadata: IMetadata
}

export interface ISmartCardFormConfig {
    smartCard: TranslatableSmartCardFormConfig
    address?: TranslatableAddressFormConfig
    images?: IImageWithBlobFormConfig[]
    menuFeeds?: ISmartCardMenuItemConfig[]
}

export interface ISmartCardConfig {
    smartCard: TranslatableSmartCardConfig
    address?: TranslatableAddressConfig
    images?: IImageWithBlobConfig[]
    menuFeeds?: ISmartCardMenuItemConfig[]
}

// -------------- LIST INTERFACES --------------

export interface ISmartCardListItem {
    id: string
    name: TranslatableTranslationsUnwrapped
    type: IContentSmartCardType
    library: TranslatableContentLibrary
    isEntrypoint: boolean
    isActive: boolean
    imgUrl: ImageUrl
    translationRequired: boolean
    relatedSmartContentGroupsCount: number
    relatedProductsCount: number
}

export interface ISmartCardFeedListItem extends ISmartCardListItem {
    featuredContentGroupId?: string
    metadata: IMetadata
}

export interface ISmartCardListFilters {
    query?: string
    isActive?: boolean | null
    isEntrypoint?: boolean | null
    allLanguages?: boolean
    contentLibraries?: string
    hasMissingTranslations?: boolean | null
}
