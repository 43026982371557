import axios from 'axios'
import { useResponseErrorInterceptor } from './interceptors'
import { paramsSerializer } from './utils'

const axiosInstance = axios.create({
    withCredentials: true,
    baseURL: import.meta.env.VITE_APP_BACKEND_URL,
    paramsSerializer,
})

useResponseErrorInterceptor(axiosInstance)

export abstract class BackendAuth {
    protected get = axiosInstance.get
    protected post = axiosInstance.post
    protected put = axiosInstance.put
    protected patch = axiosInstance.patch
    protected delete = axiosInstance.delete
}
