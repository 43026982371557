import axios, { AxiosRequestConfig } from 'axios'
import { CacheRequestConfig } from 'axios-cache-interceptor'
import {
    useRequestAuthInterceptor,
    useResponseErrorInterceptor,
    useRequestLanguageInterceptor,
} from '../interceptors'
import { cacheOptions, setupCache, getCacheStrategy, CacheStrategy } from './cache'
import { paramsSerializer } from '../utils'

const axiosInstance = axios.create({
    withCredentials: true,
    baseURL: import.meta.env.VITE_APP_CONFIGURATION_HUB_URL,
    paramsSerializer,
})
// interceptors are called in LIFO order, cache must be the last request interceptor
const axiosCacheInstance = setupCache(axiosInstance, cacheOptions)

useRequestAuthInterceptor(axiosCacheInstance)
useRequestLanguageInterceptor(axiosCacheInstance)
useResponseErrorInterceptor(axiosCacheInstance)

interface RequestConfigOptions {
    // apply caching strategy in simple manner
    cacheStrategy?: CacheStrategy
}

export abstract class BackendConfigurationHub {
    protected get = axiosCacheInstance.get
    protected post = axiosCacheInstance.post
    protected put = axiosCacheInstance.put
    protected patch = axiosCacheInstance.patch
    protected delete = axiosCacheInstance.delete

    /** @deprecated use `createRequestConfig` instead */
    get allLanguageConfig(): AxiosRequestConfig {
        return {
            headers: { 'Accept-Language': '*' },
        }
    }

    protected createRequestConfig(
        { cacheStrategy }: RequestConfigOptions,
        config?: AxiosRequestConfig
    ): CacheRequestConfig {
        return {
            ...config,
            headers: {
                ...config?.headers,
            },
            ...(cacheStrategy && { cache: getCacheStrategy(cacheStrategy) }),
        }
    }

    protected async removeCachedEntry(cacheId: string) {
        try {
            await axiosCacheInstance.storage.remove(cacheId)

            return true
        } catch (error) {
            return false
        }
    }
}
