import { ROUTE } from '@/constants'
import { IPermission, IRouteConfig } from '@/models'

const contentLibraryPermissions: IPermission = (p, a) =>
    a
        ? p.mediacontents.contentLibraries
        : {
              read: p.mediacontents.contentLibraries.read,
              create: false,
              delete: false,
              update: false,
          }

export const routesContentLibraries: IRouteConfig[] = [
    {
        name: ROUTE.CONTENT_LIBRARIES,
        path: 'content-libraries',
        component: () => import('@/components/BaseRouterView/BaseRouterView.vue'),
        props: true,
        redirect: { name: ROUTE.CONTENT_LIBRARIES_LIST },
        meta: {
            title: (intl) => intl.t().contentLibraries.contentLibraries.many,
            guard: (p) => p.mediacontents.contentLibraries.read,
            permission: contentLibraryPermissions,
            label: (intl) => intl.t().contentLibraries.contentLibraries.many,
        },
        children: [
            {
                name: ROUTE.CONTENT_LIBRARIES_LIST,
                path: '',
                component: () =>
                    import('@contentLibraries/views/ContentLibraries/ContentLibraries.vue'),
                props: true,

                meta: {
                    title: (intl) => intl.t().contentLibraries.contentLibraries.many,
                    guard: (p) => p.mediacontents.contentLibraries.read,
                    permission: contentLibraryPermissions,
                },
            },
            {
                name: ROUTE.CONTENT_LIBRARY_CREATE,
                path: 'create',
                component: () =>
                    import(
                        '@contentLibraries/views/ContentLibraryCreate/ContentLibraryCreate.vue'
                    ),
                props: true,
                meta: {
                    title: (intl) => intl.t().contentLibraries.create,
                    guard: (p, a) => p.mediacontents.contentLibraries.create && a,
                    label: (intl) => intl.t().contentLibraries.create,
                },
            },
            {
                name: ROUTE.CONTENT_LIBRARY,
                path: ':contentLibraryId',
                component: () =>
                    import(
                        '@contentLibraries/views/ContentLibraryDetails/ContentLibraryDetails.vue'
                    ),
                props: true,
                redirect: { name: ROUTE.CONTENT_LIBRARY_DETAILS },
                meta: {
                    guard: (p) => p.mediacontents.contentLibraries.read,
                    label: (intl) => intl.t().contentLibraries.contentLibraries.one,
                },
                children: [
                    {
                        name: ROUTE.CONTENT_LIBRARY_DETAILS,
                        path: 'details',
                        component: () =>
                            import('@/components/BaseRouterView/BaseRouterView.vue'),
                        props: true,
                        redirect: { name: ROUTE.CONTENT_LIBRARY_DETAILS_SMART_CARDS },
                        meta: {
                            guard: (p) => p.mediacontents.contentLibraries.read,
                        },
                        children: [
                            {
                                name: ROUTE.CONTENT_LIBRARY_DETAILS_DETAILS,
                                path: '',
                                component: () =>
                                    import(
                                        '@contentLibraries/views/ContentLibraryDetails/views/Details/Details.vue'
                                    ),
                                props: true,
                                meta: {
                                    title: (intl) =>
                                        intl.t().contentLibraries.contentLibraries.one,
                                    permission: contentLibraryPermissions,
                                },
                            },
                            {
                                name: ROUTE.CONTENT_LIBRARY_EDIT,
                                path: 'edit',
                                component: () =>
                                    import(
                                        '@contentLibraries/views/ContentLibraryEdit/ContentLibraryEdit.vue'
                                    ),
                                props: true,
                                meta: {
                                    title: (intl) => intl.t().contentLibraries.edit,
                                    guard: (p, a) =>
                                        p.mediacontents.contentLibraries.update && a,
                                    label: (intl) => intl.t().contentLibraries.edit,
                                },
                            },
                        ],
                    },
                    {
                        name: ROUTE.CONTENT_LIBRARY_DETAILS_RELATED_HOTELS,
                        path: 'related-hotels',
                        component: () =>
                            import(
                                '@contentLibraries/views/ContentLibraryDetails/views/RelatedHotels/RelatedHotels.vue'
                            ),
                        props: true,
                        meta: {
                            title: (intl) => intl.t().contentLibraries.contentLibraries.one,
                            guard: (p) => p.mediacontents.contentLibraries.affiliations.read,
                            permission: (p, a) => {
                                const library = contentLibraryPermissions(p, a)
                                const affiliations =
                                    p.mediacontents.contentLibraries.affiliations
                                return {
                                    create: library.create && affiliations.create,
                                    read: library.read && affiliations.read,
                                    update: library.update && affiliations.update,
                                    delete: library.delete && affiliations.delete,
                                }
                            },
                            label: (intl) => intl.t().hotels.relatedHotels.many,
                        },
                    },
                    {
                        name: ROUTE.CONTENT_LIBRARY_DETAILS_SMART_CONTENT,
                        path: 'smart-content',
                        component: () =>
                            import('@/components/BaseRouterView/BaseRouterView.vue'),
                        redirect: {
                            name: ROUTE.CONTENT_LIBRARY_DETAILS_SMART_CARDS,
                        },
                        props: true,
                        children: [
                            {
                                name: ROUTE.CONTENT_LIBRARY_DETAILS_SMART_CARDS,
                                path: 'smart-cards',
                                component: () =>
                                    import(
                                        '@contentLibraries/views/ContentLibraryDetails/views/SmartCards/SmartCards.vue'
                                    ),
                                props: true,
                                meta: {
                                    title: (intl) =>
                                        intl.t().contentLibraries.contentLibraries.one,
                                    guard: (p) =>
                                        p.mediacontents.contentLibraries.smartContentGroups
                                            .read,
                                    permission: (p) =>
                                        p.mediacontents.contentLibraries.one.smartContentGroups,
                                    label: (intl) => intl.t().smartCards.smartCards.many,
                                },
                            },
                            {
                                name: ROUTE.CONTENT_SMART_CARD_CREATE_FROM_LIBRARY,
                                path: 'smart-cards/create/content-libraries/:libraryId',
                                component: () =>
                                    import(
                                        '@content/views/SmartCardCreate/SmartCardCreate.vue'
                                    ),
                                props: true,
                                meta: {
                                    title: (intl) =>
                                        intl.t().contentLibraries.contentLibraries.one,
                                    guard: (p) =>
                                        p.mediacontents.contentLibraries.one.smartContentGroups
                                            .create,
                                    permission: () => {
                                        return {
                                            create: true,
                                            read: true,
                                            update: true,
                                            delete: true,
                                        }
                                    },
                                    label: (intl) => intl.t().smartCards.create,
                                },
                            },
                        ],
                    },
                ],
            },
        ],
    },
]
