import { CONTENT_ACTIVITY_TYPE_VALUE } from '@/constants'
import { IResource, IContentActivityType } from '@/models'

export class ConverterActivityType {
    constructor() {
        throw new Error('Cannot instantiate using a static class')
    }

    static fromRaw({ id, name }: IResource): IContentActivityType {
        return Object.freeze({ id, name, value: this.getTypeValue(id) })
    }

    private static getTypeValue(typeId: string): CONTENT_ACTIVITY_TYPE_VALUE {
        return (
            Object.values(CONTENT_ACTIVITY_TYPE_VALUE).find((type) => type === typeId) ??
            CONTENT_ACTIVITY_TYPE_VALUE.UNKNOWN
        )
    }
}
