import { LANGUAGE } from '@/constants'
import { IIntlPluralRuleOptions } from '@/intl/models/PluralRule'

export const pluralize = (
    locale: LANGUAGE,
    value: number,
    textPattern: (value: number, variation: string) => string,
    variations: IIntlPluralRuleOptions
) => {
    if (value > 0 && value < 1) return textPattern(value, variations.lessThanOne ?? '')
    const rule = new Intl.PluralRules(locale).select(value)
    const variation = variations[rule] ?? ''
    return textPattern(value, variation)
}
