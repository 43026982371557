import { LANGUAGE } from '@/constants'
import { ILocales } from '../models'
import { pluralize } from '@/utils'

const locale = LANGUAGE.PL

export const localesPl: ILocales = {
    common: {
        descriptions: {
            zero: 'Brak opisów',
            one: 'Opis',
            many: 'Opisy',
        },
        none: 'Brak',
        chooseOrAdd: 'Wybierz z listy lub dodaj nowy, naciskając Enter',
        close: 'Zamknij',
        title: {
            zero: 'Brak tytułu',
            one: 'Tytuł',
            many: 'Tytuły',
        },
        add: 'Dodaj',
        addNew: 'Dodaj nowy',
        create: 'Utwórz',
        edit: 'Edytuj',
        save: 'Zapisz',
        accept: 'Akceptuj',
        cancel: 'Anuluj',
        more: 'Więcej',
        delete: 'Usuń',
        send: 'Wyślij',
        notSaved: 'Nie zapisano',
        details: {
            zero: 'Brak szczegółów',
            one: 'Szczegół',
            many: 'Szczegóły',
        },
        areYouSure: 'Na pewno?',
        name: {
            zero: 'Brak nazwy',
            one: 'Nazwa',
            many: 'Nazwy',
        },
        code: {
            zero: 'Brak kodu',
            one: 'Kod',
            many: 'Kody',
        },
        results: {
            zero: 'Brak wyników',
            one: 'Wynik',
            many: 'Wyniki',
        },
        kinds: {
            zero: 'Brak rodzajów',
            one: 'Rodzaj',
            many: 'Rodzaje',
        },
        types: {
            zero: 'Brak typów',
            one: 'Typ',
            many: 'Typy',
        },
        tags: {
            zero: 'Brak tagów',
            one: 'Tag',
            many: 'Tagi',
        },
        restore: 'Przywróć',
        min: 'Minimum',
        max: 'Maximum',
        leadDescriptions: {
            zero: 'Brak opisu wprowadzającego',
            one: 'Opis wprowadzający',
            many: 'Opisy wprowadzające',
        },
        fieldIsRequired: 'To pole jest wymagane',
        fieldIsTranslated: 'To pole jest tłumaczone',
        fieldTranslatedFallbackInfo:
            'Tłumaczenie dla wybranego języka nie istnieje, użyto wartości domyślnej',
        unsupportedLanguageCodeInfo: 'W systemie nie ma języka o takim kodzie ISO',
        timezone: 'Strefa czasowa',
        website: 'Strona internetowa',
        hotelAmenities: {
            zero: 'Brak udogodnień hotelowych',
            one: 'Udogodnienie hotelowe',
            many: 'Udogodnienia hotelowe',
        },
        roomAmenities: {
            zero: 'Brak udogodnień pokojowych',
            one: 'Udogodnienie pokojowe',
            many: 'Udogodnienia pokojowe',
        },
        starRating: 'Gwiazdkowa ocena hotelu',
        number: 'Numer',
        valueInvalid: 'Wartość jest niepoprawna',
        checkIn: 'Zameldowanie',
        checkOut: 'Wymeldowanie',
        type: {
            zero: 'Brak typu',
            one: 'Typ',
            many: 'Typy',
        },
        image: 'Obraz',
        mimeType: 'Typ mimu',
        statuses: {
            zero: 'Brak statusu',
            one: 'Status',
            many: 'Statusy',
        },
        startDate: 'Data rozpoczęcia',
        endDate: 'Data zakończenia',
        period: 'Okres',
        dateOfBirth: 'Data urodzenia',
        nationalityCode: 'Kod narodowości',
        nationalityCodeInvalid: 'Kod narodowości jest niezgodny ze standardem ISO Alpha 3',
        firstName: 'Imię',
        lastName: 'Nazwisko',
        genders: {
            zero: 'Brak płci',
            one: 'Płeć',
            many: 'Płcie',
        },
        default: 'Domyślny',
        restoreDefault: 'Przywróć domyślny',
        defaultValue: 'Wartość domyślna',
        languages: {
            zero: 'Brak języka',
            one: 'Język',
            many: 'Języki',
        },
        systemLanguages: {
            zero: 'Brak języka systemu',
            one: 'Język systemu',
            many: 'Języki systemu',
        },
        fullName: 'Imię i nazwisko',
        port: 'Port',
        defaultLanguage: {
            zero: 'Brak domyślnego języka',
            one: 'Domyślny język',
            many: 'Domyślne języki',
        },
        makeDefault: 'Ustaw jako domyślny',
        priceLevel: {
            zero: 'Brak poziomu cen',
            one: 'Poziom cen',
            many: 'Poziomy cen',
        },
        prices: {
            zero: 'Brak cen',
            one: 'Cena',
            many: 'Ceny',
        },
        yes: 'Tak',
        no: 'Nie',
        dateResourceActivated: 'Data aktywacji zasobu',
        durations: {
            zero: 'Brak czasu trwania',
            one: 'Czas trwania',
            many: 'Czasy trwania',
        },
        required: '*',
        orders: {
            zero: 'Brak Kolejności',
            one: 'Kolejność',
            many: 'Kolejności',
        },
        dimensions: {
            zero: 'Brak wymiarów',
            one: 'Wymiar',
            many: 'Wymiary',
        },
        url: {
            zero: 'Brak adresu URL',
            one: 'Adres URL',
            many: 'Adresy URL',
        },
        relatedContents: {
            zero: 'Brak powiązanych treści',
            one: 'Powiązana treść',
            many: 'Powiązane treści',
        },
        contents: {
            zero: 'Brak treści',
            one: 'Treść',
            many: 'Treści',
        },
        range: 'Zakres',
        times: {
            zero: 'Brak czasu',
            one: 'Czas',
            many: 'Czasy',
        },
        data: {
            zero: 'Brak danych',
            one: 'Dana',
            many: 'Dane',
        },
        startTimes: {
            zero: 'Brak czasu rozpoczęcia',
            one: 'Czas rozpoczęcia',
            many: 'Czasy rozpoczęcia',
        },
        ago: 'Temu',
        active: 'Aktywny',
        inactive: 'Nieaktywny',
        inactiveDescription: 'Ten element jest nieaktywny',
        pluralizedMinutes: (minutes: number) =>
            pluralize(locale, minutes, (_, variation) => variation, {
                lessThanOne: 'Poniżej minuty',
                one: '1 minuta',
                few: `${minutes} minuty`,
                many: `${minutes} minut`,
            }),
        pluralizedHours: (hours: number) =>
            pluralize(locale, hours, (_, variation) => variation, {
                lessThanOne: 'Poniżej godziny',
                one: '1 godzina',
                few: `${hours} godziny`,
                many: `${hours} godzin`,
            }),
        pluralizedDays: (days: number) =>
            pluralize(locale, days, (_, variation) => variation, {
                lessThanOne: 'Poniżej dnia',
                one: 'Dzień',
                few: `${days} dni`,
                many: `${days} dni`,
            }),
        days: {
            zero: 'Brak dni',
            one: 'Dzien',
            many: 'Dni',
        },
        weeks: {
            zero: 'Brak tygodni',
            one: 'Tydzień',
            many: 'Tygodnie',
        },
        months: {
            zero: 'Brak miesięcy',
            one: 'Miesiąc',
            many: 'Miesiące',
        },
        years: {
            zero: 'Brak lat',
            one: 'Rok',
            many: 'Lata',
        },
        daily: 'Dziennie',
        weekly: 'Tygodniowo',
        monthly: 'Miesięcznie',
        yearly: 'Rocznie',
        search: 'Szukaj',
        showMore: 'Pokaż więcej...',
        showLess: 'Pokaż mniej...',
        copyToClipboard: 'Skopiuj do schowka',
        copiedToClipboard: 'Skopiowano do schowka',
        example: 'Przykład',
        showActive: 'Wyświetl aktywne',
        showInactive: 'Wyświetl nieaktywne',
        deactivate: 'Dezaktywuj',
        activate: 'Aktywuj',
        open: 'Otwarte',
        valueExists: 'Wartość już istnieje',
        import: 'Importuj',
        importLink: 'Link do formularza',
        dropFile: 'Upuść plik do przesłania w dowolnym miejscu',
        or: 'lub',
        selectFile: 'Wybierz plik',
        selectCSVFormat: 'Wybierz plik w formacie CSV',
        selectImageFormat: 'Wybierz zdjęcie z listy',
        invalidFormat: 'Niepoprawny format',
        addToFolder: 'Należy dodać do folderu',
        andSendToServer: 'i przesłać na serwer',
        currencies: {
            zero: 'Brak waluty',
            one: 'Waluta',
            many: 'Waluty',
        },
        hasParent: 'Ma rodzica',
        hasChildren: 'Ma dzieci',
        availableRequestTypes: {
            zero: 'Brak dostępnych typów żądań',
            one: 'Dostępny typ żądania',
            many: 'Dostępne typy żądań',
        },
        showFilters: 'Pokaż filtry',
        hideFilters: 'Ukryj filtry',
        deleteDescription:
            'Jeśli element zostanie usunięty, przestanie być widoczny w systemie, a jego powiązania do innych elementów przestaną istnieć.',
        activateDescription:
            'Jeśli element zostanie aktywowany, ponownie będzie widoczny w systemie wraz z powiązaniami do innych zasobów.',
        deactivateDescription:
            'Jeśli element zostanie zdezaktywowany, przestanie być widoczny w systemie, a jego powiązania z innymi elementami przestaną być widoczne. Zawsze możesz aktywować element ponownie.',
        next: 'Dalej',
        back: 'Wróć',
        lists: {
            zero: 'Brak list',
            one: 'Lista',
            many: 'Listy',
        },
        tiles: {
            zero: 'Brak kafli',
            one: 'Kafel',
            many: 'Kafle',
        },
        searchInAllLanguages: 'Wyszukuj we wszystkich językach',
        nextDay: 'Następny dzień',
        confirmationMethods: {
            zero: 'Brak metod potwierdzania transakcji',
            one: 'Metoda potwierdzania transakcji',
            many: 'Metody potwierdzania transakcji',
        },
        media: {
            zero: 'Brak mediów',
            one: 'Medium',
            many: 'Media',
        },
        contentTarget: {
            zero: 'Brak celu do dostarczania treści',
            one: 'Cel dostarczania treści',
            many: 'Cele dostarczania treści',
        },
        somethingWentWrong: 'Coś poszło nie tak',
        tryAgain: 'Spróbuj ponownie',
        manuals: {
            zero: 'Brak instrukacji',
            one: 'Instrukcja',
            many: 'Instrukcje',
        },
        trash: 'Kosz',
        visibleInSystem: 'Widoczne w systemie',
        tvCableType: 'Typ kabla TV',
        isVisibleInTouristApp: 'Czy hotel jest dostępny w aplikacji turysty?',
        tvAvailableInputs: 'Dostępne typy wejścia TV',
        tvPredefinedApplications: 'Predefiniowane aplikacje',
        epgProvider: 'Dostawca EPG',
        main: 'Główna',
        displayMain: 'Wyświetl tylko główne',
        featured: 'Wyróżniona',
        setFeatured: 'Wyróżnij',
        unsetFeatured: 'Odznacz wyróżnienie',
        setAsList: 'Ustaw jako listę',
        setAsTile: 'Ustaw jako kaflę',
        assignedAsList: 'Przypisano jako listę',
        assignedAsTile: 'Przypisano jako kaflę',
        featuredCardCannotBeEdited: 'Nie można edytować wyróżnionej karty',
        translationRequired: 'Wymagane tłumaczenie',
        fillInvalidRequiredTranslatableFields: (count) =>
            `Uzupełnij wymagane pola tłumaczeń (${count})`,
    },
    auth: {
        ownerContextChanged: 'Przełączono kontekst właściciela',
    },
    accommodations: {
        response: {
            created: 'Zakwaterowanie zostało dodane',
            updated: 'Zakwaterowanie zostało zaktualizowane',
            deleted: 'Zakwaterowanie zostało usunięte',
            deactivate: 'Zakwaterowanie zostało zdezaktywowane',
            activate: 'Zakwaterowanie zostało aktywowane',
        },
        accommodations: {
            zero: 'Brak zakwaterowań',
            one: 'Zakwaterowanie',
            many: 'Zakwaterowania',
        },
        onboardingFinishedAt: 'Data ukończenia onboardingu',
        edit: 'Edytuj zakwaterowanie',
        create: 'Utwórz zakwaterowanie',
        activate: 'Aktywuj zakwaterowanie',
        deactivate: 'Dezaktywuj zakwaterowanie',
    },
    configurations: {
        configurations: {
            zero: 'Brak konfiguracji',
            one: 'Konfiguracja',
            many: 'Konfiguracje',
        },
        wifi: {
            zero: 'Brak Wi-Fi',
            one: 'Wi-Fi',
            many: 'Wi-Fi',
        },
        authenticationType: 'Rodzaj uwierzytelnienia',
        wifiIsOpen: 'Otwarte Wi-Fi',
        passwordAuthentication: 'Uwierzytelnianie hasłem',
        authenticationRequired: 'Uwierzytelnianie zewnętrzne',
        editWifi: 'Edytuj Wi-Fi',
        createWifi: 'Utwórz Wi-Fi',
        pickWifi: 'Wybierz Wi-Fi',
        ssid: 'SSID',
        hotelDefault: 'Domyślny dla hotelu',
        securityTypes: {
            zero: 'Brak zabezpieczenia',
            one: 'Rodzaj zabezpieczenia',
            many: 'Rodzaje zabezpieczeń',
        },
        cantSaveDefaultHotel: 'Nie można zmienić domyślnego Wi-Fi hotelu',
        wifiResponse: {
            created: 'Wi-Fi zostało dodane',
            updated: 'Wi-Fi zostało zaktualizowane',
            deleted: 'Wi-Fi zostało usunięte',
        },
        informationCards: {
            zero: 'Brak kart informacyjnych',
            one: 'Karta informacyjna',
            many: 'Karty Informacyjne',
        },
        informationCardResponse: {
            created: 'Karta informacyjna została dodana',
            updated: 'Karta informacyjna została zaktualizowana',
            deleted: 'Karta informacyjna została usunięta',
            deactivate: 'Karta informacyjna została zdezaktywowana',
            activate: 'Karta informacyjna została aktywowana',
        },
        editInformationCard: 'Edytuj kartę informacyjną',
        createInformationCard: 'Utwórz kartę informacyjną',
        deactivateInformationCard: 'Aktywuj kartę informacyjną',
        activateInformationCard: 'Dezaktywuj kartę informacyjną',
        navigateToStore: 'Nawiguj do sklepu',
        navigateToMarketplace: "Nawiguj do 'Zakupy'",
        informationCardsDescription:
            'Karty informacyjne będą wyświetlać się w głównych widokach, na samej górze ekranu',
        roomServiceCards: {
            zero: 'Brak karty serwisu pokojowego',
            one: 'Karta serwisu pokojowego',
            many: 'Karty serwisu pokojowego',
        },
        roomServiceCardDescription:
            'Karta serwisu pokojowego wyświetli się na wszystkich widokach',
        storeCards: {
            zero: 'Brak kart sklepów',
            one: 'Karta sklepu',
            many: 'Karty sklepów',
        },
        storeCardDescription:
            'Ustaw kartę sklepu do wybranego widoku, aby pojawiła się w systemie',
        storyDescription: 'Opis Opowieści',
        peripheralTemplates: {
            peripheralTemplates: {
                zero: 'Brak zainstalowanych akcesoriów zewnętrznych',
                one: 'Zainstalowane akcesorium zewnętrzne',
                many: 'Zainstalowane akcesoria zewnętrzne',
            },
            createPeripheralTemplate: 'Utwórz zainstalowane akcesorium zewnętrzne',
            editPeripheralTemplate: 'Edytuj zainstalowane akcesorium zewnętrzne',
            response: {
                created: 'Zainstalowane akcesorium zewnętrzne zostało dodane',
                updated: 'Zainstalowane akcesorium zewnętrzne zostało zaktualizowane',
                deleted: 'Zainstalowane akcesorium zewnętrzne zostało usunięte',
            },
        },
        peripheralTypes: {
            peripheralTypes: {
                zero: 'Brak akcesoriów zewnętrznych',
                one: 'Akcesorium zewnętrzne',
                many: 'Akcesoria zewnętrzne',
            },
            createPeripheralType: 'Utwórz akcesorium zewnętrzne',
            editPeripheralType: 'Edytuj akcesorium zewnętrzne',
            supportedTechnologies: 'Wspierane systemy operacyjne',
            response: {
                created: 'Akcesorium zostało dodane',
                updated: 'Akcesorium zostało zaktualizowane',
                deleted: 'Akcesorium zostało usunięte',
            },
        },
        isMarketplaceAvailable: 'Czy moduł Marketplace jest włączony',
    },
    events: {
        response: {
            created: 'Wydarzenie zostało dodane',
            updated: 'Wydarzenie zostało zaktualizowane',
            deleted: 'Wydarzenie zostało usunięte',
            deactivate: 'Wydarzenie zostało zdezaktywowane',
            activate: 'Wydarzenie zostało aktywowane',
        },
        events: {
            zero: 'Brak wydarzeń',
            one: 'Wydarzenie',
            many: 'Wydarzenia',
        },
        relatedEvents: {
            zero: 'Brak powiazanych wydarzeń',
            one: 'Powiązane wydarzenie',
            many: 'Powiązane wydarzenia',
        },
        edit: 'Edytuj wydarzenie',
        activate: 'Aktywuj wydarzenie',
        deactivate: 'Dezaktywuj wydarzenie',
        create: 'Utwórz wydarzenie',
        createEventFromContentOrLibrary:
            'Wydarzenie może być utworzone z Opowieści, Karty informacyjnej, Miejsca bądź Biblioteki treśći',
    },
    places: {
        places: {
            zero: 'Brak miejsc',
            one: 'Miejsce',
            many: 'Miejsca',
        },
        relatedPlaces: {
            zero: 'Brak powiazanych miejsc',
            one: 'Powiązane miejsce',
            many: 'Powiązane miejsca',
        },
        response: {
            created: 'Miejsce zostało dodane',
            updated: 'Miejsce zostało zaktualizowane',
            deleted: 'Miejsce zostało usunięte',
            deactivate: 'Miejsce zostało zdezaktywowane',
            activate: 'Miejsce zostało aktywowane',
        },
        edit: 'Edytuj miejsce',
        activate: 'Aktywuj miejsce',
        deactivate: 'Dezaktywuj miejsce',
        create: 'Utwórz miejsce',
        createFromContentOrLibrary:
            'Miejsce może być utworzone z Opowieści, Karty informacyjnej bądź Biblioteki treśći',
        placeToVisit: 'Miejsca do odwiedzenia',
        happeningHere: 'To dzieje się tutaj',
    },
    informationCards: {
        informationCards: {
            zero: 'Brak kart informacyjnych',
            one: 'Karta informacyjna',
            many: 'Karty informacyjne',
        },
        relatedInformationCards: {
            zero: 'Brak powiazanych kart informacyjnych',
            one: 'Powiązana karta informacyjna',
            many: 'Powiązane karty informacyjne',
        },
        edit: 'Edytuj kartę informacyjną',
        activate: 'Aktywuj kartę informacyjną',
        deactivate: 'Dezaktywuj kartę informacyjną',
        create: 'Utwórz kartę informacyjną',
        response: {
            created: 'Karta informacyjna została dodana',
            updated: 'Karta informacyjna została zaktualizowana',
            deleted: 'Karta informacyjna została usunięta',
            deactivate: 'Karta informacyjna została zdezaktywowana',
            activate: 'Karta informacyjna została aktywowana',
        },
        createInformationFromContentOrLibrary:
            'Karta informacyjna może być utworzona z Opowieści bądź Biblioteki treśći',
    },
    stories: {
        stories: {
            zero: 'Brak opowieści',
            one: 'Opowieść',
            many: 'Opowieści',
        },
        relatedStories: {
            zero: 'Brak powiazanych opowieści',
            one: 'Powiązana opowieść',
            many: 'Powiązane opowieści',
        },
        displayOption: 'Sposób wyświetlania w systemie',
        response: {
            created: 'Opowieść została dodana',
            updated: 'Opowieść została zaktualizowana',
            deleted: 'Opowieść została usunięta',
            deactivate: 'Opowieść została zdezaktywowana',
            activate: 'Opowieść została aktywowana',
        },
        edit: 'Edytuj opowieść',
        activate: 'Aktywuj opowieść',
        deactivate: 'Dezaktywuj opowieść',
        create: 'Utwórz opowieść',
        createStoryFromHotelOrLibrary:
            'Opowieść może być utworzona tylko z poziomu Widok w Hotel bądź Biblioteki treśći',
    },
    activities: {
        activities: {
            zero: 'Brak aktywności',
            one: 'Aktywność',
            many: 'Aktywności',
        },
        relatedActivities: {
            zero: 'Brak powiazanych aktywności',
            one: 'Powiązana aktywność',
            many: 'Powiązane aktywności',
        },
        response: {
            created: 'Aktywność została dodana',
            updated: 'Aktywność została zaktualizowana',
            deleted: 'Aktywność została usunięta',
            deactivate: 'Aktywność została zdezaktywowana',
            activate: 'Aktywność została aktywowana',
        },
        edit: 'Edytuj aktywność',
        activate: 'Aktywuj aktywność',
        deactivate: 'Dezaktywuj aktywność',
        create: 'Utwórz aktywność',
        createActivityFromContentOrLibrary:
            'Aktywność może być utworzona z Opowieści, Karty informacyjnej, Miejsca bądź Biblioteki treśći',
    },
    devices: {
        devices: {
            zero: 'Brak urządzeń',
            one: 'Urządzenie',
            many: 'Urządzenia',
        },
        register: 'Zarejestruj urządzenie',
        edit: 'Edytuj urządzenie',
        activate: 'Aktywuj urządzenie',
        deactivate: 'Dezaktywuj urządzenie',
        deviceId: 'ID urządzenia',
        models: {
            zero: 'Brak modelu',
            one: 'Model',
            many: 'Modele',
        },
        serialNumber: 'Number seryjny',
        portType: 'Typ portu',
        portIndex: 'Numer portu',
        response: {
            updated: 'Urządzenie zostało usunięte',
            deleted: 'Urządzenie zostało usunięte',
            register: 'Urządzenie zostało zarejestrowane',
            deactivate: 'Urządzenie zostało zdezaktywowane',
            activate: 'Urządzenie zostało aktywowane',
        },
        timestampLastToken: 'Ostatni odczyt aktywnego urządzenia',
        creationDate: 'Data utworzenia',
        deactivationDate: 'Data deazaktywacji',
    },
    images: {
        images: {
            zero: 'Brak zdjęć',
            one: 'Zdjęcie',
            many: 'Zdjęcia',
        },
        icons: {
            zero: 'Brak ikon',
            one: 'Ikona',
            many: 'Ikony',
        },
        response: {
            created: 'Zdjęcie zostało dodane',
            updated: 'Zdjęcie zostało zaktualizowane',
            deleted: 'Zdjęcie zostało usunięte',
        },
        editImage: 'Edytuj zdjęcie',
        createImage: 'Utwórz zdjęcie',
        deleteImage: 'Usuń zdjęcie',
    },
    tvSeries: {
        tvSeries: {
            zero: 'Brak serii TV',
            one: 'Seria TV',
            many: 'Serie TV',
        },
        create: 'Utwórz serię TV',
        delete: 'Usuń serię TV',
        edit: 'Edytuj serię TV',
        response: {
            created: 'Seria TV została dodana',
            deleted: 'Seria TV została usunięta',
            updated: 'Seria TV została zaktualizowane',
        },
        tllElements: {
            zero: 'Brak elementów pliku .tll',
            one: 'Element pliku .tll',
            many: 'Elementy pliku .tll',
        },
    },
    menuItems: {
        menuItems: {
            zero: 'Brak elementów menu',
            one: 'Element menu',
            many: 'Elementy menu',
        },
        response: {
            created: 'Element menu został dodany',
            deleted: 'Element menu został usunięty',
            updated: 'Element menu został zaktualizowany',
        },
        create: 'Utwórz element menu',
        edit: 'Edytuj element menu',
        delete: 'Usuń element menu',
        setCard: 'Ustaw kartę',
        menuItemsCreateContentDescription:
            'Dodaj elementy menu jesli chcesz, aby karta wyświetliła się w nich jako główny punkt',
    },
    stores: {
        stores: {
            zero: 'Brak sklepów',
            one: 'Sklep',
            many: 'Sklepy',
        },
        response: {
            created: 'Sklep został dodany',
            updated: 'Sklep został zaktualizowany',
            deleted: 'Sklep został usunięty',
            deactivate: 'Sklep został zdezaktywowany',
            activate: 'Sklep został aktywowany',
        },
        editStore: 'Edytuj sklep',
        createStore: 'Utwórz sklep',
        activateStore: 'Aktywuj sklep',
        deactivateStore: 'Dezaktywuj sklep',
        deliveryHours: {
            deliveryHours: {
                zero: 'Brak godzin doręczeń',
                one: 'Godzina doręczeń',
                many: 'Godziny doręczeń',
            },
            response: {
                created: 'Godziny doręczeń zostały dodane',
                updated: 'Godzine doręczeń zostały zaktualizowane',
                deleted: 'Godziny doręczeń zostały usunięte',
            },
        },
        emailLanguage: 'Język wiadomości e-mail',
    },
    products: {
        products: {
            zero: 'Brak produktów',
            one: 'Produkt',
            many: 'Produkty',
        },
        recommendedProducts: {
            zero: 'Brak rekomendowanych produktów',
            one: 'Rekomendowany produkt',
            many: 'Rekomendowane produkty',
        },
        recommendedProductsDescription:
            'W każdym sklepie wyświetlane są 3 rekomendowane produkty, jeśli nie zostaną one ustawione, wygenerują się losowo.',
        relatedProducts: {
            zero: 'Brak powiązanych produktów',
            one: 'Powiązany produkt',
            many: 'Powiązane produkty',
        },
        response: {
            created: 'Produkt został dodany',
            updated: 'Produkt został zaktualizowany',
            deleted: 'Produkt został usunięty',
            deactivate: 'Produkt został zdezaktywowany',
            activate: 'Produkt został aktywowany',
        },
        editProduct: 'Edytuj produkt',
        createProduct: 'Utwórz produkt',
        activateProduct: 'Aktywuj produkt',
        deactivateProduct: 'Dezaktywuj produkt',
        deliveryTime: {
            zero: 'Brak czasu doręczenia',
            one: 'Czas doręczenia',
            many: 'Czasy doręczeń',
        },
        deliveryMethods: {
            zero: 'Brak sposobu doręczenia',
            one: 'Sposób doręczenia',
            many: 'Sposoby doręczeń',
        },
        contentInProductDescription:
            'Jeśli zostanie dodany zasób, produkt będzie widoczny w treści z możliwością jego zakupu',
        productInContentDescription:
            'Jeśli produkt zostanie dodany do kontentu, produkt będzie widoczny w treści z możliwością jego zakupu',
        displayProducts: 'Wybierz sklep aby wyświetlić produkty',
        categories: {
            categories: {
                zero: 'Brak kategorii',
                one: 'Kategoria',
                many: 'Kategorie',
            },
            editCategory: 'Edytuj kategorię',
            createCategory: 'Utwórz kategorię',
            activateCategory: 'Aktywuj kategorię',
            deactivateCategory: 'Dezaktywuj kategorię',
            pickCategory: 'Wybierz kategorię',
            response: {
                created: 'Kategoria została dodana',
                updated: 'Kategoria została zaktualizowana',
                deleted: 'Kategoria została usunięta',
                deactivate: 'Kategoria została zdezaktywowana',
                activate: 'Kategoria została aktywowana',
            },
        },
    },
    services: {
        services: {
            zero: 'Brak usług',
            one: 'Usługa',
            many: 'Usługi',
        },
    },
    contents: {
        contents: {
            zero: 'Brak treści',
            one: 'Treść',
            many: 'Treści',
        },
        entryFee: {
            zero: 'Brak opłaty wejściowej',
            one: 'Opłata wejściowa',
            many: 'Opłaty wejściowe',
        },
        regionExclusions: 'Wykluczenia regionalne',
        relatedToContent: (name) => `Powiązane z ${name}`,
        reservationRequired: {
            zero: 'Brak wymaganej rezerwacji',
            one: 'Wymagana rezerwacja',
            many: 'Wymagane rezerwacje',
        },
        visibilityInMenuItems: 'Widoczność w elementach menu',
        visibilityInHelp: 'Widoczność w Pomoc',
        relatedHotelObjectHotelDescription:
            'Jeśli zostanie dodany Hotel, Treść będzie wyświetlać się tylko w wybranym Hotelu. Treść może być powiązana z wieloma Obiektami Hotelowymi(Hotel/Pokój).',
        relatedHotelObjectRoomDescription:
            'Jeśli zostanie dodany Pokój, Treść będzie wyświetlać się tylko w wybranym Pokoju. Treść może być powiązana z wieloma Obiektami Hotelowymi(Hotel/Pokój).',
        relatedHotelObjectDescription:
            'Jeśli zostanie dodany Obiekt Hotelowy, Treść stanie się treścią hotelową. Treść może być treścią hotelową bądź regionalną.',
        regionExclusionsDescription:
            'Jeśli do Treści zostanie przypisany Region(Szczegóły) Treść stanie się treścią regionalną. Treść może być treścią hotelową bądź regionalną.',
        preview: 'Podgląd',
        hotelContent: 'Treści hotelowe',
        eventEnded: 'Wydarzenie zakończone',
        response: {
            deactivate: 'Treść została zdezaktywowana',
            activate: 'Treść została aktywowana',
        },
        activate: 'Aktywuj treść',
        deactivate: 'Dezaktywuj treść',
    },
    hotels: {
        hotels: {
            zero: 'Brak hoteli',
            one: 'Hotel',
            many: 'Hotele',
        },
        response: {
            created: 'Hotel został dodany',
            updated: 'Hotel został zaktualizowany',
            deleted: 'Hotel został usunięty',
            deactivate: 'Hotel został zdezaktywowany',
            activate: 'Hotel został aktywowany',
        },
        editHotel: 'Edytuj hotel',
        editHotelDetails: 'Edytuj detale hotelu',
        createHotel: 'Utwórz hotel',
        activateHotel: 'Aktywuj hotel',
        deactivateHotel: 'Dezaktywuj hotel',
        checkInTime: 'Godziny zameldowania',
        checkOutTime: 'Godziny wymeldowania',
        relatedHotels: {
            zero: 'Brak powiazanych hoteli',
            one: 'Powiązany hotel',
            many: 'Powiązane hotele',
        },
        relatedHotelObjects: {
            zero: 'Brak powiazanych obiektów hotelowych',
            one: 'Powiązany obiekt hotelowy',
            many: 'Powiązane obiekty hotelowe',
        },
        hotelServiceMode: 'Wyświetl hotele serwisowe',
    },
    hotelGroups: {
        hotelGroups: {
            zero: 'Brak grupy hoteli',
            one: 'Grupa hoteli',
            many: 'Grupy hoteli',
        },
        create: 'Utwórz grupę hoteli',
        edit: 'Edytuj grupę hoteli',
        delete: 'Usuń grupę hoteli',
        response: {
            created: 'Grupa hoteli została dodana',
            updated: 'Grupa hoteli została zaktualizowana',
            deleted: 'Grupa hoteli została usunięta',
        },
    },
    roomGroups: {
        roomGroups: {
            zero: 'Brak grupy pokoi',
            one: 'Grupa pokoi',
            many: 'Grupy pokoi',
        },
        create: 'Utwórz grupę pokoi',
        edit: 'Edytuj grupę pokoi',
        delete: 'Usuń grupę pokoi',
        response: {
            created: 'Grupa pokoi została dodana',
            updated: 'Grupa pokoi została zaktualizowana',
            deleted: 'Grupa pokoi została usunięta',
        },
    },
    openingHours: {
        openingHours: {
            zero: 'Brak godzin otwarcia',
            one: 'Godzina otwarcia',
            many: 'Godziny otwarcia',
        },
        openingHoursSchedules: {
            zero: 'Brak harmonogramów godzin otwarcia',
            one: 'Harmonogram godzin otwarcia',
            many: 'Harmonogramy godzin otwarcia',
        },
        openingHoursExclusionSchedules: {
            zero: 'Brak harmonogramów wykluczeń godzin otwarcia',
            one: 'Harmonogram wykluczeń godzin otwarcia',
            many: 'Harmonogramy wykluczeń godzin otwarcia',
        },
        response: {
            created: 'Godziny otwarcia zostały dodane',
            updated: 'Godziny otwarcia zostały zaktualizowane',
            deleted: 'Godziny otwarcia zostały usunięte',
        },
        editSchedule: 'Edytuj grafik godziny otwarcia',
        createSchedule: 'Utwórz grafik godziny otwarcia',
        editScheduleExclusions: 'Edytuj grafik wykluczeń godziny otwarcia',
        createScheduleExclusions: 'Utwórz grafik wykluczeń godziny otwarcia',
        repeatDaily: 'Powtarzalność w cyklu dziennym',
        repeatWeekly: 'Powtarzalność w cyklu tygodniowym',
        repeatMonthly: 'Powtarzalność w cyklu miesięcznym',
        repeatYearly: 'Powtarzalność w cyklu rocznym',
        weekdays: 'Brak dni tygodnia | Dzień tygodnia | Dni tygodnia',
        cycleFrequency: 'Częstotliwość cyklu',
        dayOrigin: 'Dzień początkowy',
        dayOffset: 'Przesunięcie w dniach',
        weekOrigin: 'Tydzień początkowy',
        weekOffset: 'Przesunięcie w tygodniach',
        closed: 'Zamknięte',
        cycleFrequencyDescription: 'Wybierz cykl powtarzalności godzin otwarcia',
        type: {
            type: 'Typ okresu',
            weekday: 'Dzień tygodnia',
            weekdaysRange: 'Zakres dni tygodnia',
            holiday: 'Dni świąteczne',
            other: 'Inne',
        },
        hoursRangeType: {
            hoursRangeType: 'Typ zakresu czasu',
            range: 'Zakres godzin',
            off: 'Zamknięte cały dzień',
        },
        validationError: 'Podana specyfikacja godzin otwarcia jest niepoprawna',
        invalidValue: 'Niepoprawna wartość',
    },
    contacts: {
        contacts: {
            zero: 'Brak kontaktów',
            one: 'Kontakt',
            many: 'Kontakty',
        },
        hotelContacts: {
            zero: 'Brak kontaktów hotelowych',
            one: 'Kontakt hotelowy',
            many: 'Kontakty hotelowe',
        },
        emergencyContacts: {
            zero: 'Brak kontaktów alarmowych',
            one: 'Kontakt alarmowy',
            many: 'Kontakty alarmowe',
        },
        requestCallbackContacts: {
            zero: 'Brak kontaktów do kontaktu z gośćmi',
            one: 'Do kontaktu z goścmi',
            many: 'Kontakty - prośba o oddzwonienie',
        },
        phoneNumber: 'Numer telefonu',
        emails: {
            zero: 'Brak emaili',
            one: 'Email',
            many: 'Emaile',
        },
        response: {
            created: 'Kontakt został dodany',
            updated: 'Kontakt został zaktualizowany',
            deleted: 'Kontakt został usunięty',
        },
        editEmail: 'Edytuj email',
        createEmail: 'Utwórz email',
        editContact: 'Edytuj kontakt',
        createContact: 'Utwórz kontakt',
        deleteContact: 'Usuń kontakt',
    },
    address: {
        address: {
            zero: 'Brak adresu',
            one: 'Adres',
            many: 'Adresy',
        },
        cities: {
            zero: 'Brak miast',
            one: 'Miasto',
            many: 'Miasta',
        },
        latitudeAndLongitude: 'Szerokość i długość geograficzna',
        latitudeAndLongitudeDescription:
            'Wystarczy, że klikniesz w miejsce na mapie prawym przyciskiem myszy, nastpępnie lewym na współrzędne np. 50.02128, 19.88629. Wklej je w jedno z poniżych pól.',
        latitude: 'Szerokość geograficzna',
        longitude: 'Długość geograficzna',
        postalCode: 'Kod pocztowy',
        state: 'Stan',
        line1: 'Linia pierwsza',
        line2: 'Linia druga',
        line3: 'Linia trzecia',
        response: {
            updated: 'Adres został zaktualizowany',
            deleted: 'Adres został usunięty',
        },
        editAddressDetails: 'Edytuj detale adresu',
        filledIdAutomatically:
            'Adres został uzupełniony automatycznie z powiązanego zasobu. W dalszym ciągu możesz go edytować.',
    },
    users: {
        users: {
            zero: 'Brak użytkowników',
            one: 'Użytkownik',
            many: 'Użytkownicy',
        },
        logins: {
            zero: 'Brak loginów',
            one: 'Login',
            many: 'Loginy',
        },
        signIn: 'Zaloguj się',
        signOut: 'Wyloguj się',
        passwordChange: 'Zmiana hasła',
        myAccount: 'Moje konto',
        usernames: {
            zero: 'Brak nazwy użytkownika',
            one: 'Nazwa użytkownika',
            many: 'Nazwy użytkowników',
        },
        password: {
            zero: 'Brak hasła',
            one: 'Hasło',
            many: 'Hasła',
        },
        repeatPassword: 'Powtórz hasło',
        changePassword: 'Zmień hasło',
        administrators: {
            zero: 'Brak aministratorów',
            one: 'Administrator',
            many: 'Administratorzy',
        },
        technicians: {
            zero: 'Brak techników',
            one: 'Technik',
            many: 'Technicy',
        },
        role: {
            role: 'Rola',
            admin: 'Administrator',
            owner: 'Właściciel',
            hotelier: 'Pracownik hotelu',
        },
        response: {
            created: 'Użytkownik został dodany',
            updated: 'Użytkownik został zaktualizowany',
            deleted: 'Użytkownik został usunięty',
            deactivate: 'Użytkownik został zdezaktywowany',
            activate: 'Użytkownik został aktywowany',
        },
        editTechnician: 'Edytuj technika',
        createTechnician: 'Utwórz technika',
        activateTechnician: 'Aktywuj technika',
        deactivateTechnician: 'Dezaktywuj technika',
        editAdministrator: 'Edytuj administratora',
        createAdministrator: 'Utwórz administratora',
        activateAdministrator: 'Aktywuj administratora',
        deactivateAdministrator: 'Dezaktywuj administratora',
    },
    views: {
        views: {
            zero: 'Brak widoków',
            one: 'Widok',
            many: 'Widoki',
        },
        createView: 'Dodaj nowy widok, aby rozpocząć dodawanie treści.',
        experience: 'Doświadcz',
        discover: 'Odkryj',
        taste: 'Lokalna kuchnia',
        help: 'Pomoc',
        hospitals: {
            zero: 'Brak szpitali',
            one: 'Szpital',
            many: 'Szpitale',
        },
        pharmacies: {
            zero: 'Brak aptek',
            one: 'Apteka',
            many: 'Apteki',
        },
        pickHotels: 'Wybierz hotel aby wyświetlić widoki',
    },
    contentFeeds: {
        contentFeeds: {
            zero: 'Brak źródeł teści',
            one: 'Źródło treści',
            many: 'Źródła treści',
        },
        create: 'Utwórz źródło treści',
        edit: 'Edytuj źródło treści',
        delete: 'Usuń źródło treści',
        activate: 'Aktywuj źródło treści',
        deactivate: 'Dezaktywuj źródło treści',
        response: {
            created: 'Źródło treści zostało dodana',
            updated: 'Źródło treści zostało zaktualizowana',
            deleted: 'Źródło treści zostało usunięta',
            deactivate: 'Źródło treści zostało zdezaktywowane',
            activate: 'Źródło treści zostało aktywowane',
        },
    },
    countries: {
        countries: {
            zero: 'Brak krajów',
            one: 'Kraj',
            many: 'Kraje',
        },
        editCountry: 'Edytuj kraj',
        createCountry: 'Utwórz kraj',
        activateCountry: 'Aktywuj kraj',
        deactivateCountry: 'Dezaktywuj kraj',
        response: {
            created: 'Kraj został dodany',
            updated: 'Kraj został zaktualizowany',
            deleted: 'Kraj został usunięty',
            deactivate: 'Kraj został zdezaktywowany',
            activate: 'Kraj został aktywowany',
        },
        countryCode: 'Kod kraju (ISO Alpha 2)',
        countryCodeInvalid: 'Kod kraju jest niezgodny ze standardem ISO Alpha 2',
    },
    regions: {
        regions: {
            zero: 'Brak regionów',
            one: 'Region',
            many: 'Regiony',
        },
        response: {
            created: 'Region został dodany',
            updated: 'Region został zaktualizowany',
            deleted: 'Region został usunięty',
            deactivate: 'Region został zdezaktywowany',
            activate: 'Region został aktywowany',
        },
        editRegion: 'Edytuj region',
        createRegion: 'Utwórz region',
        activateRegion: 'Aktywuj region',
        deactivateRegion: 'Dezaktywuj region',
        regionCode: 'Kod regionu',
        polygonDescription:
            'Skopiuj współprzedne poligonu do schowka, kliknij w link i zaimportuj współrzedne aby wyświetlić Poligon regionu',
        polygonUrlDescription: 'Pokaż poligon',
        createUrlDescription: 'Utwórz poligon',
        polygons: {
            zero: 'Brak poligonu',
            one: 'Poligon',
            many: 'Poligony',
        },
        coordinates: {
            zero: 'Brak wrpółrzędnych',
            one: 'Współprzedna',
            many: 'Współrzędne',
        },
        closePolygon: "Pamiętaj, aby zamknąć poligon. Pod współrzędnymi - 'type': 'Polygon'",
        polygonNotClosed: 'Poligon nie został zamknięty',
    },
    owners: {
        owners: {
            zero: 'Brak właściciela',
            one: 'Właściciel',
            many: 'Właściciele',
        },
        create: 'Utwórz właściciela',
        edit: 'Edytuj właściciela',
        delete: 'Usuń właściciela',
        hotelManagement:
            'Poglądowa lista Hoteli. Właściciela można zmienić z poziomu detali Hotelu',
        response: {
            created: 'Właściciel został dodany',
            updated: 'Właściciel został zaktualizowany',
            deleted: 'Właściciel został usunięty',
        },
        cannotCreateLibrary:
            'Nie można utworzyć biblioteki treści. Brak hoteli powiązanych z Właścicielem',
        cannotCreateStore:
            'Nie można utworzyć Sklepu. Brak hoteli powiązanych z Właścicielem Sprzedawcy',
    },
    contentLibraries: {
        contentLibraries: {
            zero: 'Brak biblioteki treści',
            one: 'Biblioteka treści',
            many: 'Biblioteki treści',
        },
        shared: 'Współdzielona',
        sharedLibrary: 'Biblioteka współdzielona',
        cardInSharedLibrary: 'Karta w bibliotece współdzielonej',
        create: 'Utwórz bibliotekę treści',
        edit: 'Edytuj bibliotekę treści',
        delete: 'Usuń bibliotekę treści',
        response: {
            created: 'Biblioteka treści została dodana',
            updated: 'Biblioteka treści została zaktualizowana',
            deleted: 'Biblioteka treści została usunięta',
        },
    },
    rooms: {
        rooms: {
            zero: 'Brak pokoi',
            one: 'Pokój',
            many: 'Pokoje',
        },
        response: {
            created: 'Pokój został dodany',
            updated: 'Pokój został zaktualizowany',
            deleted: 'Pokój został usunięty',
            deactivate: 'Pokój został zdezaktywowany',
            activate: 'Pokój został aktywowany',
        },
        editRoom: 'Edytuj pokój',
        createRoom: 'Utwórz pokój',
        activateRoom: 'Aktywuj pokój',
        deactivateRoom: 'Dezaktywuj pokój',
        roomNumber: 'Numer pokoju',
        roomName: 'Nazwa pokoju',
        roomCode: 'kod pokoju',
        maxOccupancy: {
            zero: 'Brak maksymalnego obłożenia',
            one: 'Maksymalne obłożenie',
            many: 'Maksymalne obłoenia',
        },
        roomType: {
            zero: 'Brak typu pokoju',
            one: 'Typ pokoju',
            many: 'Typy pokoju',
        },
        roomExists: 'Pokój już istnieje',
        relatedRooms: {
            zero: 'Brak powiazanych pokoi',
            one: 'Powiązany pokój',
            many: 'Powiązane pokoje',
        },
        displayRooms: 'Wybierz hotel aby wyświetlić pokoje',
    },
    guests: {
        guests: {
            zero: 'Brak gości',
            one: 'Gość',
            many: 'Goście',
        },
        response: {
            created: 'Gość został dodany',
            updated: 'Gość został zaktualizowany',
            deleted: 'Gość został usunięty',
            deactivate: 'Gość został zdezaktywowany',
            activate: 'Gość został aktywowany',
        },
        editGuest: 'Edytuj gościa',
        createGuest: 'Utwórz gościa',
        activateGuest: 'Aktywuj gościa',
        deactivateGuest: 'Dezaktywuj gościa',
        classifications: {
            zero: 'Brak klasyfikacji',
            one: 'Klasyfikacja',
            many: 'Klasyfikacje',
        },
    },
    checklists: {
        checklists: {
            zero: 'Brak list zadań',
            one: 'Lista zadań',
            many: 'Listy zadań',
        },
        predefinedChecklists: {
            zero: 'Brak predefinonawych list zadań',
            one: 'Predefiniowana lista zadań',
            many: 'Predefiniowane listy zadań',
        },
        response: {
            created: 'Predefiniowana lista zadań została dodana',
            updated: 'Predefiniowana lista zadań została zaktualizowana',
            deleted: 'Predefiniowana lista zadań została usunięta',
        },
        editPredefinedChecklists: 'Edytuj predefiniowaną listę zadań',
        createPredefinedChecklists: 'Utwórz predefiniowane listę zadań',
    },
    tasks: {
        tasks: {
            zero: 'Brak zadań',
            one: 'Zadanie',
            many: 'Zadania',
        },
        response: {
            created: 'Predefiniowane zadanie zostało dodane',
            updated: 'Predefiniowane zadanie zostało zaktualizowane',
            deleted: 'Predefiniowane zadanie zostało usunięte',
            deactivate: 'Predefiniowane zadanie zostało zdezaktywowane',
            activate: 'Predefiniowane zadanie zostało aktywowane',
        },
        editPredefinedTasks: 'Edytuj predefiniowane zadanie',
        createPredefinedTasks: 'Utwórz predefiniowane zadanie',
        activatePredefinedTasks: 'Aktywuj predefiniowane zadanie',
        deactivatePredefinedTasks: 'Dezaktywuj predefiniowane zadanie',
        predefinedTasks: {
            zero: 'Brak predefinonawych zadań',
            one: 'Predefiniowane zadanie',
            many: 'Predefiniowane zadania',
        },
    },
    maintenances: {
        maintenances: {
            zero: 'Brak utrzymań pokoju',
            one: 'Utrzymanie pokoju',
            many: 'Utrzymania pokoju',
        },
        response: {
            created: 'Utrzymanie pokoju zostało dodane',
            updated: 'Utrzymanie pokoju zostało zaktualizowane',
            deleted: 'Utrzymanie pokoju zostało usunięte',
        },
        editMaintenance: 'Edytuj utrzymanie pokoju',
        createMaintenance: 'Utwórz utrzymanie pokoju',
        deleteMaintenance: 'Usuń utrzymanie pokoju',
    },
    azIndex: {
        azIndex: {
            zero: 'Brak A-Z indeksów',
            one: 'A-Z indeks',
            many: 'A-Z indeksy',
        },
        create: 'Utwórz indeks',
        delete: 'Usuń indeks',
        edit: 'Edytuj A-Z indeks',
        response: {
            created: 'Indeks został dodany',
            updated: 'Indeks został zaktualizowany',
            deleted: 'Indeks został usunięty',
        },
    },
    smartCards: {
        smartCards: {
            zero: 'Brak smart kart',
            one: 'Smart karta',
            many: 'Smart karty',
        },
        smartCardsMenu: 'Menu (Treści)',
        response: {
            created: 'Smart karta zostało dodane',
            updated: 'Smart karta zostało zaktualizowane',
            deleted: 'Smart karta zostało usunięte',
            deactivate: 'Smart karta zostało zdezaktywowane',
            activate: 'Smart karta zostało aktywowane',
        },
        edit: 'Edytuj smart kartę',
        activate: 'Aktywuj smart kartę',
        deactivate: 'Dezaktywuj smart kartę',
        create: 'Utwórz smart kartę',
        type: 'Typ karty',
    },
    administration: {
        administration: 'Administracja',
    },
    systemMessages: {
        systemMessages: {
            zero: 'Brak komunikatów systemowych',
            one: 'Komunikat systemowy',
            many: 'Komunikaty systemowe',
        },
        create: 'Utwórz komunikat',
        delete: 'Usuń komunikat',
        edit: 'Edytuj komunikat',
        activate: 'Aktywuj komunikat',
        deactivate: 'Dezaktywuj komunikat',
        response: {
            created: 'Komunikat został dodany',
            updated: 'Komunikat został zaktualizowany',
            activated: 'Komunikat został aktywowany',
            deactivated: 'Komunikat został zdezaktywowany',
        },
    },
    notifications: {
        notifications: {
            zero: 'Brak powiadomień',
            one: 'Powiadomienie',
            many: 'Powiadomienia',
        },
        response: {
            sent: 'Powiadomienie zostało wysłane',
            updated: 'Powiadomienie zostało zaktualizowane',
            deleted: 'Powiadomienie zostało usunięte',
        },
        edit: 'Edytuj powiadomienie',
        create: 'Utwórz powiadomienie',
        delete: 'Usuń powiadomienie',
        send: 'Wyślij powiadomienie',
        messages: {
            zero: 'Brak wiadomości',
            one: 'Wiadomość',
            many: 'Wiadomości',
        },
        unread: 'Nieprzeczytane',
        sendingTime: 'Data wysłania',
    },
    marketplace: {
        marketplace: 'Marketplace',
    },
}
