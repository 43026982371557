import {
    ContentCounterName,
    IContentLimits,
    IHotelCounters,
    IMarketplaceLimits,
    IMediaLimits,
    IStoreCounters,
} from '@/models'
import { ServiceLimits } from '@/services'
import { defineStore } from 'pinia'
import { ref, computed } from 'vue'

export const useLimitsStore = defineStore('limits', () => {
    const mediaLimits = ref<IMediaLimits | null>(null)
    const contentLimits = ref<IContentLimits | null>(null)
    const marketplaceLimits = ref<IMarketplaceLimits | null>(null)
    const contentCounters = ref<IContentLimits | null>(null)
    const hotelCounters = ref<IHotelCounters | null>(null)
    const storeCounters = ref<IStoreCounters | null>(null)
    const loadingMediaLimits = ref(false)
    const loadingContentLimits = ref(false)
    const loadingContentCounters = ref(false)
    const loadingMarketplaceLimits = ref(false)
    const loadingHotelCounters = ref(false)
    const loadingStoreCounters = ref(false)

    const loadingContent = computed(
        () => loadingContentLimits.value || loadingContentCounters.value
    )

    const getMediaLimits = async (): Promise<void> => {
        if (mediaLimits.value) return
        loadingMediaLimits.value = true
        try {
            mediaLimits.value = await ServiceLimits.fetchMediaLimits()
        } catch {}
        loadingMediaLimits.value = false
    }

    const getContentLimits = async (): Promise<void> => {
        if (contentLimits.value) return
        loadingContentLimits.value = true
        try {
            contentLimits.value = await ServiceLimits.fetchContentLimits()
        } catch {}
        loadingContentLimits.value = false
    }

    const getContentCounters = async (contentLibraryId: string): Promise<void> => {
        loadingContentCounters.value = true
        try {
            contentCounters.value = await ServiceLimits.fetchContentCounters(contentLibraryId)
        } catch {}
        loadingContentCounters.value = false
    }

    const getContentLimitsAndCounters = async (contentLibraryId?: string) => {
        getContentLimits()
        if (contentLibraryId) {
            getContentCounters(contentLibraryId)
        } else {
            contentCounters.value = null
        }
    }

    const getContentAttrsByName = (name: ContentCounterName) => ({
        loading: loadingContent.value,
        limit: contentLimits.value ? contentLimits.value[name] : null,
        count: contentCounters.value ? contentCounters.value[name] : null,
    })

    const getMarketplaceLimits = async (): Promise<void> => {
        if (marketplaceLimits.value) return
        loadingMarketplaceLimits.value = true
        try {
            marketplaceLimits.value = await ServiceLimits.fetchMarketplaceLimits()
        } catch {}
        loadingMarketplaceLimits.value = false
    }

    const getHotelCounters = async (hotelId: string): Promise<void> => {
        loadingHotelCounters.value = true
        try {
            hotelCounters.value = await ServiceLimits.fetchHotelCounters(hotelId)
        } catch {}
        loadingHotelCounters.value = false
    }

    const getStoreCounters = async (storeId: string): Promise<void> => {
        loadingStoreCounters.value = true
        try {
            storeCounters.value = await ServiceLimits.fetchStoreCounters(storeId)
        } catch {}
        loadingStoreCounters.value = false
    }

    const getHotelLimitsAndCounters = async (hotelId: string) => {
        getMarketplaceLimits()
        getHotelCounters(hotelId)
    }

    const storesAttrs = computed(() => ({
        loading: loadingMarketplaceLimits.value || loadingHotelCounters.value,
        limit: marketplaceLimits.value?.hotelStores,
        count: hotelCounters.value?.stores,
    }))

    const getStoreLimitsAndCounters = async (storeId: string) => {
        getMarketplaceLimits()
        getStoreCounters(storeId)
    }

    const productsAttrs = computed(() => ({
        loading: loadingMarketplaceLimits.value || loadingStoreCounters.value,
        limit: marketplaceLimits.value?.products,
        count: storeCounters.value?.products,
    }))
    return {
        mediaLimits,
        contentLimits,
        marketplaceLimits,
        contentCounters,
        hotelCounters,
        storeCounters,
        loadingMediaLimits,
        loadingContentLimits,
        loadingMarketplaceLimits,
        loadingContent,
        loadingHotelCounters,
        loadingStoreCounters,
        storesAttrs,
        productsAttrs,
        getMediaLimits,
        getContentLimits,
        getContentCounters,
        getContentLimitsAndCounters,
        getContentAttrsByName,
        getMarketplaceLimits,
        getHotelCounters,
        getStoreCounters,
        getHotelLimitsAndCounters,
        getStoreLimitsAndCounters,
    }
})
