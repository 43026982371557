import {
    IUserDetails,
    IRawUserDetails,
    IRawUserDetailsConfig,
    IUserDetailsConfig,
    IRawUserDetailsWithPermissions,
    IUserDetailsWithPermissions,
} from '@/models'
export class ConverterUserDetails {
    constructor() {
        throw new Error('Cannot instantiate using a static class')
    }
    static fromRaw({
        id,
        first_name: firstName,
        last_name: lastName,
        language: languageId,
        is_active: isActive,
        is_super_admin: isSuperAdmin,
        role,
    }: IRawUserDetails): IUserDetails {
        return {
            id,
            firstName,
            lastName,
            languageId,
            isActive,
            isSuperAdmin,
            role,
        }
    }
    static fromRawWithPermissions({
        permissions: p,
        ...data
    }: IRawUserDetailsWithPermissions): IUserDetailsWithPermissions {
        return {
            ...ConverterUserDetails.fromRaw(data),
            permissions: {
                access: {
                    owners: {
                        ...p['access.owners'],
                        one: {
                            ownerUsers: p['access.owners.one.owner-users'],
                        },
                    },
                    ownerUsers: p['access.owner-users'],
                },
                accommodations: {
                    ...p['accommodations'],
                    activation: p['accommodations.activation'],
                    guests: p['accommodations.guests'],
                },
                activities: {
                    ...p['activities'],
                    contacts: {
                        ...p['activities.contacts'],
                        activation: p['activities.contacts.activation'],
                    },
                    one: {
                        contacts: p['activities.one.contacts'],
                        images: p['activities.one.images'],
                        openingHours: p['activities.one.opening-hours'],
                        tags: p['activities.one.tags'],
                    },
                },
                addresses: p['addresses'],
                backofficeMessages: {
                    ...p['backoffice-messages'],
                    activation: p['backoffice-messages.activation'],
                    markAsRead: p['backoffice-messages.mark-as-read'],
                    me: p['backoffice-messages.me'],
                },
                broadcastProviders: {
                    ...p['broadcast-providers'],
                    activation: p['broadcast-providers.activation'],
                },
                contacts: {
                    phoneNumbers: p['contacts.phone-numbers'],
                },
                controllableNaturalRangeProperties: p['controllable-natural-range-properties'],
                controllableOnOffProperties: p['controllable-on-off-properties'],
                controllableOptionProperties: p['controllable-option-properties'],
                controllableRealRangeProperties: p['controllable-real-range-properties'],
                controllableSelectionProperties: p['controllable-selection-properties'],
                controllableStates: p['controllable-states'],
                controllableTypes: p['controllable-types'],
                controllables: p['controllables'],
                countries: {
                    ...p['countries'],
                    activation: p['countries.activation'],
                    emergencyContacts: p['countries.emergency-contacts'],
                    one: {
                        emergencyContacts: p['countries.one.emergency-contacts'],
                        regions: p['countries.one.regions'],
                    },
                },
                devices: {
                    ...p['devices'],
                    activation: p['devices.activation'],
                    models: {
                        ...p['devices.models'],
                        export: p['devices.models.export'],
                    },
                    one: {
                        channelsInstallationHistory:
                            p['devices.one.channels-installation-history'],
                    },
                    peripherals: {
                        templates: p['devices.peripherals.templates'],
                        types: {
                            ...p['devices.peripherals.types'],
                            images: p['devices.peripherals.types.images'],
                        },
                    },
                    secret: p['devices.secret'],
                },
                entrypoints: {
                    ...p['entrypoints'],
                    one: {
                        images: p['entrypoints.one.images'],
                        tags: p['entrypoints.one.tags'],
                    },
                },
                events: {
                    ...p['events'],
                    one: {
                        images: p['events.one.images'],
                        tags: p['events.one.tags'],
                    },
                },
                genders: {
                    ...p['genders'],
                    activation: p['genders.activation'],
                },
                guests: {
                    ...p['guests'],
                    activation: p['guests.activation'],
                    contacts: p['guests.contacts'],
                    one: {
                        contacts: p['guests.one.contacts'],
                    },
                    requests: p['guests.requests'],
                },
                hotelGroups: p['hotel-groups'],
                hotel: {
                    amenities: {
                        codes: p['hotel.amenities.codes'],
                    },
                    one: {
                        maintenanceTasksChecklists: p['hotel.one.maintenance-task-checklists'],
                        maintenanceTasks: p['hotel.one.maintenance-tasks'],
                    },
                },
                hotels: {
                    ...p['hotels'],
                    activation: p['hotels.activation'],
                    amenities: {
                        ...p['hotels.amenities'],
                        activation: p['hotels.amenities.activation'],
                    },
                    contacts: {
                        ...p['hotels.contacts'],
                        activation: p['hotels.contacts.activation'],
                    },
                    guestRequestContacts: {
                        ...p['hotels.guest-request-contacts'],
                        activation: p['hotels.guest-request-contacts.activation'],
                    },
                    maintenanceTasksChecklists: {
                        ...p['hotels.maintenance-task-checklists'],
                        one: {
                            maintenanceTasks:
                                p['hotels.maintenance-task-checklists.one.maintenance-tasks'],
                        },
                    },
                    maintenanceTasks: {
                        ...p['hotels.maintenance-tasks'],
                        activation: p['hotels.maintenance-tasks.activation'],
                    },
                    maintenances: p['hotels.maintenances'],
                    one: {
                        amenities: p['hotels.one.amenities'],
                        contacts: p['hotels.one.contacts'],
                        devices: p['hotels.one.devices'],
                        guestRequestContacts: p['hotels.one.guest-request-contacts'],
                        guests: p['hotels.one.guests'],
                        maintenances: p['hotels.one.maintenances'],
                        roomTypes: p['hotels.one.room-types'],
                        rooms: p['hotels.one.rooms'],
                        wifi: p['hotels.one.wifi'],
                        azIndexItems: p['hotels.one.az-index-items'],
                    },
                    roomTypes: {
                        ...p['hotels.room-types'],
                        activation: p['hotels.room-types.activation'],
                        amenities: {
                            ...p['hotels.room-types.amenities'],
                            activation: p['hotels.room-types.amenities.activation'],
                        },
                    },
                    wifi: p['hotels.wifi'],
                },
                informationCards: {
                    ...p['information-cards'],
                    activation: p['information-cards.activation'],
                },
                marketplace: {
                    deliveryHours: p['marketplace.delivery-hours'],
                    products: {
                        ...p['marketplace.products'],
                        activation: p['marketplace.products.activation'],
                        bulkAssignmentChanges:
                            p['marketplace.products.bulk-assignment-changes'],
                        images: p['marketplace.products.images'],
                        services: p['marketplace.products.services'],
                    },
                    services: {
                        ...p['marketplace.services'],
                        activation: p['marketplace.services.activation'],
                        bulkAssignmentChanges:
                            p['marketplace.services.bulk-assignment-changes'],
                    },
                    store: {
                        categories: {
                            ...p['marketplace.store.categories'],
                            activation: p['marketplace.store.categories.activation'],
                            one: {
                                ancestors: p['marketplace.store.categories.one.ancestors'],
                                children: p['marketplace.store.categories.one.children'],
                            },
                        },
                        one: {
                            categories: p['marketplace.store.one.categories'],
                        },
                    },
                    stores: {
                        ...p['marketplace.stores'],
                        activation: p['marketplace.stores.activation'],
                        defaultOrderableDeliveryHours:
                            p['marketplace.stores.default-orderable-delivery-hours'],
                        one: {
                            products: p['marketplace.stores.one.products'],
                            services: p['marketplace.stores.one.services'],
                        },
                    },
                },
                mediacontent: {
                    mediaLibraryImage: p['mediacontent.media-library-image'],
                },
                mediacontents: {
                    contentFeeds: p['mediacontents.content-feeds'],
                    contentLibraries: {
                        ...p['mediacontents.content-libraries'],
                        affiliations: p['mediacontents.content-libraries.affiliations'],
                        contentGroups: {
                            ...p['mediacontents.content-libraries.content-groups'],
                            counter:
                                p['mediacontents.content-libraries.content-groups.counter'],
                            limits: p['mediacontents.content-libraries.content-groups.limits'],
                            related: {
                                activities:
                                    p[
                                        'mediacontents.content-libraries.content-groups.related.activities'
                                    ],
                                entrypoints:
                                    p[
                                        'mediacontents.content-libraries.content-groups.related.entrypoints'
                                    ],
                                events: p[
                                    'mediacontents.content-libraries.content-groups.related.events'
                                ],
                                places: p[
                                    'mediacontents.content-libraries.content-groups.related.places'
                                ],
                                products:
                                    p[
                                        'mediacontents.content-libraries.content-groups.related.products'
                                    ],
                                services:
                                    p[
                                        'mediacontents.content-libraries.content-groups.related.services'
                                    ],
                                stories:
                                    p[
                                        'mediacontents.content-libraries.content-groups.related.stories'
                                    ],
                            },
                        },
                        counter: p['mediacontents.content-libraries.counter'],
                        limits: p['mediacontents.content-libraries.limits'],
                        one: {
                            contentGroups:
                                p['mediacontents.content-libraries.one.content-groups'],
                            smartContentGroups:
                                p['mediacontents.content-libraries.one.smart-content-groups'],
                        },
                        shares: p['mediacontents.content-libraries.shares'],
                        smartContentGroups:
                            p['mediacontents.content-libraries.smart-content-groups'],
                    },
                    contentProviders: p['mediacontents.content-providers'],
                    images: {
                        ...p['mediacontents.images'],
                        variants: p['mediacontents.images.variants'],
                    },
                    tags: p['mediacontents.tags'],
                    videos: {
                        ...p['mediacontents.videos'],
                        thumbnails: {
                            ...p['mediacontents.videos.thumbnails'],
                            imageFrames: p['mediacontents.videos.thumbnails.image-frames'],
                        },
                        variants: p['mediacontents.videos.variants'],
                    },
                    websites: p['mediacontents.websites'],
                    menuItemFeeds: p['mediacontents.menu-item-feeds'],
                },
                notifications: p['notifications'],
                openingHours: {
                    exclusions: {
                        schedules: p['opening-hours.exclusions.schedules'],
                    },
                    schedules: p['opening-hours.schedules'],
                },
                places: {
                    ...p['places'],
                    contacts: {
                        ...p['places.contacts'],
                        activation: p['places.contacts.activation'],
                    },
                    one: {
                        activities: p['places.one.activities'],
                        contacts: p['places.one.contacts'],
                        events: p['places.one.events'],
                        images: p['places.one.images'],
                        openingHours: p['places.one.opening-hours'],
                        tags: p['places.one.tags'],
                    },
                },
                regions: {
                    ...p['regions'],
                    activation: p['regions.activation'],
                    hotels: p['regions.hotels'],
                },
                roomGroups: p['room-groups'],
                roomTypes: {
                    amenities: {
                        codes: p['room-types.amenities.codes'],
                    },
                },
                rooms: {
                    ...p['rooms'],
                    activation: p['rooms.activation'],
                    one: {
                        accommodations: p['rooms.one.accommodations'],
                        devices: p['rooms.one.devices'],
                    },
                },
                stories: {
                    ...p['stories'],
                    one: {
                        images: p['stories.one.images'],
                        tags: p['stories.one.tags'],
                    },
                },
                technicians: {
                    ...p['technicians'],
                    activation: p['technicians.activation'],
                },
                tourGuides: {
                    ...p['tour-guides'],
                    one: {
                        tourElements: p['tour-guides.one.tour-elements'],
                    },
                    tourElements: {
                        ...p['tour-guides.tour-elements'],
                        order: p['tour-guides.tour-elements.order'],
                    },
                },
                users: {
                    backofficeAdministrators: {
                        ...p['users.backoffice-administrators'],
                        activation: p['users.backoffice-administrators.activation'],
                        me: p['users.backoffice-administrators.me'],
                        owner: {
                            token: p['users.backoffice-administrators.owner.token'],
                        },
                    },
                },
            },
        }
    }

    static toRaw({
        firstName,
        lastName,
        languageId,
    }: IUserDetailsConfig): IRawUserDetailsConfig {
        return {
            first_name: firstName,
            last_name: lastName,
            language: languageId,
        }
    }
}
