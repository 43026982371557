<template>
    <RouterView />
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue'
import { setDocumentTitle } from './router/utils'
import { intlSymbol, toastsSymbol } from '@/plugins'
import { routerSymbol } from './router'

export default defineComponent({
    name: 'App',
    provide() {
        return {
            [toastsSymbol]: this.$toasts,
            [intlSymbol]: this.$intl,
            [routerSymbol]: reactive(this.$router),
        }
    },
    watch: {
        '$route.name'() {
            this.setDocumentTitle()
        },
        '$intl.locale'() {
            this.setDocumentTitle()
        },
    },
    methods: {
        setDocumentTitle() {
            setDocumentTitle(this.$route?.meta?.title(this.$intl))
        },
    },
})
</script>

<style lang="sass">
@import "~vuetify/src/styles/main.sass"
@import "@/styles/overrides/_index.sass"
html, body
    min-height: 100%
</style>
