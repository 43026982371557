import { ROUTE } from '@/constants'
import { IRouteConfig } from '@/models'

export const routesUsers: IRouteConfig[] = [
    {
        path: 'user',
        component: () => import('@/components/BaseRouterView/BaseRouterView.vue'),
        redirect: { name: ROUTE.USERS },
        children: [
            {
                name: ROUTE.MY_ACCOUNT,
                path: 'my-account',
                component: () => import('@users/views/MyAccount/MyAccount.vue'),
                meta: {
                    title: (intl) => intl.t().users.myAccount,
                    guard: (p) => p.users.backofficeAdministrators.me.read,
                },
            },
        ],
    },
    {
        path: 'users',
        component: () => import('@/components/BaseRouterView/BaseRouterView.vue'),
        redirect: { name: ROUTE.USERS_ADMINISTRATORS },
        children: [
            {
                name: ROUTE.USERS_TECHNICIANS,
                path: 'technicians',
                component: () => import('@users/views/Technicians/Technicians.vue'),
                meta: {
                    title: (intl) => intl.t().users.technicians.many,
                },
            },
            {
                name: ROUTE.USERS_TECHNICIAN_CREATE,
                path: 'technicians/create',
                component: () => import('@users/views/TechnicianCreate/TechnicianCreate.vue'),
                meta: {
                    title: (intl) => intl.t().users.createTechnician,
                    guard: (p) => p.technicians.create,
                },
            },
            {
                name: ROUTE.USERS_TECHNICIAN_DETAILS,
                path: 'technicians/:technicianId',
                component: () => import('@users/views/TechnicianDetails/TechnicianDetails.vue'),
                props: true,
                meta: {
                    title: (intl) => intl.t().users.technicians.one,
                },
            },
            {
                name: ROUTE.USERS_TECHNICIAN_DETAILS_EDIT,
                path: 'technicians/:technicianId/edit',
                component: () =>
                    import('@users/views/TechnicianDetailsEdit/TechnicianDetailsEdit.vue'),
                props: true,
                meta: {
                    title: (intl) => intl.t().users.editTechnician,
                    guard: (p) => p.technicians.update,
                },
            },
            {
                name: ROUTE.USERS_ADMINISTRATORS,
                path: 'administrators',
                component: () => import('@/components/BaseRouterView/BaseRouterView.vue'),
                redirect: {
                    name: ROUTE.USERS_ADMINISTRATORS_LIST,
                },
                meta: {
                    title: (intl) => intl.t().users.administrators.many,
                    guard: (p) => p.users.backofficeAdministrators.read,
                    label: (intl) => intl.t().users.administrators.many,
                },
                children: [
                    {
                        name: ROUTE.USERS_ADMINISTRATORS_LIST,
                        path: '',
                        component: () =>
                            import('@users/views/Administrators/Administrators.vue'),
                        meta: {
                            title: (intl) => intl.t().users.administrators.many,
                        },
                    },
                    {
                        name: ROUTE.USERS_ADMINISTRATOR_CREATE,
                        path: 'create',
                        component: () =>
                            import('@users/views/AdministratorCreate/AdministratorCreate.vue'),
                        meta: {
                            title: (intl) => intl.t().users.createAdministrator,
                            guard: (p) => p.users.backofficeAdministrators.create,
                            label: (intl) => intl.t().users.createAdministrator,
                        },
                    },
                    {
                        name: ROUTE.USERS_ADMINISTRATOR,
                        path: ':administratorId',
                        component: () =>
                            import('@/components/BaseRouterView/BaseRouterView.vue'),
                        redirect: {
                            name: ROUTE.USERS_ADMINISTRATOR_DETAILS,
                        },
                        props: true,
                        meta: {
                            title: (intl) => intl.t().users.administrators.one,
                            label: (intl) => intl.t().users.administrators.one,
                        },
                        children: [
                            {
                                name: ROUTE.USERS_ADMINISTRATOR_DETAILS,
                                path: 'details',
                                component: () =>
                                    import(
                                        '@users/views/AdministratorDetails/AdministratorDetails.vue'
                                    ),
                                props: true,
                                meta: {
                                    title: (intl) => intl.t().users.administrators.one,
                                },
                            },
                            {
                                name: ROUTE.USERS_ADMINISTRATOR_DETAILS_EDIT,
                                path: 'edit',
                                component: () =>
                                    import(
                                        '@users/views/AdministratorDetailsEdit/AdministratorDetailsEdit.vue'
                                    ),
                                props: true,
                                meta: {
                                    title: (intl) => intl.t().users.editAdministrator,
                                    guard: (p) => p.users.backofficeAdministrators.update,
                                    label: (intl) => intl.t().users.editAdministrator,
                                },
                            },
                            {
                                name: ROUTE.USERS_ADMINISTRATOR_ROLE_EDIT,
                                path: 'edit/role',
                                component: () =>
                                    import(
                                        '@users/views/AdministratorRoleEdit/AdministratorRoleEdit.vue'
                                    ),
                                props: true,
                                meta: {
                                    title: (intl) => intl.t().users.editAdministrator,
                                    guard: (p) => p.users.backofficeAdministrators.update,
                                    label: (intl) => intl.t().users.editAdministrator,
                                },
                            },
                        ],
                    },
                ],
            },
        ],
    },
]
