import { store } from '@/store/instance'
import { STORE_MODULE } from '@/constants'
import { IUserAccount } from '@/models/User/IUserAccount'
import { IStateAuth } from '@/models/IState'

export function getUserAccount(): IUserAccount | undefined {
    const state = store.state as unknown as { [STORE_MODULE.AUTH]: IStateAuth }

    return state[STORE_MODULE.AUTH].userAccount
}
