import { ROUTE } from '@/constants'
import { IRouteConfig } from '@/models'

export const routesAdministration: IRouteConfig[] = [
    {
        name: ROUTE.SYSTEM_MESSAGES,
        path: 'system-messages',
        component: () => import('@/components/BaseRouterView/BaseRouterView.vue'),
        redirect: {
            name: ROUTE.SYSTEM_MESSAGES_LIST,
        },
        meta: {
            title: (intl) => intl.t().systemMessages.systemMessages.many,
            guard: (p) => p.backofficeMessages.read,
            label: (intl) => intl.t().systemMessages.systemMessages.many,
        },
        children: [
            {
                name: ROUTE.SYSTEM_MESSAGES_LIST,
                path: '',
                component: () =>
                    import('@administration/views/SystemMessages/SystemMessages.vue'),
                meta: {
                    title: (intl) => intl.t().systemMessages.systemMessages.many,
                },
            },
            {
                name: ROUTE.SYSTEM_MESSAGE_CREATE,
                path: 'create',
                component: () =>
                    import('@administration/views/SystemMessageCreate/SystemMessageCreate.vue'),
                meta: {
                    title: (intl) => intl.t().systemMessages.create,
                    guard: (p) => p.backofficeMessages.create,
                    label: (intl) => intl.t().systemMessages.create,
                },
            },
            {
                name: ROUTE.SYSTEM_MESSAGE,
                path: ':systemMessageId',
                component: () => import('@/components/BaseRouterView/BaseRouterView.vue'),
                redirect: {
                    name: ROUTE.SYSTEM_MESSAGE_DETAILS,
                },
                props: true,
                meta: {
                    title: (intl) => intl.t().systemMessages.systemMessages.one,
                    label: (intl) => intl.t().systemMessages.systemMessages.one,
                },
                children: [
                    {
                        name: ROUTE.SYSTEM_MESSAGE_DETAILS,
                        path: 'details',
                        component: () =>
                            import(
                                '@administration/views/SystemMessageDetails/SystemMessageDetails.vue'
                            ),
                        props: true,
                        meta: {
                            title: (intl) => intl.t().systemMessages.systemMessages.one,
                        },
                    },
                    {
                        name: ROUTE.SYSTEM_MESSAGE_DETAILS_EDIT,
                        path: 'edit',
                        component: () =>
                            import(
                                '@administration/views/SystemMessageDetailsEdit/SystemMessageDetailsEdit.vue'
                            ),
                        props: true,
                        meta: {
                            title: (intl) => intl.t().systemMessages.edit,
                            guard: (p) => p.backofficeMessages.update,
                            label: (intl) => intl.t().systemMessages.edit,
                        },
                    },
                ],
            },
        ],
    },
]
